import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTitle
} from '@progress/kendo-react-charts';
import "hammerjs";
import "./DirectReportOverview.scss";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";
import { EmojiIcon } from "@fluentui/react-northstar";
import { SavePDFConsumer } from '../../Shared/Context/ExploreAppMode';
import { useInView } from 'react-intersection-observer';

const DirectReportOverview = ({ data }) => {
    const renderTooltip = ({
        point
    }) => <span>{point.category.toString()}: {point.value}%</span>;
    const { ref, inView } = useInView()
    return (
        <SavePDFConsumer>
            {
                (yesPDF) => {
                    return <div>
                        <div className="card-body p-0">
                            <div className="activity-view">
                                <div className="usr-view p-3 px-md-4">
                                    <div className="usr-vDtl">
                                        <div className="row pb-1 px-auto">
                                            <div className="col-md-6 pr-2 pl-2">
                                                <div className="row">
                                                    <div className="col-md-12 pb-3">
                                                        <div className="usr-vHdr-pro">
                                                            <div className="topUsrAreaPic">
                                                                <div className="topUsrAreaPic-i">
                                                                    <div className="contact-list-icon">
                                                                        <div className="contact-list-icon-txt">
                                                                            <div className="contact-list-icon-img">
                                                                                <img src={data.image} />
                                                                            </div>
                                                                            {data.image == "" ? data.initials : ""}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="topUsrAreaPic-tx">
                                                                    <div className="topUsrAreaPic-h">{data.userName}</div>
                                                                    <div className="topUsrAreaPic-p">{data.designation}</div>
                                                                    <div className="topUsrAreaPic-p">{data.email}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="callTrackOuter border">
                                                            <div className="callTrack calltrk">
                                                                <div className="callTrackRow border-bottom">
                                                                    <div className="callTrack-p">
                                                                        <div className="callTrack-pp font-weight-bold">One-to-One</div>
                                                                    </div>
                                                                </div>
                                                                <div className="callTrackRow ">
                                                                    <div className="callTrack-p">
                                                                        <div className="callTrack-pi">
                                                                            <i className="bi bi-telephone"></i>
                                                                        </div>
                                                                        <div className="callTrack-pp">Meetings/Calls</div>
                                                                    </div>
                                                                    <div className="callTrack-h">{data.oneToOneCollaboration.calls}</div>
                                                                    <div className="callTrack-p" style={{ fontSize: "11px" }}>{data.oneToOneCollaboration.perVideoCalls}% Video Enabled</div>
                                                                </div>
                                                                <div className="callTrackRow ">
                                                                    <div className="callTrack-p">
                                                                        <div className="callTrack-pi">
                                                                            <i className="bi bi-clock"></i>
                                                                        </div>
                                                                        <div className="callTrack-pp">Duration</div>
                                                                    </div>
                                                                    <div className="callTrack-h">{data.oneToOneCollaboration.duration}</div>
                                                                    <div className="callTrack-p">&nbsp;</div>
                                                                </div>
                                                                <div className="callTrackRow ">
                                                                    <div className="callTrack-p">
                                                                        <div className="callTrack-pi">
                                                                            <i className="bi bi-chat-left"></i>
                                                                        </div>
                                                                        <div className="callTrack-pp">Chat</div>
                                                                    </div>
                                                                    <div className="callTrack-h">{data.oneToOneCollaboration.messages}</div>
                                                                    <div className="callTrack-h pl-0">
                                                                        <div className="usr-vHdr-qtVal"><div className="heading" style={{ fontSize: "11px", fontWeight: "0" }}> Chat Sentiment</div>
                                                                            <div className="progress" style={{ height: "8px" }}>
                                                                                <div className="progress-bar bg-success" style={{ width: data.oneToOneCollaboration.perPositiveMessages + "%" }} title={"Positive: " + data.oneToOneCollaboration.countPositiveMessages}>

                                                                                </div>
                                                                                <div className="progress-bar border-left bg-warning" style={{ width: data.oneToOneCollaboration.perNeturalMessages + "%" }} title={"Neutral: " + data.oneToOneCollaboration.countNeturalMessages}>

                                                                                </div>
                                                                                <div className="progress-bar border-left bg-danger" style={{ width: data.oneToOneCollaboration.perNegativeMessages + "%" }} title={"Negative: " + data.oneToOneCollaboration.countNegativeMessages}>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="callTrack calltrk">
                                                                <div className="callTrackRow border-bottom border-top">
                                                                    <div className="callTrack-p">
                                                                        <div className="callTrack-pp font-weight-bold">Group</div>
                                                                    </div>
                                                                </div>
                                                                <div className="callTrackRow ">
                                                                    <div className="callTrack-p">
                                                                        <div className="callTrack-pi">
                                                                            <i className="bi bi-telephone"></i>
                                                                        </div>
                                                                        <div className="callTrack-pp">Meetings/Calls</div>
                                                                    </div>
                                                                    <div className="callTrack-h">{data.groupCollaboration.calls}</div>
                                                                    <div className="callTrack-p" style={{ fontSize: "11px" }}>{data.groupCollaboration.perVideoCalls}% Video Enabled</div>
                                                                </div>
                                                                <div className="callTrackRow ">
                                                                    <div className="callTrack-p">
                                                                        <div className="callTrack-pi">
                                                                            <i className="bi bi-clock"></i>
                                                                        </div>
                                                                        <div className="callTrack-pp">Duration</div>
                                                                    </div>
                                                                    <div className="callTrack-h">{data.groupCollaboration.duration}</div>
                                                                    <div className="callTrack-p">&nbsp;</div>
                                                                </div>
                                                                <div className="callTrackRow ">
                                                                    <div className="callTrack-p">
                                                                        <div className="callTrack-pi">
                                                                            <i className="bi bi-chat-left"></i>
                                                                        </div>
                                                                        <div className="callTrack-pp">Group Chat</div>
                                                                    </div>
                                                                    <div className="callTrack-h">{data.groupCollaboration.messages}</div>
                                                                    <div className="callTrack-h pl-0">
                                                                        <div className="usr-vHdr-qtVal"><div className="heading" style={{ fontSize: "11px", fontWeight: "0" }}>Chat Sentiment</div>
                                                                            <div className="progress" style={{ height: "8px" }}>
                                                                                <div className="progress-bar bg-success" style={{ width: data.groupCollaboration.perPositiveMessages + "%" }} title={"Positive: " + data.groupCollaboration.countPositiveMessages}>

                                                                                </div>
                                                                                <div className="progress-bar border-left bg-warning" style={{ width: data.groupCollaboration.perNeturalMessages + "%" }} title={"Neutral: " + data.groupCollaboration.countNeturalMessages}>

                                                                                </div>
                                                                                <div className="progress-bar border-left bg-danger" style={{ width: data.groupCollaboration.perNegativeMessages + "%" }} title={"Negative: " + data.groupCollaboration.countNegativeMessages}>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 pr-2 pl-2" style={{ marginTop: "31px" }}>
                                                <div className="usr-vDtlRowTrk h-100 border">
                                                    <div className="vDtl-t border-bottom">
                                                        <span className='font-weight-bold'>Insights</span>
                                                    </div>
                                                    <div className="vDtl-inr">
                                                        <div className="vDtl-inrRow pt-2 pb-2">
                                                            <div className="vDtl-inrRow-span d-flex align-items-center">
                                                                <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                                <span>
                                                                    Overall collaboration time with direct reports <b>{data.insights.perOverallCollab}%</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="vDtl-inrRow pt-2 pb-2">
                                                            <div className="vDtl-inrRow-span d-flex align-items-center">
                                                                <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                                <span>
                                                                    Total time spent in meetings/calls with direct reports <b>{data.insights.totalTimeinMeetings}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {data.insights.perVideoEnabledCalls !== "" &&
                                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                                <div className="vDtl-inrRow-span d-flex align-items-center">
                                                                    <i className="bg-transparent-cst bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                                    <span>
                                                                        Video meetings/calls with direct reports <b>{data.insights.perVideoEnabledCalls}%</b>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {data.insights.userWithLeastOneToOneCollaboartion !== "" &&
                                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                                <div className="vDtl-inrRow-span d-flex align-items-center">
                                                                    <i className="bg-transparent-cst bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                                    <span>
                                                                        Least one-to-one collaboration with <b>{data.insights.userWithLeastOneToOneCollaboartion}</b>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {data.insights.userWithMostOneToOneCollaboartion !== "" &&
                                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                                <div className="vDtl-inrRow-span d-flex align-items-center">
                                                                    <i className="bg-transparent-cst bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                                    <span>
                                                                        Most one-to-one collaboration with <b>{data.insights.userWithMostOneToOneCollaboartion}</b>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {data.insights.userWithhighestMessages !== "" &&
                                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                                <div className="vDtl-inrRow-span d-flex align-items-center">
                                                                    <i className="bg-transparent-cst bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                                    <span>
                                                                        Most one-to-one chat with <b>{data.insights.userWithhighestMessages}</b>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* {data.insights.totalScreenSharingCalls !== "" &&
                                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                                <div className="vDtl-inrRow-span d-flex align-items-center">
                                                                    <i className="bg-transparent-cst bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                                    <span>
                                                                        Screens shared on meetings/calls <b>{data.insights.totalScreenSharingCalls}</b>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-auto">
                                            <div className="col-md-12 pr-2 pl-2">
                                                <div className="usr-grp h-100" style={{ minHeight: "240px" }}>
                                                    {data.chartData.categories.length >= 6 && <div ref={ref}> {inView && <div className="hideMeAfter5Seconds">
                                                        <p className='click-drag-flash-3 p-2 rounded'><i class="bi bi-arrow-left-short"></i> <i class="bi bi-mouse-fill"></i> <i class="bi bi-arrow-right-short"></i></p>
                                                    </div>}</div>}
                                                    {data.chartData && data.chartData.categories && data.chartData.categories.length > 0 &&
                                                        <Chart
                                                            style={{ height: '100%' }}
                                                            pannable={{ lock: "y" }}
                                                            zoomable={{
                                                                mousewheel: {
                                                                    lock: yesPDF ? ["y", "x"] : "y"
                                                                },
                                                                selection: {
                                                                    lock: yesPDF ? ["y", "x"] : "y"
                                                                },
                                                            }}
                                                        >
                                                            <ChartTitle text="Distribution of One-to-One Time Across Direct Reports" />
                                                            <ChartValueAxis>
                                                                <ChartValueAxisItem min={0} labels={{ content: (e) => e.value + "%" }} />
                                                            </ChartValueAxis>
                                                            <ChartLegend position="top" orientation="horizontal" />
                                                            <ChartCategoryAxis>
                                                                <ChartCategoryAxisItem
                                                                    categories={data.chartData.categories}
                                                                    labels={{ rotation: 'auto' }}
                                                                    maxDivisions={10}
                                                                    max={yesPDF ? null : 6}
                                                                />
                                                            </ChartCategoryAxis>
                                                            <ChartSeries>
                                                                {data.chartData.series.map((item, idx) => (
                                                                    <ChartSeriesItem
                                                                        key={idx}
                                                                        color={item.color}
                                                                        type="column"
                                                                        spacing={0.1}
                                                                        tooltip={{ visible: true }}
                                                                        data={item.data}
                                                                        name={item.name}
                                                                    />
                                                                ))}
                                                            </ChartSeries>
                                                            <ChartTooltip render={renderTooltip} />
                                                        </Chart>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            }
        </SavePDFConsumer>
    );
};

export default DirectReportOverview;
