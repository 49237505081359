import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const Error500 = () => {
    const location = useLocation();
    return (
        <div className="error">
            <div className="notfound">
                <div className="notfound-section">
                    <h1>Oops!</h1>
                    <h2>It's just a 500 Error!
                        <br />Internal Server Error, Please be patient or try again later.
                    </h2>
                </div>
                {
                    location.pathname.toLowerCase().includes('dashboards') ?
                        <Link to="/dashboards" className="k-button k-primary py-3 px-5">Go to Dashboard</Link> :
                        location.pathname.toLowerCase().includes('settings') ?
                            <Link to={location.pathname} className="k-button k-primary py-3 px-5">Go to Settings</Link> :
                            location.pathname.toLowerCase().includes('tools') ?
                                <Link to="/tools/BusinessPriorities" className="k-button k-primary py-3 px-5">Go to Tools</Link> :
                                <Link to="/" className="k-button k-primary py-3 px-5">Go to Home</Link>
                }
            </div>
        </div>
    )
}

export { Error500 }