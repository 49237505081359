import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartLegend
} from "@progress/kendo-react-charts";
import './PieChart.scss';

const pieData = [
    {
        name: "Jatin",
        share: 0.24
    },
    {
        name: "Neeraj",
        share: 0.26,
        explode: true
    },
    {
        name: "Prabhat",
        share: 0.1
    },
    {
        name: "Others",
        share: 0.4
    }
];

export default function PieChart(props){
    return (
        <Chart style={{height: '100%'}}>
                {/* <ChartTitle text="Exploded Pie Chart" /> */}
                <ChartLegend position="top" orientation="horizontal" />
                <ChartSeries>
                    <ChartSeriesItem
                        type="pie"
                        overlay={{
                            gradient: "sharpBevel"
                        }}
                        tooltip={{ visible: true }}
                        data={pieData}
                        categoryField="name"
                        field="share"
                    />
                </ChartSeries>
            </Chart>
      );
}
