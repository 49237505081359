import "./OnetoOneOverview.scss";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";
import { EmojiIcon } from "@fluentui/react-northstar";
import { tooltipAsLabelBehavior } from '@fluentui/react-northstar';
import "hammerjs";
import { CallIcon, ChatIcon } from '@fluentui/react-icons-northstar'

const OnetoOneOverview = ({ data }) => {
    const [sort, setSort] = React.useState([]);
    const [callstate, setCallstate] = React.useState(false);
    const renderTooltip = ({
        point
    }) => <span>{point.category.toString()}: {point.value}</span>;
    // const data = props.data
    return (
        <div className="activity-view">
            <div className="usr-view">
                <div className="usr-vDtl">
                    <div className="row">
                        <div className="col-md-12 pb-2">
                            <div className="usr-vHdr-pro">
                                <div className="topUsrAreaPic">
                                    <div className="topUsrAreaPic-i">
                                        <div className="contact-list-icon">
                                            <div className="contact-list-icon-txt">
                                                <div className="contact-list-icon-img">
                                                    <img src={data.userPhoto} />
                                                </div>
                                                {data.userPhoto == "" ? data.initials : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="topUsrAreaPic-tx">
                                        <div className="topUsrAreaPic-h">{data.name}</div>
                                        <div className="topUsrAreaPic-p">{data.designation}</div>
                                        <div className="topUsrAreaPic-p">{data.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-2 pr-2 pl-2">
                            <div className="usr-cardBrRadiusTop border">
                                <div className="vDtl-t border-bottom">
                                    <span>Manager</span>
                                </div>
                                <div className="vDtl-inr">
                                    <div className="row">
                                        <div className="col-md-12 p-0">
                                            <div className="callTrack calltrk">
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pp txt-dull">One-to-One</div>
                                                    </div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-telephone"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Meetings/Calls</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.managerOverview.oneToOne.calls}</div>
                                                    <div className="callTrack-p" style={{ fontSize: "11px" }}>{Math.round(data.managerOverview.oneToOne.perVideoCalls)}% Video Enabled</div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-clock"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Duration</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.managerOverview.oneToOne.duration}</div>
                                                    <div className="callTrack-p">&nbsp;</div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-chat-left"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Chat</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.managerOverview.oneToOne.messages}</div>
                                                    <div className="callTrack-h p-0">
                                                        <div className="usr-vHdr-qtVal"><div className="heading" style={{ fontSize: "8px", fontWeight: "0" }}>Chat Sentiment</div>
                                                            {data.managerOverview.oneToOne.messages > 0 &&
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" style={{ width: data.managerOverview.oneToOne.perPositiveMessages + "%" }} title={"Positive: " + data.managerOverview.oneToOne.countPositiveMessages}> </div>
                                                                    <div className="progress-bar border-left bg-warning" style={{ width: data.managerOverview.oneToOne.perNeturalMessages + "%" }} title={"Neutral: " + data.managerOverview.oneToOne.countNeturalMessages}>
                                                                    </div>
                                                                    <div className="progress-bar border-left bg-danger" style={{ width: data.managerOverview.oneToOne.perNegativeMessages + "%" }} title={"Negative: " + data.managerOverview.oneToOne.countNegativeMessages}>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {data.managerOverview.oneToOne.messages === 0 && <div className="na-heightFix">N/A</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="callTrack calltrk">
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pp txt-dull">Group</div>
                                                    </div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-telephone"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Meetings/Calls</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.managerOverview.group.calls}</div>
                                                    <div className="callTrack-p" style={{ fontSize: "11px" }}>{Math.round(data.managerOverview.group.perVideoCalls)}% Video Enabled</div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-clock"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Duration</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.managerOverview.group.duration}</div>
                                                    <div className="callTrack-p">&nbsp;</div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-chat-left"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Group Chat</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.managerOverview.group.messages}</div>
                                                    <div className="callTrack-h p-0">
                                                        <div className="usr-vHdr-qtVal"><div className="heading" style={{ fontSize: "8px", fontWeight: "0" }}>Chat Sentiment</div>
                                                            {data.managerOverview.group.messages > 0 &&
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" style={{ width: data.managerOverview.group.perPositiveMessages + "%" }} title={"Positive: " + data.managerOverview.group.countPositiveMessages}> </div>
                                                                    <div className="progress-bar border-left bg-warning" style={{ width: data.managerOverview.group.perNeturalMessages + "%" }} title={"Neutral: " + data.managerOverview.group.countNeturalMessages}>
                                                                    </div>
                                                                    <div className="progress-bar border-left bg-danger" style={{ width: data.managerOverview.group.perNegativeMessages + "%" }} title={"Negative: " + data.managerOverview.group.countNegativeMessages}>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {data.managerOverview.group.messages === 0 && <div className="na-heightFix">N/A</div>}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="usr-cardBrRadiusBottom border h-100">
                                        {/* <div className="vDtl-t border-bottom">
                                            <span>Insights</span>
                                        </div> */}
                                        <div className="vDtl-inr scrollbar-box insightListBox pt-1">
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Total collaboration time with Manager <b>{data.managerOverview.collaborationTime}</b></span>
                                                </div>
                                            </div>
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Percentage of overall collaboration time spent with Manager <b>{Math.round(data.managerOverview.perOverallCollabtime)}%</b></span>
                                                </div>
                                            </div>
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Percentage of overall collaboration time spent one-to-one with Manager <b>{Math.round(data.managerOverview.perOverallOneToOneCollabTime)}%</b></span>
                                                </div>
                                            </div>
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Percentage scheduled meetings with Manager <b>{Math.round(data.managerOverview.perScheduleMeetings)}%</b></span>
                                                </div>
                                            </div>
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Screens shared on meetings/calls with Manager <b>{Math.round(data.managerOverview.countScreenSharing)}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-2 pr-2 pl-2">
                            <div className="usr-cardBrRadiusTop border">
                                <div className="vDtl-t border-bottom">
                                    <span>Direct Reports</span>
                                </div>
                                <div className="vDtl-inr">
                                    <div className="row">
                                        <div className="col-md-12 p-0">
                                            <div className="callTrack calltrk">
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pp txt-dull">One-to-One</div>
                                                    </div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-telephone"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Meetings/Calls</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.reporteeOverview.oneToOne.calls}</div>
                                                    <div className="callTrack-p" style={{ fontSize: "11px" }}>{Math.round(data.reporteeOverview.oneToOne.perVideoCalls)}% Video Enabled</div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-clock"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Duration</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.reporteeOverview.oneToOne.duration}</div>
                                                    <div className="callTrack-p">&nbsp;</div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-chat-left"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Chat</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.reporteeOverview.oneToOne.messages}</div>
                                                    <div className="callTrack-h p-0">
                                                        <div className="usr-vHdr-qtVal"><div className="heading" style={{ fontSize: "8px", fontWeight: "0" }}>Chat Sentiment</div>
                                                            {data.reporteeOverview.oneToOne.messages > 0 &&
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" style={{ width: data.reporteeOverview.oneToOne.perPositiveMessages + "%" }} title={"Positive: " + data.reporteeOverview.oneToOne.countPositiveMessages}> </div>
                                                                    <div className="progress-bar border-left bg-warning" style={{ width: data.reporteeOverview.oneToOne.perNeturalMessages + "%" }} title={"Neutral: " + data.reporteeOverview.oneToOne.countNeturalMessages}>

                                                                    </div>
                                                                    <div className="progress-bar border-left bg-danger" style={{ width: data.reporteeOverview.oneToOne.perNegativeMessages + "%" }} title={"Negative: " + data.reporteeOverview.oneToOne.countNegativeMessages}>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {data.reporteeOverview.oneToOne.messages === 0 && <div className="na-heightFix">N/A</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="callTrack calltrk">
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pp txt-dull">Group</div>
                                                    </div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-telephone"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Meetings/Calls</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.reporteeOverview.group.calls}</div>
                                                    <div className="callTrack-p" style={{ fontSize: "11px" }}>{Math.round(data.reporteeOverview.group.perVideoCalls)}% Video Enabled</div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-clock"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Duration</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.reporteeOverview.group.duration}</div>
                                                    <div className="callTrack-p">&nbsp;</div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-chat-left"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Group Chat</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.reporteeOverview.group.messages}</div>
                                                    <div className="callTrack-h p-0">
                                                        <div className="usr-vHdr-qtVal"><div className="heading" style={{ fontSize: "8px", fontWeight: "0" }}>Chat Sentiment</div>
                                                            {data.reporteeOverview.group.messages > 0 &&
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" style={{ width: data.reporteeOverview.group.perPositiveMessages + "%" }} title={"Positive: " + data.reporteeOverview.group.countPositiveMessages}> </div>
                                                                    <div className="progress-bar border-left bg-warning" style={{ width: data.reporteeOverview.group.perNeturalMessages + "%" }} title={"Neutral: " + data.reporteeOverview.group.countNeturalMessages}>
                                                                    </div>
                                                                    <div className="progress-bar border-left bg-danger" style={{ width: data.reporteeOverview.group.perNegativeMessages + "%" }} title={"Negative: " + data.reporteeOverview.group.countNegativeMessages}>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {data.reporteeOverview.group.messages === 0 && <div className="na-heightFix">N/A</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="usr-cardBrRadiusBottom border h-100">
                                        {/* <div className="vDtl-t border-bottom">
                                            <span>Insights</span>
                                        </div> */}
                                        <div className="vDtl-inr scrollbar-box insightListBox pt-1">
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Total collaboration time with Direct Reports <b>{data.reporteeOverview.collaborationTime}</b></span>
                                                </div>
                                            </div>
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Percentage of overall collaboration time spent with Direct Reports <b>{Math.round(data.reporteeOverview.perOverallCollabtime)}%</b></span>
                                                </div>
                                            </div>
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Percentage of overall collaboration time spent one-to-one with Direct Reports <b>{Math.round(data.reporteeOverview.perOverallOneToOneCollabTime)}%</b></span>
                                                </div>
                                            </div>
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Percentage scheduled meetings with Direct Reports <b>{Math.round(data.reporteeOverview.perScheduleMeetings)}%</b></span>
                                                </div>
                                            </div>
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Screens shared on meetings/calls with Direct Reports <b>{Math.round(data.reporteeOverview.countScreenSharing)}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-2 pr-2 pl-2">
                            <div className="usr-cardBrRadiusTop border">
                                <div className="vDtl-t border-bottom">
                                    <span>All Other People</span>
                                </div>
                                <div className="vDtl-inr">
                                    <div className="row">
                                        <div className="col-md-12 p-0">
                                            <div className="callTrack calltrk">
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pp txt-dull">One-to-One</div>
                                                    </div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-telephone"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Meetings/Calls</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.othersOverview.oneToOne.calls}</div>
                                                    <div className="callTrack-p" style={{ fontSize: "11px" }}>{Math.round(data.othersOverview.oneToOne.perVideoCalls)}% Video Enabled</div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-clock"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Duration</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.othersOverview.oneToOne.duration}</div>
                                                    <div className="callTrack-p">&nbsp;</div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-chat-left"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Chat</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.othersOverview.oneToOne.messages}</div>
                                                    <div className="callTrack-h p-0">
                                                        <div className="usr-vHdr-qtVal"><div className="heading" style={{ fontSize: "8px", fontWeight: "0" }}>Chat Sentiment</div>
                                                            {data.othersOverview.oneToOne.messages > 0 &&
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" style={{ width: data.othersOverview.oneToOne.perPositiveMessages + "%" }} title={"Positive: " + data.othersOverview.oneToOne.countPositiveMessages}> </div>
                                                                    <div className="progress-bar border-left bg-warning" style={{ width: data.othersOverview.oneToOne.perNeturalMessages + "%" }} title={"Neutral: " + data.othersOverview.oneToOne.countNeturalMessages}>

                                                                    </div>
                                                                    <div className="progress-bar border-left bg-danger" style={{ width: data.othersOverview.oneToOne.perNegativeMessages + "%" }} title={"Negative: " + data.othersOverview.oneToOne.countNegativeMessages}>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {data.othersOverview.oneToOne.messages === 0 && <div className="na-heightFix">N/A</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="callTrack calltrk">
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pp txt-dull">Group</div>
                                                    </div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-telephone"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Meetings/Calls</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.othersOverview.group.calls}</div>
                                                    <div className="callTrack-p" style={{ fontSize: "11px" }}>{Math.round(data.othersOverview.group.perVideoCalls)}% Video Enabled</div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-clock"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Duration</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.othersOverview.group.duration}</div>
                                                    <div className="callTrack-p">&nbsp;</div>
                                                </div>
                                                <div className="callTrackRow">
                                                    <div className="callTrack-p">
                                                        <div className="callTrack-pi">
                                                            <i className="bi bi-chat-left"></i>
                                                        </div>
                                                        <div className="callTrack-pp">Group Chat</div>
                                                    </div>
                                                    <div className="callTrack-h">{data.othersOverview.group.messages}</div>
                                                    <div className="callTrack-h p-0">
                                                        <div className="usr-vHdr-qtVal"><div className="heading" style={{ fontSize: "8px", fontWeight: "0" }}>Chat Sentiment</div>
                                                            {data.othersOverview.group.messages > 0 &&
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" style={{ width: data.othersOverview.group.perPositiveMessages + "%" }} title={"Positive: " + data.othersOverview.group.countPositiveMessages}> </div>
                                                                    <div className="progress-bar border-left bg-warning" style={{ width: data.othersOverview.group.perNeturalMessages + "%" }} title={"Neutral: " + data.othersOverview.group.countNeturalMessages}>
                                                                    </div>
                                                                    <div className="progress-bar border-left bg-danger" style={{ width: data.othersOverview.group.perNegativeMessages + "%" }} title={"Negative: " + data.othersOverview.group.countNegativeMessages}>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {data.othersOverview.group.messages === 0 && <div className="na-heightFix">N/A</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="usr-cardBrRadiusBottom border h-100">
                                        {/* <div className="vDtl-t border-bottom">
                                            <span>Insights</span>
                                        </div> */}
                                        <div className="vDtl-inr scrollbar-box insightListBox pt-1">
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Total collaboration time with All Other People <b>{data.othersOverview.collaborationTime}</b></span>
                                                </div>
                                            </div>
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Percentage of overall collaboration time spent with All Other People <b>{Math.round(data.othersOverview.perOverallCollabtime)}%</b></span>
                                                </div>
                                            </div>
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Percentage of overall collaboration time spent one-to-one with All Other People <b>{Math.round(data.othersOverview.perOverallOneToOneCollabTime)}%</b></span>
                                                </div>
                                            </div>
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Percentage scheduled meetings with All Other People <b>{Math.round(data.othersOverview.perScheduleMeetings)}%</b></span>
                                                </div>
                                            </div>
                                            <div className="vDtl-inrRow pt-2 pb-2">
                                                <div className="vDtl-inrRow-p d-flex align-items-center">
                                                    <i className="bi bi-circle-fill pr-2" style={{ fontSize: "6px" }}></i>
                                                    <span >Screens shared on meetings/calls with All Other People <b>{Math.round(data.othersOverview.countScreenSharing)}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnetoOneOverview;