import React, { useState } from 'react';
import './insights.scss';
import { Avatar, Button, Skeleton, Tooltip, Divider } from '@fluentui/react-northstar';
import { InfoIcon } from "@fluentui/react-icons-northstar"
import MultiSegmentRangeSlider from '../../Shared/uicomponents/MultiSegmentRangeSlider';
import HSCProgress from './hscProgress';
function InsightsCard({ wellBeingScoreDescriptionList, key, teamView, fnUpdateUserToView, forDialog = false, isNotManager = false, ...data }) {
    const ListViewIndicator = ({ type, label = 'Activity' }) => {
        return (
            <div className='col-auto px-2 d-flex align-items-center'>
                {type && <div className={`list-view-indicator ${type}`} title={`${type} zone`}></div>}
                <div className='list-view-label px-2' title={label === 'Messages' ? 'Chats & Messages' : label}>{label}</div>
            </div>
        )
    }

    const WellbeingPill = ({ type, label, val }) => {
        return (
            <div className={`col-auto px-0 m-1 py-1 rounded-pill border d-flex align-items-center wi-${val}`}>
                {type && <div className={`list-view-indicator ${type}`} title={`${type} zone`}></div>}
                <div className='list-view-label-cst px-2' title={label ? `Wellbeing score- ${val}` : "NA"}>{label}</div>
            </div>
        )
    }

    const WellbeingCategoryScoreComponent = (props) => {
        return (
            <div className='col-12'>
                <div className='row align-items-center'>
                    <Tooltip content={props.category.desc}
                        trigger={
                            <div className='col-auto pl-0 wb-cat-label'>
                                {props.category.label}
                            </div>
                        }
                    />
                    <div className='col pl-0 text-right'>
                        {/* <div className='col-auto'>
                            <div className={`list-view-indicator wi-${props.category.val}`}></div>
                        </div> */}
                        <span className={`ml-2 pl-3 pr-0 wb-cat-label wi-${props.category.val}`}>{props.responseScale[props.category.val]}</span>
                    </div>
                </div>
            </div>
        )
    }


    const TeamViewIndicator = ({ type, value = '!' }) => {
        //debugger
        return (
            <div className='col-auto pl-2 pr-1 d-flex align-items-center'>
                {type && <div className={`detail-view-indicator ${type}`} title={`${type} zone`}></div>}
                <div className='detail-view-label px-2' title={type + ' People'}>{type} - {value}</div>
            </div>
        )
    }

    const InsightCardHeader = ({ userName = 'User name', userEmail = 'user.name@company.com', userImage, userDesignation = 'no data', vibe = 'Neutral', reporteeCount, userGUID, managerGUID, fnUpdateUserToView }) => {
        //debugger
        return (
            <div className='row insights-card-header align-items-center'>

                <div className=''>
                    <Avatar
                        name={userName}
                        size="large"
                        image={userImage}
                    />
                </div>
                <div className='col px-2 labels'>
                    <div className='name-label'>{userName}</div>
                    <div className='designation-label'>{userDesignation}</div>
                    {(teamView) && <div className='org-email'>{userEmail}</div>}
                </div>
                {(!teamView) ?
                    (<div className={`vibe-indicator align-self-end ${(!teamView) ? '' : 'mb-2'}`}>
                        <div className='vibe-indicator-emoji' >
                            {vibe === 'positive' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Halo.png" alt="Neutral Face" width="38" height="38" />
                                : (vibe === 'negative' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Worried%20Face.png" alt="Worried Face" width="38" height="38" />
                                    : <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Neutral%20Face.png" alt="Neutral Face" width="38" height="38" />
                                )}
                        </div>
                        <div className='vibe-indicator-text bg-primary text-right'>
                            {vibe === 'positive' ? 'Positive' : (vibe === 'negative' ? 'Negative' : 'Neutral')}
                        </div>
                    </div>) : <></>
                }
            </div>
        )
    }

    return (

        <>
            <div className={isNotManager ? "non-managercard" : "insights-card-container"}>
                <div className={isNotManager ? "non-managercardcursor" : (`${(forDialog) ? 'm-3' : 'insights-card'} cursor-default ${(teamView) ? forDialog ? '' : 'detail-view-width' : 'list-view-width'}`)} key={key}>
                    <div className="container">
                        <InsightCardHeader
                            teamView
                            userName={data.userName}
                            userDesignation={data.userDesignation}
                            userEmail={data.userEmail}
                            userStatus={data.userStatus}
                            userLabel={data.userLabel}
                            vibe={data.vibe}
                            reporteeCount={data.reporteeCount}
                            userGUID={data.userGUID}
                            managerGUID={data.managerGUID}
                            userImage={data.userImage}
                            fnUpdateUserToView={fnUpdateUserToView}
                            managerData={forDialog}
                        />
                        <div className="insights-card-body">
                            {
                                (teamView) ? <>
                                    <div class="row mb-1">
                                        <div className='col-auto px-2 text-secondary'>Percentage Time Spent</div>
                                        <Tooltip
                                            content="This section provides a comprehensive look at your workday, helping you balance communication and focus time effectively. By visualizing how your time is spent across meetings, calls, and tasks that require concentration, it guides you toward optimizing your schedule for better productivity and work-life balance. The color-coded zones make it easy to spot when adjustments are needed to achieve a"
                                            pointing
                                            trigger={
                                                <Button
                                                    className='mr-1'
                                                    icon={<InfoIcon className='text-secondary' />}
                                                    text
                                                    iconOnly
                                                    size='small'
                                                />
                                            }
                                        />

                                    </div>
                                    <div className='row border-b-uinsights pb-1'>
                                        <div className="col px-2">
                                            <HSCProgress
                                                label={'Meetings'}
                                                progress={data.meetingsPercentage}
                                                zones={data.meetingsZone}
                                            />

                                            <HSCProgress
                                                label={'Messages'}
                                                progress={data.callsPercentage}
                                                zones={data.callsZone}
                                            />

                                            <HSCProgress
                                                label={'Focused'}
                                                progress={data.focusedPercentage}
                                                zones={data.focusedZone}
                                            />
                                            {/* <MultiSegmentRangeSlider
                                                fieldName="percentageTimeInMeetings"
                                                headerText="Meetings"
                                                displayValue={data.meetingsPercentage}
                                                compact={true}
                                                setValue={data.meetingsPercentage}
                                                setZoneBars={data.meetingsZone}
                                                draggable={false}
                                                readonly={true}
                                                min={0}
                                                max={100}
                                            />
                                            <MultiSegmentRangeSlider
                                                fieldName="percentageTimeInCallsAndMessages"
                                                headerText="Messages"
                                                displayValue={data.callsPercentage}
                                                compact={true}
                                                setValue={data.callsPercentage}
                                                setZoneBars={data.callsZone}
                                                draggable={false}
                                                readonly={true}
                                                min={0}
                                                max={100}
                                            />
                                            <MultiSegmentRangeSlider
                                                fieldName="percentageTimeInFocus"
                                                headerText="Focused"
                                                displayValue={data.focusedPercentage}
                                                compact={true}
                                                setValue={data.focusedPercentage}
                                                setZoneBars={data.focusedZone}
                                                draggable={false}
                                                readonly={true}
                                                min={0}
                                                max={100}
                                            /> */}
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div className='col-auto px-2 text-secondary'>Sentiment with People</div>
                                        <Tooltip
                                            content='Sentiments shape our interactions: positive feelings foster connection, negative feelings can cause conflict, and neutral feelings indicate indifference. Understanding these dynamics enhances our communication and relationships.'
                                            pointing
                                            trigger={
                                                <Button
                                                    className='mr-1'
                                                    icon={<InfoIcon className='text-secondary' />}
                                                    text
                                                    iconOnly
                                                    size='small'
                                                />
                                            }
                                        />
                                    </div>
                                    <div className='row align-items-center justify-content-between my-1 pb-2 border-b-uinsights'>
                                        <TeamViewIndicator type='Neutral' value={data.dviNeutralVal} />
                                        <TeamViewIndicator type='Positive' value={data.dviPositiveVal} />
                                        <TeamViewIndicator type='Negative' value={data.dviNegativeVal} />
                                    </div>
                                    <div class="row mt-2 ">
                                        <div className='col-auto px-2 text-secondary'>Wellbeing Status</div>
                                        <Tooltip
                                            content='This section provides an overview of various wellbeing categories within our organization. Each category reflects the current wellbeing status of you and your team members using qualitative labels. The statuses include "Thriving," "Satisfied," "Neutral," "Struggling," and "At Risk," which signify the overall health of your work environment.'
                                            pointing
                                            trigger={
                                                <Button
                                                    className='mr-1'
                                                    icon={<InfoIcon className='text-secondary' />}
                                                    text
                                                    iconOnly
                                                    size='small'
                                                />
                                            }
                                        />
                                    </div>
                                    <div className='row align-items-center mb-2 pb-1 mt-1 border-b-uinsights'>
                                        <div className="col pl-2 p-0 ml-3">
                                            <div className="row align-items-center">
                                                {data.categoryTag.map((item) => <WellbeingCategoryScoreComponent responseScale={wellBeingScoreDescriptionList} category={item} />)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row justify-even align-items-center'>
                                        {
                                            data.reporteeCount ? <>
                                                <Button
                                                    icon={<i className="bi bi-diagram-2" style={{ fontSize: '20px', marginRight: '-2px' }}></i>}
                                                    content="Team View"
                                                    title="Team View"
                                                    text
                                                    primary
                                                    onClick={() => fnUpdateUserToView(data.userGUID, true)}
                                                />
                                                <div className='border-r-uinsights h-100'>&nbsp;</div>
                                            </> : <></>
                                        }
                                        <Button
                                            icon={<i className="bi bi-person-bounding-box" style={{ fontSize: '16px', marginRight: '4px' }}></i>}
                                            title="View Details"
                                            content="View Details"
                                            text
                                            primary
                                            onClick={() => fnUpdateUserToView(data.userGUID, false)}
                                        />
                                    </div>
                                </>
                                    :
                                    <div className='row justify-content-center list-view-indicator-margin'>
                                        <ListViewIndicator type={data.lvTypeMeeting} label='Meetings' />
                                        <ListViewIndicator type={data.lvTypeMessages} label='Messages' />
                                        <ListViewIndicator type={data.lvTypeFocused} label='Focused' />
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default InsightsCard