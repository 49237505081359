import React from "react";
import CircleChart from "../../Shared/uicomponents/circleChart";
import "./MeetingParticipation.scss";

const MeetingParticipation = ({ data }) => {
  return (
    <div className="row m-2 my-auto">
      <div class="col">
        <div class="row justify-content-center">
          <CircleChart id="xx" value={data} size="fs-190 m-0" />
        </div>
        {/*  <div class="row my-2" style={{ lineHeight: "1" }}>*/}
        {/*    <div class="col my-1 align-items-baseline">*/}
        {/*      <span class="" style={{ fontSize: "12px" }}>Meetings missed by*/}
        {/*        organisors</span>*/}
        {/*      <div class="row align-items-center mt-1">*/}
        {/*        <div class="col pr-1">*/}
        {/*          <div class="progress rounded-0">*/}
        {/*            <div class="progress-bar bg-warning" role="progressbar"*/}
        {/*              style={{ width: "30%" }} aria-valuenow="30" aria-valuemin="0"*/}
        {/*              aria-valuemax="100"></div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div class="col-auto pl-0" style={{ minWidth: "51px" }}>*/}
        {/*          <b>30</b>%*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
      </div>
    </div>
  );
};

export default MeetingParticipation;
