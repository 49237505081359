import React, { Component } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./NavMenu";
import * as constants from "../constants";
import WindowContext from "./Shared/Context/Context";

export const Layout = ({ children, themeString, themeHandler }) => {
  return (
    <WindowContext>
            {
            (context) => (
    <div>
      {constants.isLocal && (
        <NavMenu themeString={themeString} themeHandler={themeHandler} />
      )}
      <Container className={`pt-0 ${context.mediaCategory?.sm ? "px-0":" "}`} fluid id="main" style={{ height: "100dvh" }}>
        {children}
      </Container>
    </div>
    )
        }
        </WindowContext>
  );
};
