import * as constants from '../constants';
import * as msTeams from '@microsoft/teams-js';

import { PublicClientApplication } from "@azure/msal-browser"
import TrackUserActivityApiService from './TrackUserActivityApiService'

const msalConfig = {
    auth: {
        clientId: constants.Auth.appId,
        authority: "https://login.windows.net/common",
        navigateToLoginRequestUrl: false,
        audience: constants.Auth.audience,
        redirectUri: `${window.location.origin}/${constants.Auth.signInEndPage}`,
        postLogoutRedirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
};

// Add scopes here for access token to be used at Microsoft Graph API endpoints.
const tokenRequest = {
    scopes: ["User.Read", "Mail.Read"]
};

const msalApplication = new PublicClientApplication(msalConfig);
msalApplication.handleRedirectPromise().then((authResult) => {
    AuthHelper_Local.HandleRedirectResponse(authResult);
});

msTeams.initialize();

class AuthHelper_Local {
    static HandleRedirectResponse(authResult) {
        // if this page load is redirect from the Microsoft Identity platform then the
        // authResult will be populated. Otherwise null on other page loads.

        if (authResult !== null) {
            // save the fresh account info from the result.
            let loginActivityData = {
                UserName: authResult.account.name,
                UserMail: authResult.account.username,
                TenantId: authResult.account.tenantId,
                LoginDateTime: null,
                Device: this.getDeviceType(),
                Language: navigator.language,
            };
            //TrackUserActivityApiService.AddUserLoginActivity(loginActivityData);
            msTeams.authentication.notifySuccess(authResult.accessToken);
        }
    }

    static getDeviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return "mobile";
        }
        return "desktop";
    };

    /**
     * Uses the current authetication context to check if a user
     * is logged in. In this case, this is determined by the presence
     * of a cached user and cached token with length > 0.
     */
    static IsUserLoggedIn() {
        let account = AuthHelper_Local.getAccount();
        return account != null;
    }

    static getAccount() {
        const currentAccounts = msalApplication.getAllAccounts();
        if (currentAccounts === null) {
            console.log("No accounts detected");
            return null;
        }

        if (currentAccounts.length > 1) {
            console.log("Multiple accounts detected, need to add choose account code.");
            return currentAccounts[0];
        } else if (currentAccounts.length === 1) {
            return currentAccounts[0];
        }
    }

    static GetToken() {
        const account = AuthHelper_Local.getAccount()
        const tenantId = account?.tenantId
        const msalTenantSpecificConfig = {
            auth: {
                clientId: constants.Auth.appId,
                audience: constants.Auth.audience,
                authority: "https://login.microsoftonline.com/" + tenantId
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            }
        }
        const tenantMsalApplication = new PublicClientApplication(msalTenantSpecificConfig);

        let tokenRequest = {
            account: AuthHelper_Local.getAccount(),
            scopes: [constants.Auth.scopes]
        }

        // msal will return the cached token if present, or call to get a new one
        // if it is expired or near expiring.
        return tenantMsalApplication.acquireTokenSilent(tokenRequest)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                // throw new Error('Token expired. Fetching new token silently');
                return AuthHelper_Local.getSilentToken()
                //window.location.replace(window.location.origin + '/dashboards');
            });
    }

    static async getSilentToken() {
        return new Promise((resolve, reject) => {
            msTeams.authentication.authenticate({
                url: `${window.location.origin}/${constants.Auth.silentTokenStartPage}`,
                width: 600,
                height: 535,
                successCallback: (accessToken) => {
                    resolve(accessToken);
                },
                failureCallback: (reason) => {
                    if (reason) {
                        resolve(AuthHelper_Local.GetToken());
                    } else {
                        reject(reason);
                    }
                }
            })
        })
    }

    static StartSilentToken() {
        let account = AuthHelper_Local.getAccount();
        let loginRequestPayload = {
            scopes: [constants.Auth.scopes],
            loginHint: account?.username
        }

        msalApplication.acquireTokenRedirect(loginRequestPayload).then((response) => {
            //debugger;
            var token = response;
        })
    }

    /**
     * Attempts to get an access token for the user. If successful,
     * sends the user to the home page again where they will now
     * be logged in.
     */
    static async Login(returnUrl) {
        let accessToken = '';

        try {
            accessToken = await
                AuthHelper_Local.getAccessToken()
                    .then(() => {
                        //console.log("RESOLVED"); 
                        window.location.replace(window.location.origin + returnUrl);
                    })
                    .catch(() => {
                        //console.log("REJECTED");
                        //window.location.replace(window.location.origin + '/login');
                        window.location.replace(window.location.origin + returnUrl);
                    });
        } catch (err) {
            //console.error(err);
            window.location.replace(window.location.origin + '/login');
        }
    }


    static async logout() {
        let account = AuthHelper_Local.getAccount();
        const logOutRequest = {
            account,
        };

        msalApplication.logout(logOutRequest);
    }

    /**
     * Clears any existing user from the cache, then requests
     * an AD token.
     */
    static StartSignIn() {
        let loginRequestPayload = {
            scopes: [constants.Auth.scopes],
            redirectStartPage: `${window.location.origin}/${constants.Auth.signInEndPage}`
        }

        msalApplication.loginRedirect(loginRequestPayload);
    }

    /**
     * Called from the sign-in-end page. Checks for the presence
     * of the AD token, and notifies teams of a successful log in
     * if it is there, or notifies of failure otherwise.
     */
    static EndSignIn() {
        console.log(`Ending Sign In. This will now redirect to HandleRedirectResponse`);
    }

    /**
     * Begins the login flow by opening a popup window
     * at the sign-in-start page.
     */
    static async getAccessToken() {
        return new Promise((resolve, reject) => {
            msTeams.authentication.authenticate({
                url: `${window.location.origin}/${constants.Auth.signInStartPage}`,
                width: 600,
                height: 535,
                successCallback: (accessToken) => {
                    resolve(accessToken);
                },
                failureCallback: (reason) => {
                    reject(reason);
                }
            })
        })
    }
}

class AuthHelper_Prod {
    static async getAccessToken(successCallbackFunction) {
        msTeams.initialize();
        var token = window.localStorage.getItem("impersonationToken")

        if (window.localStorage.getItem("impersonationMode"))
            successCallbackFunction(token);
        else {
            var authTokenRequest = {
                successCallback: successCallbackFunction,
                failureCallback: function (error) { console.log("Failure: " + error); },
                resources: [constants.Auth.audience]
            };

            msTeams.authentication.getAuthToken(authTokenRequest);
        }
    }
}

export default class AuthHelper {
    static async getAccessToken(successCallbackFunction) {
        if (constants.isLocal) {
            if (window.localStorage.getItem("impersonationMode")) {
                var token = window.localStorage.getItem("impersonationToken")
                AuthHelper_Local.GetToken().then(function (response) {
                    successCallbackFunction(token);
                });
            }
            else {
                AuthHelper_Local.GetToken().then(function (response) {
                    if (response && response.accessToken) {
                        successCallbackFunction(response.accessToken);
                    }
                });
            }
        }
        else {
            AuthHelper_Prod.getAccessToken(successCallbackFunction);
        }
    }

    static StartSignIn() {
        AuthHelper_Local.StartSignIn();
    }

    static EndSignIn() {
        AuthHelper_Local.EndSignIn();
    }
    static StartSilentToken() {
        AuthHelper_Local.StartSilentToken();
    }
}