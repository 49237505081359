export const TeamsThemes = {
    contrast: "constrast",
    dark: "dark",
    default: "default"
};
export const isLocal = process.env.REACT_APP_ISLOCAL === 'true' ? true:  false; 
export const Api = {
    baseUri: process.env.REACT_APP_ISLOCAL === 'true' ? process.env.REACT_APP_BASE_URI : process.env.REACT_APP_BASE_URI
};
export const Auth = process.env.REACT_APP_ISLOCAL === 'true' ? {
    appId: process.env.REACT_APP_APPID,
    audience: process.env.REACT_APP_AUDIENCE,
    scopes: process.env.REACT_APP_SCOPES,
    cacheLocation: "localStorage",
    loginPage: "login",
    signInStartPage: "signinstart",
    silentTokenStartPage: "silenttokenstart",
    signInEndPage: "signinend",
    authenticatedDomains: {
        "https://graph.microsoft.com": "https://graph.microsoft.com"
    }
} : {
    appId: process.env.REACT_APP_APPID,
    audience:process.env.REACT_APP_AUDIENCE
};