import { useState, useEffect } from 'react';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import './MeetingHabitsIndicator.scss';

export default function MeetingHabitsIndicator({ data }) {
    return (
        <div class="card-body p-3">
            <div class="activity-view lh-1">
                <div class="progressBox teamHabit scrollbar-box space-evenly">
                    <div class="progressBoxRow">
                        <div class="progressBoxData">
                            <div class="progressBoxTxt">Invitation sent with less than 24 hours' notice</div>
                            <div class="progressBoxVal">{data.atLeastTwentyFourHoursNotice}%</div>
                        </div>
                        <div class="lh-1">
                            <ProgressBar
                                value={data.atLeastTwentyFourHoursNotice}
                                style={{ height: 6, width: '100%' }}
                                label={props => {
                                    return <strong>{data.atLeastTwentyFourHoursNotice}%</strong>;
                                }}
                                labelVisible={true}
                                labelPlacement={'end'}
                            />
                        </div>
                    </div>
                    <div class="progressBoxRow">
                        <div class="progressBoxData">
                            <div class="progressBoxTxt">Low attendance</div>
                            <div class="progressBoxVal">{data.attendance}%</div>
                        </div>
                        <div class="lh-1">
                            <ProgressBar
                                value={data.attendance}
                                style={{ height: 6, width: '100%' }}
                                label={props => {
                                    return <strong>{data.attendance}%</strong>;
                                }}
                                labelVisible={true}
                                labelPlacement={'end'}
                            />
                        </div>
                    </div>
                    <div class="progressBoxRow">
                        <div class="progressBoxData">
                            <div class="progressBoxTxt">Messages sent to people outside of the meeting</div>
                            <div class="progressBoxVal">{data.noMessageToPeopleOutsideMeeting}%</div>
                        </div>
                        <div class="lh-1">
                            <ProgressBar
                                value={data.noMessageToPeopleOutsideMeeting}
                                style={{ height: 6, width: '100%' }}
                                label={props => {
                                    return <strong>{data.noMessageToPeopleOutsideMeeting}%</strong>;
                                }}
                                labelVisible={true}
                                labelPlacement={'end'}
                            />
                        </div>
                    </div>
                    {!data.isTeam &&
                        <div class="progressBoxRow">
                            <div class="progressBoxData">
                                <div class="progressBoxTxt">Scheduled meetings joined late</div>
                                <div class="progressBoxVal">{data.joinedBeforeMeetingStarts}%</div>
                            </div>
                            <div class="lh-1">
                                <ProgressBar
                                    value={data.joinedBeforeMeetingStarts}
                                    style={{ height: 6, width: '100%' }}
                                    label={props => {
                                        return <strong>{data.joinedBeforeMeetingStarts}%</strong>;
                                    }}
                                    labelVisible={true}
                                    labelPlacement={'end'}
                                />
                            </div>
                        </div>}
                    {!data.isTeam && <div class="progressBoxRow">
                        <div class="progressBoxData">
                            <div class="progressBoxTxt">Meetings scheduled outside of working hours</div>
                            <div class="progressBoxVal">{data.outsideWorkingHours}%</div>
                        </div>
                        <div class="lh-1">
                            <ProgressBar
                                value={data.outsideWorkingHours}
                                style={{ height: 6, width: '100%' }}
                                label={props => {
                                    return <strong>{data.outsideWorkingHours}%</strong>;
                                }}
                                labelVisible={true}
                                labelPlacement={'end'}
                            />
                        </div>
                    </div>}
                    {!data.isTeam && <div class="progressBoxRow">
                        <div class="progressBoxData">
                            <div class="progressBoxTxt">Scheduled meetings that overran</div>
                            <div class="progressBoxVal">{data.overrun}%</div>
                        </div>
                        <div class="lh-1">
                            <ProgressBar
                                value={data.overrun}
                                style={{ height: 6, width: '100%' }}
                                label={props => {
                                    return <strong>{data.overrun}%</strong>;
                                }}
                                labelVisible={true}
                                labelPlacement={'end'}
                            />
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
}
