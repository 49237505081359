import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Avatar, Tooltip, Popup, Box, Flex, Text } from "@fluentui/react-northstar";
import "./ExpandableRowGrid.scss";
import { CircleIcon } from "@fluentui/react-icons-northstar";
import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';

class cellWithIcon extends React.Component {
  render() {
    let sentimentScore = this.props.dataItem.sentimentScore ? this.props.dataItem.sentimentScore : this.props.dataItem.sentiment;
    let className = "st_good";
    switch (sentimentScore) {
      case "Negative":
        className = "st_poor";
        break;
      case "Neutral":
        className = "st_average";
        break;
      case "Positive":
        className = "st_good";
        break;
      case "Unavailable":
        className = "st_unavailable";
        break;
      default:
        className = "st_good";
        sentimentScore = "Positive";
    }
    return (
      <td>
        <CircleIcon className={className} /> {sentimentScore}
      </td>
    );
  }
}

const CellWithSentiment = (props) => {
  const data = props.dataItem;
  //if (data.perPositiveMessages === 0 && data.perNeturalMessages === 0 && data.perNegativeMessages === 0)
    //data.perNeturalMessages = "100";
  return (
    <td>
      {data.perPositiveMessages === 0 && data.perNeturalMessages === 0 && data.perNegativeMessages === 0
      ?"N/A":<div className="callTrack-h">
        <div className="usr-vHdr-qtVal">
          <div className="progress">
            <div className="progress-bar bg-success" style={{ width: data.perPositiveMessages + "%" }} title={"Positive: " + data.perPositiveMessages + "%"}>

            </div>
            <div className="progress-bar border-left bg-warning" style={{ width: data.perNeturalMessages + "%" }} title={"Neutral: " + data.perNeturalMessages + "%"}>

            </div>
            <div className="progress-bar border-left bg-danger" style={{ width: data.perNegativeMessages + "%" }} title={"Negative: " + data.perNegativeMessages + "%"}>

            </div>
          </div>
        </div>
      </div>}
    </td>
  )
}


const TeamActivityDetailComponent = (props) => {
  const [sort, setSort] = React.useState([]);
  const data = props.dataItem.channelDetails;

  return (
    <Grid
      data={orderBy(data, sort)}
      sortable={true}
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
      }}
    >
      <Column field="channelName" title="Channel Name" locked width={120} />
      <Column field="organizedMeetings" title="Total Meetings" width={72} />
      <Column field="organizedMeetingMinutes" title="Talk time" width={90} />
      <Column field="perRecurring" title="Recurring(%)" width={90} />
      {/* <Column field="perRecurringMinutes" title="% Time in recurring" width={90} /> */}
      <Column field="externalMeeting" title="External Meetings" width={90} />
      {/* <Column field="externalMeetingParticipation" title="External Meetings Participation" width={120} /> */}
      <Column field="posts" title="Posts (External)" width={112} />
      <Column field="replies" title="Replies (External)" width={112} />
      <Column title="Sentiment" width={100} cell={CellWithSentiment} />
      <Column field="membersOwners" title="Membership (Owners)" width={110} />
      <Column field="totalUsers" title="Active Users (Inactive Users)" width={110} />
    </Grid>
  );

};

export default function ExpandableRowGrid({ data, widgetId, downloadCard, valueAfterCardDownload }) {
  const [sort, setSort] = React.useState([]);
  const [gridData, setGridData] = React.useState(data.gridData);
  const [cardDownload, setCardDownload] = React.useState(false);
  const _export = React.useRef(null);

  React.useEffect(() => {
    setCardDownload(downloadCard)
    if (cardDownload) {
      if (_export.current !== null) {
        _export.current.save();
      }
      setCardDownload(false);
      valueAfterCardDownload(false);
    }
  });

  const expandChange = (event) => {
    let newData = gridData.map((item) => {
      if (item.teamName === event.dataItem.teamName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setGridData(newData);
  };

  return (
    <>
      {gridData && gridData.length > 0 && widgetId === 35 && (
        <div className="smryDiv">
          {/* <div className="smrL"> */}
          <ExcelExport data={gridData} ref={_export}>
            <Grid
              className="standardGrid"
              style={{ height: "100%" }}
              data={orderBy(gridData, sort)}
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              detail={TeamActivityDetailComponent}
              expandField="expanded"
              onExpandChange={expandChange}
            >
              <Column field="teamName" title="Team/Channel Name" locked width={120} />
              <Column field="organizedMeetings" title="Total Meetings" width={72} />
              <Column field="organizedMeetingMinutes" title="Time In Meetings" width={90} />
              <Column field="perRecurring" title="Recurring(%)" width={90} />
              {/* <Column field="perRecurringMinutes" title="% Time In Recurring" width={90} /> */}
              <Column field="externalMeeting" title="External Meetings" width={90} />
              {/* <Column field="externalMeetingParticipation" title="External Meetings Participation" width={125} /> */}
              <Column field="posts" title="Posts (External)" width={112} />
              <Column field="replies" title="Replies (External)" width={112} />
              <Column title="Sentiment" width={100} cell={CellWithSentiment} />
              <Column field="membersOwners" title="Membership (Owners)" width={120} />
              <Column field="totalUsers" title="Active Users (Inactive)" width={120} />
            </Grid>
          </ExcelExport>
        </div>
      )
      }
    </>
  );
}


