import React, { useState, useEffect } from "react";
import {
  Flex,
  Input,
  Checkbox,
  Dropdown,
  Button,
  Text,
  Tooltip,
  Avatar,
  Loader,
  Label,
  Box,
  Popup,
} from "@fluentui/react-northstar";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import {
  SearchIcon,
  EditIcon,
  ApprovalsAppbarIcon,
  ExclamationTriangleIcon,
  ShiftActivityIcon,
  PresenceAvailableIcon,
  CloseIcon,
  InfoIcon,
} from "@fluentui/react-icons-northstar";
import "../Dashboard/dashboard.scss";
import "./config.scss";
import EditUser from "./edituser";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import AuthHelper from "../../../services/auth-helper";
import CenteredHeader from "../../Shared/Header/Header";
import useMediaQuery from "../../Shared/CustomHooks/useMediaQuery";
import WindowContext from "../../Shared/Context/Context";
import { GroupInheritanceIcon, TrialExpiredIcon, LicenseWarningIcon } from "./commonIcons";
import { OPERATIONSTATUS} from "../../Shared/uicomponents/OperationStatus/index";
import { Error403 } from "../../Shared/Error/403";
import { Error500 } from "../../Shared/Error/500";

const DATA_ITEM_KEY = "userGUID";
const SELECTED_FIELD = "checked";
const idGetter = getter(DATA_ITEM_KEY);
const initialDataState = { skip: 0, take: 10 };
const administrativeAccessTypes = [
  { id: 0, header: 'No Admin', key: 0 },
  { id: 2, header: 'Admin', key: 2 },
  { id: 1, header: 'Super Admin', key: 1 }

];
const administrativeAccessTypesTeamOwner = [
  { id: 0, header: 'No Admin', key: 0 },
  { id: 2, header: 'Admin', key: 2 }
];
const administrativeAccessTypesAdminCase = [
  { id: 2, header: 'Admin', key: 2 },
  { id: 1, header: 'Super Admin', key: 1 }

];
const administrativeAccessTypesSuperAdminCase = [
  { id: 1, header: 'Super Admin', key: 1 }

];


const HeaderCell = (props) => {
  return (
    <a className="k-link" onClick={props.onClick}>
      <span>
        {props.title}
      </span>
      {props.children != null && props.children[0]}
    </a>
  );
};

const CellWithUserImage = (props) => {
  const user = props.dataItem;
  return (
    <WindowContext.Consumer>
      {
        context => (
          <td colSpan={props.colspan}>
            <Avatar
              name={user.userName}
              image={user.userImage}
              size={context.mediaCategory.sm ? "small" : "large"}
            />
          </td>
        )
      }
    </WindowContext.Consumer>
  );
};

const CellWithUserName = (props) => {
  const user = props.dataItem;
  return (
    <td>
      <Box>
        <Text content={user.userName} size="medium" />
      </Box>
      <Text content={user.jobTitle} size="small" timestamp />
    </td>
  );
};


const CellWithAdministrativeAccess = (props) => {
  const user = props.dataItem;
  const [administrativeAccessLoading, setAdministrativeAccessLoading] = useState(false);
  const [administrativeAccess, setAdministrativeAccess] = useState(null);

  useEffect(() => {
    let item = administrativeAccessTypes.find(e => e.id === user.administrativeAccess);
    setAdministrativeAccess(item);
  }, [props])

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);

  const handleAdministrativeAccess = {
    onAdd: (item) => {
      if (item.id === user.administrativeAccess) {
        return;
      }
      setAdministrativeAccessLoading(true);
      var data = {
        userGUIDList: [user.userGUID],
        accessID: item.id,
      };
      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.UpdateAdministrativeAccess(data, token).then(
          (response) => {
                if (response && response.status == 200) {
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                    props.handleUserAdministrativeAccess(item.id, user);
                    setAdministrativeAccess(item);
                
            }
            else if (response.status == 401)
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
            else
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            setAdministrativeAccessLoading(false);
          }
        );
      });
    }
  };

  const renderAdministrativeAccess = () => {
    if (user.email == props.loggedInUserEmail && (user.administrativeAccess === 1 || (user.administrativeAccessInherited !== 1 && user.administrativeAccess === 2)
      || (user.administrativeAccessInherited !== 1 && user.administrativeAccessInherited !== 2 && user.administrativeAccess === 0))) {
      let access = administrativeAccessTypes.find(e => e.id === user.administrativeAccess);
      return <>
        <Tooltip
          className="tooltipTxt"
          trigger={
            <Text weight="bold" styles={{ color: "#A4A4A4" }}>{access.header}</Text>
          }
          align="center"
          position="below"
          content={"Edit permission disabled for own account"}
        />
      </>
    }
    else if (!props.isLoggedInUserAdmin && user.administrativeAccess === 1) {
      return <>
        <Tooltip
          className="tooltipTxt"
          trigger={
            <Text weight="bold" styles={{ color: "#A4A4A4" }}>Super Admin </Text>
          }
          align="center"
          position="below"
          content={"Admin can not change Super Admin permissions"}
        />
      </>
    }

    else if (user.administrativeAccess === 1 || (user.administrativeAccessInherited !== 1 && user.administrativeAccess === 2)
      || (user.administrativeAccessInherited !== 1 && user.administrativeAccessInherited !== 2 && user.administrativeAccess === 0)) {
      return <Dropdown
        inline
        items={props.isLoggedInUserAdmin ? administrativeAccessTypes : administrativeAccessTypesTeamOwner}
        value={administrativeAccess}
        disabled={isDemo === true}
        getA11ySelectionMessage={handleAdministrativeAccess}
        itemToValue={(obj) => {
          return obj.key;
        }}
      />
    }
    else if (user.administrativeAccessInherited === 1) {
      return <>
        {!props.isLoggedInUserAdmin && <Text weight="bold" styles={{ marginRight: "20px" }}>Super Admin </Text>}

        {props.isLoggedInUserAdmin && <Dropdown
          inline
          items={administrativeAccessTypesSuperAdminCase}
          value={"Super Admin"}
          disabled={isDemo === true}
          getA11ySelectionMessage={handleAdministrativeAccess}
          itemToValue={(obj) => {
            return obj.key;
          }}
        />}
        <GroupInheritanceIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "green"} userGUID={user.userGUID} inheritanceType={"administrative"} />
      </>
    }
    else if (user.administrativeAccessInherited === 2) {
      return <>
        {!props.isLoggedInUserAdmin && <Text weight="bold" styles={{ marginRight: "15px" }}>Admin </Text>}

        {props.isLoggedInUserAdmin && <Dropdown
          inline
          items={administrativeAccessTypesAdminCase}
          value={"Admin"}
          disabled={isDemo === true}
          getA11ySelectionMessage={handleAdministrativeAccess}
          itemToValue={(obj) => {
            return obj.key;
          }}
        />}
        <GroupInheritanceIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "green"} userGUID={user.userGUID} inheritanceType={"administrative"} />
      </>
    }
    else {
      return <LicenseWarningIcon type={"user"} />
    }
  }

  return (
    <td style={{ overflow: "visible" }}>
      {administrativeAccessLoading ? (
        <Loader size="small" inline />
      ) : (renderAdministrativeAccess())
      }
    </td>
  );
};

const CellWithReportingAccess = (props) => {
  const user = props.dataItem;
  const [reportingAccessLoading, setReportingAccessLoading] = useState(false);

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);

  const handleReportingAccess = (isChecked) => {
    setReportingAccessLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      accessID: isChecked.checked ? 1 : 0,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateReportingAccess(data, token).then(
        (response) => {
          if (response && response.status == 200) {
              props.handleUserReportingAccess(isChecked.checked ? 1 : 0, user);
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
              }
          else if (response.status == 401)
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
          else
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          setReportingAccessLoading(false);
        }
      );
    });
  };

  return (
    <td style={{ paddingLeft: "20px" }}>
      {reportingAccessLoading ? (
        <Loader size="small" inline />
      ) : (
        user.reportingAccess !== 1 && user.reportingAccessInherited !== 0 ? (
          user.reportingAccessInherited === 1 ?
            <Box style={{ marginLeft: "10px" }}>
              <GroupInheritanceIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "green"} userGUID={user.userGUID} inheritanceType={"reporting"} />
            </Box>
            :
            <Box style={{ marginLeft: "10px" }}>
              <LicenseWarningIcon type={"user"} />
            </Box>
        ) : (
          <Checkbox
            toggle
            checked={user.reportingAccess === 1}
            disabled={isDemo === true}
            onClick={(event, isChecked) => {
              handleReportingAccess(isChecked);
            }}
          />
        )
      )}
    </td>
  );
};

const CellWithSubscription = (props) => {
  const user = props.dataItem;
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [subscribedProduct, setSubscribedProduct] = useState(null);
  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    let item = props.productTypes.find(e => e.id === user.subscriptionId % 500);
    setSubscribedProduct(item);
  }, [props])

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);

  const handleUserSubscription = {
    onAdd: (item) => {
      if (item.id === user.subscriptionId || user.email === props.loggedInUserEmail
        || (!props.tenantDetails.autoBuySubscription && item.disabled)) {
        return;
      }
      setSubscriptionLoading(true);
      var data = {
        userGUIDList: [user.userGUID],
        accessID: item.id,
      };
      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.UpdateUserSubscription(data, token).then(
          (response) => {
                if (response && response.status == 200) {
              props.handleUserSubscription(item.id, user);
              setSubscribedProduct(item)
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
            }
            else if (response.status == 401)
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
            else
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            setSubscriptionLoading(false);
          }
        );
      });
    }
  };

  const renderUserSubscription = () => {
    
    let item = props.productTypes.find(e => e.id === user.subscriptionId % 500);
    let itemInherited = props.productTypes.find(e => e.id === user.subscriptionIdInherited % 500);
    let productName = user.isProductInherited ? itemInherited?.header : item?.header;


    if (user.email === props.loggedInUserEmail) {
      return <>
        <Flex space="between">
          <Tooltip
            className="tooltipTxt"
            trigger={
              <Text weight="bold" styles={{ color: "#A4A4A4" }} truncated>
                {user.isProductInherited ? itemInherited?.header : item?.header}
              </Text>
            }
            align="center"
            position="below"
            content={<div>
              <p style={{ marginBottom: "0px", weight: "bold" }}>{productName}</p>
              {!user.isProductInherited && ((user.email === props.loggedInUserEmail) ? "Edit permission disabled for own account"
                : "Admin can not change super admin permissions")}
            </div>
            }
          />
          <Flex gap="gap.small">
            {user.isProductInherited && Math.floor(user.subscriptionIdInherited / 500) === 0 &&
              <GroupInheritanceIcon colorValue={"#A4A4A4"} userGUID={user.userGUID} inheritanceType={"subscription"} />
            }
            {user.isProductInherited && Math.floor(user.subscriptionIdInherited / 500) !== 0 &&
              <LicenseWarningIcon colorValue={"#A4A4A4"} type={"user"} />
            }
            {!user.isProductInherited && Math.floor(user.subscriptionId / 500) !== 0 &&
              <LicenseWarningIcon colorValue={"#A4A4A4"} type={"user"} />
            }
          </Flex>
        </Flex>
      </>
    }
    else {
      if (!user.isProductInherited) {
        return (
          <WindowContext.Consumer>
            {
              (context) =>
                <>
                  <Flex space="between">
                    <Tooltip
                      className="tooltipTxt"
                      content={item?.header}
                      trigger={
                        <Dropdown
                          className={context.mediaCategory.sm ? "w142fixSm" : ""}
                          inline
                          items={props.productTypes}
                          value={subscribedProduct}
                          getA11ySelectionMessage={handleUserSubscription}
                          disabled={isDemo === true}
                          itemToValue={(obj) => {
                            return obj.key;
                          }}
                          renderItem={(Component, props) => {
                            if (props.disabled) {
                              props.className = props.className + " _licenseUnavailable";
                            }
                            return <Component {...props}></Component>;
                          }}
                        />
                      }
                      align="center"
                      position="before"
                    />
                  </Flex>
                  <Flex gap="gap.small">
                    {Math.floor(user.subscriptionId / 500) !== 0 &&
                      <LicenseWarningIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "orange"} type={"user"} />
                    }
                  </Flex>
                </>
            }
          </WindowContext.Consumer>
        )
      }

      else {
        return <>
          <Flex space="between">
            <Tooltip
              className="tooltipTxt"
              trigger={<Text weight="bold" content={itemInherited?.header} truncated />}
              align="center"
              position="before"
              content={itemInherited?.header}
            />
            <Flex gap="gap.small">
              {Math.floor(user.subscriptionIdInherited / 500) === 0 &&
                <GroupInheritanceIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "green"} userGUID={user.userGUID} inheritanceType={"subscription"} />
              }
              {Math.floor(user.subscriptionIdInherited / 500) !== 0 &&
                <LicenseWarningIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "orange"} type={"user"} />
              }
            </Flex>
          </Flex>
        </>
      }
    }
  }

  return (
    <td style={{ overflow: "visible" }} className="subscriptionDropDown">
      {subscriptionLoading ? (
        <Loader size="small" inline />
      ) : (renderUserSubscription())}
    </td>
  );
};

const CellWithAction = (props) => {
  const user = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      <Button
        onClick={() => props.onOpen(user)}
        icon={<EditIcon />}
        iconOnly
        title="Edit"
        size="small"
        primary
      />
    </td>
  );
};

const DetailComponent = (props) => {
  return (
    <section style={{ paddingRight: "32px" }}>
      <table>
        <tr>
          <td>Administration Access:</td>
          <CellWithAdministrativeAccess {...props} />
        </tr>
        <tr>
          <td>Reporting Access:</td>
          <CellWithReportingAccess {...props} />
        </tr>
        <tr>
          <td>Subscription:</td>
          <CellWithSubscription {...props} />
        </tr>
      </table>
    </section>
  );
};



const UserConfig = (props) => {
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [sort, setSort] = useState([]);
  const [page, setPage] = React.useState(initialDataState);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedState, setSelectedState] = useState({});

  const [userData, setUserData] = useState(null);
  const [tenantDetails, setTenantDetails] = useState(null);
  const [productTypes, setProductTypes] = useState([]);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);

  const [selectedAdministrativeAccess, setSelectedAdministrativeAccess] = useState(false);
  const [selectedReportingAccess, setSelectedReportingAccess] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(false);
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    setSearchInput("");
    setTenantDetails(props.tenantDetails);
      setLoggedInUserEmail(props.loggedInUserEmail);
      props.viewStatus.setPageStatus(null);
      props.viewStatus.InformChildPageStatus(null);
    loadUserData();
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, [props.tenantDetails]);

  const onOpen = (userDetails) => {
    setSelectedUser(userDetails);
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleCloseAction = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const expandChange = (event) => {
    let newData = userData.map((item) => {
      if (item.userName === event.dataItem.userName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setUserData(newData);
  };

  const loadUserData = () => {
    setUserData(null);
    setSelectedState({});
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetUsers(token).then((response) => {
          if (response && response.status == 200) {
            setUserSubscriptionData(response.data);
        } else {
          setUserData([]);
          }
          props.viewStatus.setPageStatus(response.status);
      });
    });
  };

  const setUserSubscriptionData = (users) => {
    var _products = props.tenantDetails.plans.map(e => { return { ...e, consumed: 0 } });
    var _users = users.map(user => {
      var subscriptionRank = _products.find(e => e.productId === user.subscriptionId)?.productRank;
      var subscriptionInheritedRank = _products.find(e => e.productId === user.subscriptionIdInherited)?.productRank;
      var consumedProductId = 0;
      var isProductInherited = false;
      if (!user.isGroupInheritanceAllowed) {
        consumedProductId = user.subscriptionId;
        isProductInherited = false;
      }
      else if (subscriptionRank > subscriptionInheritedRank) {
        consumedProductId = user.subscriptionId;
        isProductInherited = false;
      }
      else if (subscriptionRank < subscriptionInheritedRank) {
        consumedProductId = user.subscriptionIdInherited;
        isProductInherited = true;
      }
      else if (subscriptionRank === subscriptionInheritedRank) {
        consumedProductId = Math.max(user.subscriptionId, user.subscriptionIdInherited);
        isProductInherited = user.subscriptionId < user.subscriptionIdInherited
      }
      else if (user.subscriptionIdInherited > 500 && user.isGroupInheritanceAllowed) {
        isProductInherited = true;
      }

      var _pIndex = _products.findIndex(e => e.productId === consumedProductId);
      if (_pIndex !== -1) {
        _products[_pIndex].consumed += 1;
      }

      return { ...user, isProductInherited: isProductInherited };
    });

    var products = _products.map(ele => {
      return {
        id: ele.productId,
        header: ele.name,
        key: ele.productId,
        disabled: (!props.tenantDetails.autoBuySubscription && (ele.quantity !== -1) && (ele.consumed >= ele.quantity)),
        content: (!props.tenantDetails.autoBuySubscription && (ele.quantity !== -1) && (ele.consumed >= ele.quantity)) &&
          ("Maximum " + ele.quantity + " people can have " + ele.name)
      }
    });
    setProductTypes(products);
    setUserData(_users);
    if (selectedUser !== null) {
      var indexId = _users.findIndex((x) => x.userGUID === selectedUser.userGUID);
      setSelectedUser(_users[indexId])
    }
  }

  const pageChange = (event) => {
    setPage(event.page);
  };

  // const sortIssue = (props) => {
  //   if (subscriptionId === 0){
  //     subscriptionId = 
  //   }
  // }

  const sortChange = (event) => {
    if (event.sort.length === 0) {
      setSort(event.sort);
      return;
    }

    var sortOrder = event.sort[0].dir;
    var columnName = event.sort[0].field;
    if (columnName === "administrativeAccess") {
      let sortArray = [{ field: 'administrativeAccess', dir: sortOrder },
      { field: 'administrativeAccessInherited', dir: sortOrder }];
      setSort(sortArray);
    } else
      if (columnName === "reportingAccess") {
        let sortArray = [{ field: 'reportingAccess', dir: sortOrder },
        { field: 'reportingAccessInherited', dir: sortOrder }];
        setSort(sortArray);
      } else
        if (columnName === "subscriptionId") {
          //debugger;
          let sortArray = [{ field: 'subscriptionDisplayName', dir: sortOrder }];
          setSort(sortArray);
        } else {
          setSort(event.sort);
        }
  };

  const selectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const dataItemKey = idGetter(event.dataItem);
    const newSelectedState = { ...selectedState, [dataItemKey]: checked };
    setSelectedState(newSelectedState);
    handleValidations(newSelectedState, userData);
  };

  const headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const newSelectedState = {};
    userData.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
    handleValidations(newSelectedState, userData);
  };

  const handleUserGroupInheritance = (checked, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);
    tempUserdata[indexId].isGroupInheritanceAllowed = checked;
    setUserSubscriptionData(tempUserdata);
    handleValidations(selectedState, tempUserdata);
  };

  const handleUserAdministrativeAccess = (accessId, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);
    tempUserdata[indexId].administrativeAccess = accessId;
    handleValidations(selectedState, tempUserdata);
    setUserData(tempUserdata);
    if (selectedUser !== null) {
      setSelectedUser(tempUserdata[indexId])
    }
  };

  const handleUserReportingAccess = (accessId, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);
    tempUserdata[indexId].reportingAccess = accessId;
    handleValidations(selectedState, tempUserdata);
    setUserData(tempUserdata);
    if (selectedUser !== null) {
      setSelectedUser(tempUserdata[indexId])
    }
  };

  const handleUserSubscription = (accessId, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);
    tempUserdata[indexId].subscriptionId = accessId;
    setUserSubscriptionData(tempUserdata)
    handleValidations(selectedState, tempUserdata);
  };


  const handleValidations = (selectedUsers, userArray) => {
    var selectedUserCount = userArray.filter((e) => selectedUsers[e.userGUID]).length;

    var appOwnerCount = userArray.filter(
      (e) => selectedUsers[e.userGUID] && (e.administrativeAccess === 1 || e.administrativeAccessInherited === 1)
    ).length;
    var teamOwnerCount = userArray.filter(
      (e) => selectedUsers[e.userGUID] && (e.administrativeAccess === 2 || e.administrativeAccessInherited === 2)
    ).length;
    if (appOwnerCount === selectedUserCount) {
      let item = administrativeAccessTypes.find(e => e.id === 1);
      setSelectedAdministrativeAccess(item);
    } else if (teamOwnerCount === selectedUserCount) {
      let item = administrativeAccessTypes.find(e => e.id === 2);
      setSelectedAdministrativeAccess(item);
    } else {
      let item = administrativeAccessTypes.find(e => e.id === 0);
      setSelectedAdministrativeAccess(item);
    }

    var reportingUserCount = userArray.filter(
      (e) => selectedUsers[e.userGUID] && (e.reportingAccess === 1 || e.reportingAccessInherited === 1)
    ).length;
    setSelectedReportingAccess(reportingUserCount === selectedUserCount);

    var _products = props.tenantDetails.plans.map(e => { return { ...e, consumed: 0, availableUser: 0 } });
    var _users = userArray.map(user => {
      var subscriptionRank = _products.find(e => e.productId === user.subscriptionId)?.productRank;
      var subscriptionInheritedRank = _products.find(e => e.productId === user.subscriptionIdInherited)?.productRank;
      var consumedProductId = 0;
      var isProductInherited = false;
      if (!user.isGroupInheritanceAllowed) {
        consumedProductId = user.subscriptionId;
        isProductInherited = false;
      }
      else if (subscriptionRank > subscriptionInheritedRank) {
        consumedProductId = user.subscriptionId;
        isProductInherited = false;
      }
      else if (subscriptionRank < subscriptionInheritedRank) {
        consumedProductId = user.subscriptionIdInherited;
        isProductInherited = true;
      }
      else if (subscriptionRank === subscriptionInheritedRank) {
        consumedProductId = Math.max(user.subscriptionId, user.subscriptionIdInherited);
        isProductInherited = user.subscriptionId < user.subscriptionIdInherited
      }

      var _pIndex = _products.findIndex(e => e.productId === consumedProductId);
      if (_pIndex !== -1) {
        _products[_pIndex].consumed += 1;
        if (!isProductInherited && selectedUsers[user.userGUID]) {
          _products.forEach((p, index, arr) => {
            if (index !== _pIndex) {
              arr[index].availableUser += 1;
            }
          })
        }
      }
      return { ...user, isProductInherited: isProductInherited };
    });

    var selectedProductId = 0;
    var products = _products.map(ele => {
      var subCount = _users.filter(user => selectedUsers[user.userGUID] && !user.isProductInherited && user.subscriptionId === ele.productId).length;
      if (subCount === selectedUserCount) {
        selectedProductId = ele.productId;
      }
      return {
        id: ele.productId,
        header: ele.name,
        key: ele.productId,
        disabled: (!props.tenantDetails.autoBuySubscription && (ele.quantity !== -1) && (ele.availableUser > ele.quantity - ele.consumed)),
        content: (!props.tenantDetails.autoBuySubscription && (ele.quantity !== -1) && (ele.availableUser > ele.quantity - ele.consumed)) &&
          ("Maximum " + ele.quantity + " people can have " + ele.name)
      }
    });
    setSelectedProductTypes(products);
    setSelectedSubscription(products.find(e => e.id === selectedProductId));
  };

  const handleSelectedAdministrativeAccess = {
    onAdd: (item) => {
      let accessId = item.id;
      if (!props.isLoggedInUserAdmin && accessId === 1) {
        return;
      }
      setLoading(true);
      var data = {};
      if (accessId === 1) {
        data = {
          userGUIDList: userData
            .filter((e) => e.email != loggedInUserEmail && selectedState[e.userGUID]
              && ((e.administrativeAccess !== 1 && e.administrativeAccessInherited !== 1 && e.administrativeAccess === 2)
                || (e.administrativeAccess !== 1 && e.administrativeAccessInherited !== 1 && e.administrativeAccess !== 2 && e.administrativeAccessInherited !== 2 && e.administrativeAccess === 0))
            ).map((ele) => { return ele.userGUID; }),
          accessID: accessId,
        };
      }
      else if (accessId === 2) {
        data = {
          userGUIDList: userData
            .filter((e) => e.email != loggedInUserEmail && selectedState[e.userGUID]
              && ((e.administrativeAccess === 1 && props.isLoggedInUserAdmin)
                || (e.administrativeAccess !== 1 && e.administrativeAccessInherited !== 1 && e.administrativeAccess !== 2 && e.administrativeAccessInherited !== 2 && e.administrativeAccess === 0))
            ).map((ele) => { return ele.userGUID; }),
          accessID: accessId,
        };
      }
      else if (accessId === 0) {
        data = {
          userGUIDList: userData
            .filter((e) => e.email != loggedInUserEmail && selectedState[e.userGUID]
              && ((e.administrativeAccess === 1 && props.isLoggedInUserAdmin)
                || (e.administrativeAccess !== 1 && e.administrativeAccessInherited !== 1 && e.administrativeAccess === 2))
            ).map((ele) => { return ele.userGUID; }),
          accessID: accessId,
        };
      }

      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.UpdateAdministrativeAccess(data, token).then(
          (response) => {
            if (response && response.status == 200) {
              var tempUserArray = userData.map((ele) => {
                if (data.userGUIDList.indexOf(ele.userGUID) > -1) {
                  ele.administrativeAccess = data.accessID;
                }
                return ele;
              });
              handleValidations(selectedState, tempUserArray);
                setUserData(tempUserArray);
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                }
            else if (response.status == 401)
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
            else
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            setLoading(false);
          }
        );
      });
    }
  };

  const handleSelectedReportingAccess = (isChecked) => {
    setLoading(true);
    var data = {};
    if (isChecked.checked) {
      data = {
        userGUIDList: userData.filter((e) =>
          e.reportingAccess !== 1 && e.reportingAccessInherited !== 1 && selectedState[e.userGUID]
        ).map((ele) => { return ele.userGUID; }),
        accessID: 1,
      };
    } else {
      data = {
        userGUIDList: userData.filter((e) =>
          e.reportingAccess === 1 && selectedState[e.userGUID]
        ).map((ele) => { return ele.userGUID; }),
        accessID: 0,
      };
    }

    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateReportingAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            var tempUserArray = userData.map((ele) => {
              if (data.userGUIDList.indexOf(ele.userGUID) > -1) {
                ele.reportingAccess = data.accessID;
              }
              return ele;
            });
            handleValidations(selectedState, tempUserArray);
            setUserData(tempUserArray);
          }
          setLoading(false);
        }
      );
    });
  };

  const handleSelectedSubscription = {
    onAdd: (item) => {
      let accessId = item.id;
      setLoading(true);
      var data = {
        userGUIDList: userData
          .filter((e) => (e.email !== loggedInUserEmail) && selectedState[e.userGUID] && (props.isLoggedInUserAdmin || e.administrativeAccess !== 1)
            && !e.isProductInherited && (e.subscriptionId !== item.id)
          ).map((ele) => { return ele.userGUID; }),
        accessID: accessId,
      };

      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.UpdateUserSubscription(data, token).then(
          (response) => {
                if (response && response.status == 200) {
              var tempUserArray = userData.map((ele) => {
                if (data.userGUIDList.indexOf(ele.userGUID) > -1) {
                  ele.subscriptionId = data.accessID;
                }
                return ele;
              });
              setUserSubscriptionData(tempUserArray);
                handleValidations(selectedState, tempUserArray);
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                }
            else if (response.status == 401)
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
            else
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            setLoading(false);
          }
        );
      });
    }
  };


  const CustomCellWithUserImage = (props) => (
    <CellWithUserImage {...props} colspan={1} />
  );

  const CustomCellWithAdministrativeAccess = (propsData) => (
    <CellWithAdministrativeAccess
      {...propsData}
      selectedState={selectedState}
      loggedInUserEmail={loggedInUserEmail}
      isLoggedInUserAdmin={props.isLoggedInUserAdmin}
      isLoggedInUserTeamOwner={props.isLoggedInUserTeamOwner}
      handleUserAdministrativeAccess={handleUserAdministrativeAccess}
      viewStatus={props.viewStatus}
    />
  );

  const CustomCellWithReportingAccess = (propsData) => (
    <CellWithReportingAccess
      {...propsData}
      selectedState={selectedState}
      isLoggedInUserAdmin={props.isLoggedInUserAdmin}
      isLoggedInUserTeamOwner={props.isLoggedInUserTeamOwner}
      handleUserReportingAccess={handleUserReportingAccess}
      viewStatus={props.viewStatus}
    />
  );

  const CustomCellWithSubscription = (propsData) => (
    <CellWithSubscription
      {...propsData}
      selectedState={selectedState}
      tenantDetails={tenantDetails}
      productTypes={productTypes}
      loggedInUserEmail={loggedInUserEmail}
      isLoggedInUserAdmin={props.isLoggedInUserAdmin}
      isLoggedInUserTeamOwner={props.isLoggedInUserTeamOwner}
      handleUserSubscription={handleUserSubscription}
      viewStatus={props.viewStatus}
    />
  );

  const CustomCellWithAction = (props) => (
    <CellWithAction
      {...props}
      onOpen={(user) => {
        onOpen(user);
      }}
    />
  );

  const CustomDetailedComponent = (propsData) => (
    <DetailComponent
          {...propsData}
          selectedState={selectedState}
          tenantDetails={tenantDetails}
          productTypes={productTypes}
          loggedInUserEmail={loggedInUserEmail}
          isLoggedInUserAdmin={props.isLoggedInUserAdmin}
          isLoggedInUserTeamOwner={props.isLoggedInUserTeamOwner}
          handleUserAdministrativeAccess={handleUserAdministrativeAccess}
          handleUserReportingAccess={handleUserReportingAccess}
          handleUserSubscription={handleUserSubscription}
          viewStatus={props.viewStatus }
    />
  );

  return (
    <WindowContext.Consumer>
      {
            context => (
                      props.viewStatus.pageStatus == 401 ? <Error403 /> :
                          props.viewStatus.pageStatus == 500 ? <Error500 /> :
          <>
            {isDemo && (<Flex
              gap="gap.small"
              column={context.mediaCategory.sm}
              style={{ marginTop: "20px" }}
            ></Flex>)}
            {userData != null &&
              userData.filter((e) => selectedState[e.userGUID]).length != 0 && (
                <Box>
                  <Box style={{ maxHeight: "60px", overflow: "auto" }}>
                    {userData != null &&
                      userData
                        .filter((e) => selectedState[e.userGUID])
                        .map((user) => {
                          return (
                            <Label
                              color="brand"
                              key={user.userId}
                              content={user.userName}
                              icon={
                                <CloseIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    let newSelectedState = { ...selectedState, [user.userGUID]: false };
                                    setSelectedState(newSelectedState);
                                    handleValidations(newSelectedState, userData);
                                  }}
                                />
                              }
                              style={{ margin: "3px" }}
                            />
                          );
                        })}
                  </Box>
                  {loading ? (
                    <Loader size="small" />
                  ) : (
                    <Flex
                      gap="gap.small"
                      column={context.mediaCategory.sm}
                      style={{ marginTop: "10px" }}
                    >
                      <Box className={!context.mediaCategory.sm ? "d-flex text-nowrap align-items-center" : ""} style={{ width: "auto" }}>
                        <Text weight="bold" styles={{ display: "inline-block", marginRight: "10px" }}>Administration Access : </Text>
                        <Dropdown
                          styles={{ display: "inline-block", width: "auto", marginRight: "10px" }}
                          fluid
                          disabled={isDemo}
                          items={props.isLoggedInUserAdmin ? administrativeAccessTypes : administrativeAccessTypesTeamOwner}
                          value={selectedAdministrativeAccess}
                          getA11ySelectionMessage={handleSelectedAdministrativeAccess}
                          itemToValue={(obj) => {
                            return obj.key;
                          }}
                        />
                        <Popup
                          trigger={<InfoIcon outline size="large" />}
                          content={<>
                            <p><b>Super Admin :</b> can manage settings for <br />the whole organization. <br />
                              <b>Admin :</b> can manage settings of <br />teams owned by this user. <br />
                              <b>No Admin :</b> no access to settings.
                            </p>
                          </>}
                          pointing
                          trapFocus
                          position={"after"}
                          align={"center"}
                          on="hover"
                        />
                      </Box>
                      <Box styles={{ width: "auto" }} className={!context.mediaCategory.sm ? "d-flex text-nowrap align-items-center" : ""}>
                        <Text weight="bold">Reporting Access : </Text>
                        <Checkbox
                          styles={{ marginRight: "10px" }}
                          toggle
                          disabled={isDemo}
                          labelPosition="start"
                          checked={selectedReportingAccess}
                          onClick={(event, isChecked) => {
                            handleSelectedReportingAccess(isChecked);
                          }}
                        />
                        <Popup
                          trigger={<InfoIcon outline size="large" />}
                          content={<>
                            <p>Allows user to access dashboards.</p>
                          </>}
                          pointing
                          trapFocus
                          position={"after"}
                          align={"center"}
                          on="hover"
                        />
                      </Box>
                      <Box styles={{ width: "auto" }} className={!context.mediaCategory.sm ? "d-flex text-nowrap align-items-center" : ""}>
                        <Text weight="bold" styles={{ display: "inline-block", marginRight: "10px" }}>Subscription : </Text>
                        <Dropdown
                          styles={{ display: "inline-block", width: "auto", marginRight: "10px" }}
                          fluid
                          disabled={isDemo}
                          items={selectedProductTypes}
                          value={selectedSubscription}
                          getA11ySelectionMessage={handleSelectedSubscription}
                          itemToValue={(obj) => {
                            return obj.key;
                          }}
                          renderItem={(Component, props) => {
                            if (props.disabled) {
                              props.className = props.className + " _licenseUnavailable";
                            }
                            return <Component {...props}></Component>;
                          }}
                        />
                        <Popup
                          trigger={<InfoIcon outline size="large" />}
                          content={<>
                              <p>Allows {process.env.REACT_APP_APP_BRAND_NAME} to capture data for this user <br />based on product subscription type.</p>
                          </>}
                          pointing
                          trapFocus
                          position={"after"}
                          align={"center"}
                          on="hover"
                        />
                      </Box>
                    </Flex>
                  )}
                </Box>
              )}
            <Flex gap="gap.small" padding="padding.medium" column fill>
              <Flex.Item>
                <Input
                  fluid
                  icon={<SearchIcon />}
                  clearable
                  value={searchInput}
                  placeholder="Search..."
                  onChange={(e, { name, value }) => {
                    setPage(initialDataState);
                    setSearchInput(value);
                  }}
                />
              </Flex.Item>
              <Flex.Item>
                <Grid
                  className={"usersconfigGrid"}
                  style={{ maxHeight: "calc(100vh - 115px)" }}
                  detail={context.mediaCategory.sm ? CustomDetailedComponent : null}
                  expandField="expanded"
                  onExpandChange={expandChange}
                  data={
                    userData != null
                      ? orderBy(userData
                        .filter((user) =>
                          user.userName
                            .toLowerCase()
                            .includes(searchInput.toLowerCase())
                        ), sort)
                        .slice(page.skip, page.take + page.skip)
                        .map((item) => ({
                          ...item,
                          [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))
                      : null
                  }
                  skip={page.skip}
                  take={page.take}
                  total={userData != null ? userData.filter((user) => user.userName.toLowerCase().includes(searchInput.toLowerCase())).length : 0}
                  pageable={{
                    buttonCount: context.mediaCategory.sm ? 2 : 10,
                    info: true,
                  }}
                  onPageChange={pageChange}
                  sortable={true}
                  sort={sort}
                  onSortChange={sortChange}
                  dataItemKey={DATA_ITEM_KEY}
                  selectedField={SELECTED_FIELD}
                  selectable={{ enabled: true, drag: false, cell: false }}
                  onSelectionChange={selectionChange}
                  onHeaderSelectionChange={headerSelectionChange}
                >
                  <GridNoRecords>
                    {userData != null ? "No records available" : <Loader />}
                  </GridNoRecords>
                  <Column
                    width="32px"
                    field="checked"
                    headerSelectionValue={
                      userData != null
                        ? userData.findIndex(
                          (item) => !selectedState[idGetter(item)]
                        ) === -1
                        : false
                    }
                  />
                  <Column
                    width={context.mediaCategory.sm ? "40px" : "56px"}
                    title=""
                    cell={CustomCellWithUserImage}
                  />
                  <Column
                    width={context.mediaCategory.sm ? "calc(100% - 242px)" : "calc(100% - 600px)"}
                    title="Name"
                    field="userName"
                    cell={CellWithUserName}
                  />
                  {!context.mediaCategory.sm && (
                    <Column
                      width="180px"
                      title="Administration Access"
                      field="administrativeAccess"
                      cell={CustomCellWithAdministrativeAccess}
                      headerCell={HeaderCell}
                    />
                  )}
                  {!context.mediaCategory.sm && (
                    <Column
                      width="150px"
                      title="Reporting Access"
                      field="reportingAccess"
                      cell={CustomCellWithReportingAccess}
                      headerCell={HeaderCell}
                    />
                  )}
                  {!context.mediaCategory.sm && (
                    <Column
                      width="168px"
                      title="Subscription"
                      field="subscriptionDisplayName"
                      cell={CustomCellWithSubscription}
                      headerCell={HeaderCell}
                    />
                  )}
                  <Column
                    width={context.mediaCategory.sm ? "60px" : "80px"}
                    title="Actions"
                    cell={CustomCellWithAction}
                    headerCell={CenteredHeader}
                  />
                </Grid>
              </Flex.Item>
            </Flex>

            {(open && selectedUser != null) && (
              <Dialog
                className="settingsDialog"
                title={"Edit"}
                onClose={() => handleCloseAction()}
              >
                <EditUser
                                        user={selectedUser}
                                        setUserData={setUserData}
                                        userData={userData}
                                        tenantDetails={tenantDetails}
                                        productTypes={productTypes}
                                        loggedInUserEmail={loggedInUserEmail}
                                        loggedInUserGUID={props.loggedInUserGUID}
                                        isLoggedInUserAdmin={props.isLoggedInUserAdmin}
                                        isLoggedInUserTeamOwner={props.isLoggedInUserTeamOwner}
                                        handleUserGroupInheritance={handleUserGroupInheritance}
                                        handleUserAdministrativeAccess={handleUserAdministrativeAccess}
                                        handleUserReportingAccess={handleUserReportingAccess}
                                        handleUserSubscription={handleUserSubscription}
                                        viewStatus={props.viewStatus }
                />
              </Dialog>
            )}
          </>
        )
      }
    </WindowContext.Consumer>
  );
};

export default UserConfig;
