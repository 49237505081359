import React from 'react';
import { useState, useEffect } from "react";
import { Flex, Button, Text, Segment, Checkbox, OpenOutsideIcon } from '@fluentui/react-northstar'
import './notprovisioned.scss';
import DashboardApiService from "../../services/DashboardApiService";
import AuthHelper from "../../services/auth-helper";
import { useHistory } from "react-router-dom";
import { Switch } from "@progress/kendo-react-inputs";
import CreateForDemoAPIService from "../../services/CreateForDemoAPIService";


const NotProvisioned = () => {
    const [isDemo, setIsDemo] = useState(false);
    const [impersonateMode, setImpersonateMode] = useState(false);
    const [globalimpMode, setGlobalimpMode] = useState(false);
    const history = useHistory();

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    const setDemo = (isChecked) => {
        AuthHelper.getAccessToken(function (token) {
            CreateForDemoAPIService.CreateUserForDemo(token).then((response) => {
                if (response !== undefined && response !== "") {

                    global.localStorage.setItem("demoGlobal", "true");
                    global.localStorage.setItem("isCustomerNotRegistered", "true");

                    history.push("/dashboards");
                }
            });
        });

    }
    useEffect(() => {
        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.GetSignUpUrl(token).then(
                (response) => {
                    if (response) {
                        global.localStorage.setItem("signUpUrl", response);

                    }
                }
            );
        });
    }, []);
    return (
        <div class="login-container-msg datatrkMain">
            <div class="innerlogin-msg">

                <div class="signUp-data">
                    <div class="signUp-datatrk datatrkL">
                        <div class="signUp-datatrkInr">
                            <div class="datatrk-logo">
                                <a class="navbar-logo" href="#" title="Logo">
                                    <img src="/logo365.png" alt="Logo" />
                                </a>
                            </div>
                            <div class="datatrk-h">
                                Analytics for Customer Engagement, Collaboration and Wellbeing
                            </div>
                            <div class="datatrk-p">
                                {process.env.REACT_APP_APP_BRAND_NAME} is a native app for Microsoft Teams that provides actionable insights into internal and external collaboration to improve business performance.
                                Intuitive dashboards display collaboration trends, participation, activity, sentiment and quality issues.
                                Company data protection policies and individual privacy is maintained through tight integration with Microsoft 365 admin permissions.
                                {process.env.REACT_APP_APP_BRAND_NAME} data insights provide a holistic view of people, team and group collaboration. Detailed individual interactions and personal data (such as chat messages and call data) are not made accessible to subscribers.
                            </div>
                        </div>
                    </div>
                    <div class="signUp-datatrk datatrkR">
                        <div class="signUp-datatrkInr">
                            <div class="datatrk-h">
                                Sign up now!
                            </div>
                            <div class="datatrk-p pb-0">
                                Sign up to subscribe to the app via the {process.env.REACT_APP_APP_BRAND_NAME} web store. Once submitted, your Microsoft administrator will receive an email requesting permission to be granted to the app. When access has been granted, open your app to start creating your personalised dashboards.
                            </div>
                            <div class="datatrk-h">
                                <div class="datatrk-btnOuter pt-3">
                                    <button type="button" onClick={() => openInNewTab(global.localStorage.getItem("signUpUrl"))} class="btn btn-light"><OpenOutsideIcon className='fUi-1_25' /> Sign up</button>
                                </div>
                            </div>
                            {
                                process.env.REACT_APP_HIDE_DEMO === 'false' ?
                                <div className="row py-3 rounded shadow align-items-center" style={{ background: "rgba(255, 255, 255, .05)" }}>
                                    <div class="col text-white">
                                        Explore the app with sample data.
                                    </div>
                                    <div class="col-auto ml-auto">
                                        <button type="button" onClick={(event, isChecked) => {
                                            setDemo(isChecked);
                                        }} class="btn btn-outline-light">Demo Mode</button>
                                    </div>
                                </div>
                                : <></>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default NotProvisioned;