//import { AllWidgets } from "../../../pages/Dashboard/allwidgets";

export const cardFilters = {
    1: {
        cardFilterID: 1,
        description: "Collaboration",
        date: 1,
        time: 1,
        teams: 2,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 1,
        reactions: 1,
        modalities: 1,
        messages: 1,
    },
    2: {
        cardFilterID: 2,
        description: "Calls",
        date: 1,
        time: 1,
        teams: 2,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 0,
        reactions: 0,
        modalities: 1,
        messages: 0,
        callParticipation: 1,
    },
    3: {
        cardFilterID: 3,
        description: "Messages",
        date: 1,
        time: 1,
        teams: 2,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 1,
        reactions: 1,
        modalities: 0,
        messages: 1,
    },
    4: {
        cardFilterID: 4,
        description: "UserToUser",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 3,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    5: {
        cardFilterID: 5,
        description: "TeamToTeam",
        date: 1,
        time: 1,
        teams: 3,
        groups: 0,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    6: {
        cardFilterID: 6,
        description: "DeptToDept",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    7: {
        cardFilterID: 7,
        description: "TopDept",
        date: 1,
        time: 1,
        teams: 2,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    8: {
        cardFilterID: 8,
        description: "TopDiv",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    9: {
        cardFilterID: 9,
        description: "TopCC",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    10: {
        cardFilterID: 10,
        description: "TopTeam",
        date: 1,
        time: 1,
        teams: 2,
        groups: 0,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    11: {
        cardFilterID: 11,
        description: "TeamMessageSummary",
        date: 1,
        time: 1,
        teams: 2,
        groups: 0,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 1,
        reactions: 1,
        modalities: 0,
        messages: 1,
    },
    12: {
        cardFilterID: 12,
        description: "TeamCollaboration",
        date: 1,
        time: 1,
        teams: 2,
        groups: 0,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 1,
        reactions: 1,
        modalities: 1,
        messages: 1,
    },
    13: {
        cardFilterID: 13,
        description: "ChatAndCallTrends",
        date: 1,
        time: 1,
        teams: 2,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 1,
        reactions: 1,
        modalities: 1,
        messages: 1,
    },
    14: {
        cardFilterID: 14,
        description: "CallNonTeams",
        date: 1,
        time: 1,
        teams: 0,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 0,
        reactions: 0,
        modalities: 1,
        messages: 0,
    },
    15: {
        cardFilterID: 15,
        description: "MessagesReaction",
        date: 1,
        time: 1,
        teams: 2,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 1,
        reactions: 0,
        modalities: 0,
        messages: 1,
        msgParticipation: 1,
    },
    16: {
        cardFilterID: 16,
        description: "CallOnlyTeams",
        date: 1,
        time: 1,
        teams: 2,
        groups: 0,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 0,
        reactions: 0,
        modalities: 1,
        messages: 0,
    },
    17: {
        cardFilterID: 17,
        description: "CollaborationNonTeams",
        date: 1,
        time: 1,
        teams: 0,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 1,
        reactions: 1,
        modalities: 1,
        messages: 1,
    },
    18: {
        cardFilterID: 18,
        description: "CollaborationOnlyTeams",
        date: 1,
        time: 1,
        teams: 2,
        groups: 0,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 1,
        reactions: 1,
        modalities: 1,
        messages: 1,
    },
    19: {
        cardFilterID: 19,
        description: "MessageOnlyGroups",
        date: 1,
        time: 1,
        teams: 0,
        groups: 2,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 1,
        reactions: 1,
        modalities: 0,
        messages: 1,
    },
    20: {
        cardFilterID: 20,
        description: "CallOnlyGroups",
        date: 1,
        time: 1,
        teams: 0,
        groups: 2,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 0,
        reactions: 0,
        modalities: 1,
        messages: 0,
        callParticipation: 1,
    },
    21: {
        cardFilterID: 21,
        description: "User Summary",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 1,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 1,
        reactions: 1,
        modalities: 1,
        messages: 1,
        callParticipation: 1,
    },
    22: {
        cardFilterID: 22,
        description: "Platform Summary",
        date: 1,
        time: 1,
        teams: 2,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    23: {
        cardFilterID: 23,
        description: "CallQueue and Agents Cards",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 0,
        callQueues: 2,
        agents: 2,
        callQuality: 1,
        sentiment: 0,
        reactions: 0,
        modalities: 1,
        messages: 0,
    },
    24: {
        cardFilterID: 24,
        description: "Call Queue Summary",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 0,
        callQueues: 1,
        agents: 0,
        callQuality: 1,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    25: {
        cardFilterID: 25,
        description: "Call Queue Cards",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 0,
        callQueues: 2,
        agents: 0,
        callQuality: 1,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    26: {
        cardFilterID: 26,
        description: "Agents Cards",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 0,
        callQueues: 0,
        agents: 2,
        callQuality: 1,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    27: {
        cardFilterID: 27,
        description: "People Comparison",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 3,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 1,
        reactions: 1,
        modalities: 1,
        messages: 1,
    },
    28: {
        cardFilterID: 28,
        description: "TeamSummary",
        date: 1,
        time: 1,
        teams: 1,
        groups: 0,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 1,
        reactions: 0,
        modalities: 1,
        messages: 1,
    },
    29: {
        cardFilterID: 29,
        description: "CallQueue and Agents Cards without call quality and modalities",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 0,
        callQueues: 2,
        agents: 2,
        callQuality: 0,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    30: {
        cardFilterID: 30,
        description: "CallsWithParticipation&JoinedAs",
            date: 1,
            time: 1,
            teams: 2,
            groups: 2,
            users: 2,
            callQueues: 0,
            agents: 0,
            callQuality: 1,
            sentiment: 0,
            reactions: 0,
            modalities: 1,
            messages: 0,
            callParticipation: 1,
            joinedAs:1,
    },
    31: {
        cardFilterID: 31,
        description: "MessagesWithoutReaction",
        date: 1,
        time: 1,
        teams: 2,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 1,
        reactions: 0,
        modalities: 0,
        messages: 1,
        msgParticipation: 1,
    },
    32: {
        cardFilterID: 32,
        description: "Calls_MeetingHabit",
        date: 1,
        time: 1,
        teams: 2,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
        callParticipation: 1,
        joinedAs:1,
    },
    33: {
        cardFilterID: 33,
        description: "MessagesWithoutSenitment&ReactionwithParticipation",
        date: 1,
        time: 1,
        teams: 2,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
        msgParticipation: 1,
    },
    34: {
        cardFilterID: 34,
        description: "CallNonTeams",
        date: 1,
        time: 1,
        teams: 0,
        groups: 2,
        users: 2,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 0,
        reactions: 0,
        modalities: 1,
        messages: 0,
        callParticipation: 1,
    },
    35: {
        cardFilterID: 35,
        description: "Management Style",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 1,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    36: {
        cardFilterID: 36,
        description: "Direct Report Overview",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 1,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 0,
        reactions: 0,
        modalities: 1,
        messages: 0,
    },
    37: {
        cardFilterID: 37,
        description: "collaborationNetwork",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 1,
        callQueues: 0,
        agents: 0,
        callQuality: 1,
        sentiment: 1,
        reactions: 1,
        modalities: 1,
        messages: 1,
        callParticipation: 1,
        collaborationType : 1,
    },
    38: {
        cardFilterID: 38,
        description: "CallVolumeTrends Cards",
        date: 1,
        time: 1,
        teams: 0,
        groups: 0,
        users: 0,
        callQueues: 2,
        agents: 2,
        callQuality: 1,
        sentiment: 0,
        reactions: 0,
        modalities: 0,
        messages: 0,
    },
    39: {
        cardFilterID: 39,
        description: "TeamActivity",
        date: 1,
        time: 1,
        teams: 2,
        groups: 0,
        users: 0,
        callQueues: 0,
        agents: 0,
        callQuality: 0,
        sentiment: 1,
        reactions: 0,
        modalities: 1,
        messages: 1,
    },
};

export const sentimentTypes = [
    {
        sentimentAnalysisTypeID: 0,
        description: "Positive",
    },
    {
        sentimentAnalysisTypeID: 1,
        description: "Neutral",
    },
    {
        sentimentAnalysisTypeID: 2,
        description: "Negative",
    },
];

export const reactionTypes = [
    {
        reactionTypeID: 0,
        Description: "Like",
    },
    {
        reactionTypeID: 1,
        Description: "Love",
    },
    {
        reactionTypeID: 2,
        Description: "Laugh",
    },
    {
        reactionTypeID: 3,
        Description: "Surprised",
    },
    {
        reactionTypeID: 4,
        Description: "Sad",
    },
    {
        reactionTypeID: 5,
        Description: "Angry",
    },
    {
        reactionTypeID: 6,
        Description: "No Reaction",
    },
];

export const callQualityTypes = [
    {
        callQualityID: 0,
        Description: "Good",
    },
    {
        callQualityID: 1,
        Description: "Average",
    },
    {
        callQualityID: 2,
        Description: "Poor",
    },
];

export const modalityTypes = [
    {
        modalityID: 0,
        modalityName: "Audio",
    },
    {
        modalityID: 1,
        modalityName: "Video",
    },
    {
        modalityID: 2,
        modalityName: "Phone",
    },
];
export const teamModalityTypes = [
    {
        modalityID: 0,
        modalityName: "Audio",
    },
    {
        modalityID: 1,
        modalityName: "Video",
    },
];
export const FilterTypes = [
    { value: 0, header: "People", isApplicable: false },
    { value: 1, header: "Team", isApplicable: false },
    { value: 2, header: "Group", isApplicable: false },
    { value: 3, header: "Call Queue", isApplicable: false },
    { value: 4, header: "Agent", isApplicable: false },
];

export const msgParticipantsTypes = [
    {
        Id : 0, Type : "Internal"
    },
    {
        Id : 1, Type : "External"
    },
]
export const callParticipantsTypes = [
    {
        Id : 0, Type : "Internal"
    },
    {
        Id : 1, Type : "External"
    },
]
export const joinedAsTypes = [
    {
        Id : 0, Type: "Organizer"
    },
    {
        Id : 1, Type : "Participant"
    }
]
export const collaborationTypes = [
    {
        Id: 0, Type: "One-to-One"
    },
    {
        Id: 1, Type: "Group"
    }
]

//export const WIDGETSWITHFILTERIDS = AllWidgets.reduce((acc, curr) => (acc[curr.cardWidgetID] = cardFilters[curr.cardFilterID], acc), {});
