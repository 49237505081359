import React, { useEffect, useState } from 'react'
import InsightsCard from './insightsCard'
import './insights.scss';
import { Avatar, Text, Loader, Flex, FlexItem, Box, Skeleton, Button } from '@fluentui/react-northstar';
import { Dialog } from '@progress/kendo-react-dialogs';
import ConfigurationApiService from '../../../services/ConfigurationApiService';
import DashboardApiService from '../../../services/DashboardApiService';
import AuthHelper from '../../../services/auth-helper';
import FilterPopup from './FilterPopup';
import { Breadcrumb } from '@fluentui/react-northstar'
import { AddIcon, ChevronEndMediumIcon, PersonIcon } from '@fluentui/react-icons-northstar'
import InsightsCardSkeleton from './insightsCardSkeleton';
import UserInsights from './userInsights';
import WindowContext from '../../Shared/Context/Context';



const transformData = (apiData, setManagerData, setIsUser, categoryData) => {
    console.log(apiData)
    let customerProfileMap = apiData.customerProfileDetails?.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;

    }, {});

    if (apiData.managerProfileDetails === null) {
        setIsUser(true)
    }
    else {
        setIsUser(false)
    }
    let customerProfile;

    if (apiData.managerProfileDetails) {
        customerProfile = customerProfileMap[apiData.managerProfileDetails.customerProfileId];
        const manager = apiData.managerProfileDetails;

        const userSentimentsArray = Object.values(manager.userSentiments);
        const positiveSentimentUsers = userSentimentsArray.filter(user => user.sentiment > 0);
        const negativeSentimentUsers = userSentimentsArray.filter(user => user.sentiment < 0);
        const neutralSentimentUsers = userSentimentsArray.filter(user => user.sentiment == 0);
        const userWellbeingScore = categoryData.filter(val => val.userGUID === manager.userGUID);

        const managerData =
        {
            vibe: 'positive',
            userName: manager.displayName,
            userGUID: manager.userGUID,
            userDesignation: manager.jobTitle,
            userEmail: manager.mail,
            userImage: manager.userImage,
            reporteeCount: manager.reporteeCount,
            userStatus: 'success',
            reportee: 'Shiv',
            reporteeDesignation: 'CTO',
            reporteeStatus: 'error',
            reporteeImage: '',
            progressType: 'productive-hours',
            meetingsPercentage: Number(manager.meetingTime),//Number(((manager.meetingTime / (manager.avgWorkingHours * 3600 * numberOfDays)) * 100).toFixed(1)),
            callsPercentage: Number(manager.callTime),//Number((((manager.messageTime + manager.callTime) / (manager.avgWorkingHours * 3600 * numberOfDays)) * 100).toFixed(1)),
            focusedPercentage: Number(100 - Number(manager.meetingTime + manager.callTime)),//Number(((((manager.avgWorkingHours * 3600 * numberOfDays) - (manager.meetingTime + manager.callTime + manager.messageTime)) / (manager.avgWorkingHours * 3600 * numberOfDays)) * 100).toFixed(1)),
            meetingsZone: customerProfile.meetingsZone ? customerProfile.meetingsZone : null,
            callsZone: customerProfile.callsZone ? customerProfile.callsZone : null,
            focusedZone: customerProfile ? customerProfile.focusedZone : null,
            expectedMeetingsPercentage: customerProfile ? customerProfile.percentageTimeInMeetings : 0,
            expectedCallsPercentage: customerProfile ? customerProfile.percentageTimeInCallsAndMessages : 0,
            expectedFocusedPercentage: customerProfile ? customerProfile.percentageTimeInFocus : 0,
            dviNeutralVal: neutralSentimentUsers.length,//Number(manager.neutralSentmentScore * 100),
            dviPositiveVal: positiveSentimentUsers.length,//Number(manager.positiveSentmentScore * 100),
            dviNegativeVal: negativeSentimentUsers.length,//Number(manager.negetiveSentmentScore * 100),
            categoryTag: categoryData.filter(tag => tag.isSelected === 1).map(tag => {
                const filteredData = userWellbeingScore.filter(v => v.questionCategoryName === tag.questionCategoryName && v.isSelected === 1);
                const score = filteredData.length > 0
                    ? Math.ceil(filteredData.reduce((sum, item) => sum + item.score, 0) / filteredData.length)
                    : 0;
                return {
                    label: tag.questionCategoryName,
                    val: score
                }
            })
                .filter((item, index, self) =>
                    index === self.findIndex(t => t.label === item.label && t.val === item.val)
                ),
            lvTypeMeeting: 'Negative',
            lvTypeMessages: 'Neutral',
            lvTypeFocused: 'Positive',
        }
        setManagerData(managerData);
    } else {
        setManagerData(null);
    }

    return apiData.userDetails.map(user => {
        customerProfile = customerProfileMap[user.customerProfileId];
        const userSentimentsArray = Object.values(user.userSentiments);
        const positiveSentimentUsers = userSentimentsArray.filter(user => user.sentiment > 0);
        const negativeSentimentUsers = userSentimentsArray.filter(user => user.sentiment < 0);
        const neutralSentimentUsers = userSentimentsArray.filter(user => user.sentiment == 0);
        const top5negativeSentimentUserGUIDs = Object.keys(user.userSentiments)
            .filter(key => user.userSentiments[key].sentiment < 0) // Filter only negative sentiments
            .sort((a, b) => user.userSentiments[b].sentiment - user.userSentiments[a].sentiment) // Sort by sentiment
            .slice(0, 5);
        const top5keyValuePairs = top5negativeSentimentUserGUIDs.map(key => ({
            key: key,
            value: user.userSentiments[key]
        }));
        const remainingUserIds = Object.keys(user.userSentiments).filter(user => !top5negativeSentimentUserGUIDs.includes(user));
        const remainingkeyValuePairs = remainingUserIds.map(key => ({
            key: key,
            value: user.userSentiments[key]
        }));
        const userWellbeingScore = categoryData.filter(val => val.userGUID === user.userGUID);
        return {
            vibe: 'neutral',
            userName: user.displayName,
            userGUID: user.userGUID,
            userDesignation: user.jobTitle,
            userEmail: user.mail,
            userImage: user.userImage,
            reporteeCount: user.reporteeCount,
            userStatus: 'success',
            reportee: user.managerDisplayName,
            reporteeDesignation: user.managerDesignation,
            reporteeStatus: 'success',
            reporteeImage: user.managerPhoto,
            progressType: 'productive-hours',
            meetingsPercentage: Number(user.meetingTime),//Number(((user.meetingTime / avgWorkingHoursInSeconds) * 100).toFixed(1)),
            callsPercentage: Number(user.callTime),//Number((((user.messageTime + user.callTime) / avgWorkingHoursInSeconds) * 100).toFixed(1)),
            focusedPercentage: Number(100 - Number(user.meetingTime + user.callTime)),//Number((((avgWorkingHoursInSeconds - (user.meetingTime + user.callTime + user.messageTime)) / avgWorkingHoursInSeconds) * 100).toFixed(1)),
            meetingsZone: customerProfile.meetingsZone ? customerProfile.meetingsZone : null,
            callsZone: customerProfile.callsZone ? customerProfile.callsZone : null,
            focusedZone: customerProfile ? customerProfile.focusedZone : null,
            expectedMeetingsPercentage: customerProfile ? customerProfile.percentageTimeInMeetings : 0,
            expectedCallsPercentage: customerProfile ? customerProfile.percentageTimeInCallsAndMessages : 0,
            expectedFocusedPercentage: customerProfile ? customerProfile.percentageTimeInFocus : 0,
            dviNeutralVal: neutralSentimentUsers.length,
            dviPositiveVal: positiveSentimentUsers.length,
            dviNegativeVal: negativeSentimentUsers.length,
            categoryTag: categoryData.filter(tag => tag.isSelected === 1).map(tag => {
                const filteredData = userWellbeingScore.filter(v => v.questionCategoryName === tag.questionCategoryName && v.isSelected === 1);
                const score = filteredData.length > 0
                    ? Math.ceil(filteredData.reduce((sum, item) => sum + item.score, 0) / filteredData.length)
                    : 0;
                return {
                    label: tag.questionCategoryName,
                    val: score
                }
            })
                .filter((item, index, self) =>
                    index === self.findIndex(t => t.label === item.label && t.val === item.val)
                ),
            lvTypeMeeting: 'Neutral',
            lvTypeMessages: 'Neutral',
            lvTypeFocused: 'Neutral',
            topSentimentUsers: top5keyValuePairs,
            otherSentimentUsers: remainingkeyValuePairs
        };
    });
};


const ProfileSettingHeader = (
    { profileName = 'Profile Name', sysDefine = false, assignedTo = '! No data', handleUsers, handleEdit, handleDelete }
) => {
    return (
        <div className='row insights-card-header align-items-center'>
            <div className='col px-2 labels'>
                <div className='name-label'>{profileName}</div>
                {sysDefine && <div className='org-email'>System defined</div>}
                <div onClick={handleUsers} className='assigned-users-label'>Assigned to {assignedTo} users</div>
            </div>
            <div className='col d-flex px-2 labels justify-content-end'>
                <div className="mx-1"><button onClick={handleEdit} className='bg-custome-light border px-3 py-1 rounded-pill'>Edit</button></div>
                <div className="mx-1"><button onClick={handleDelete} className='bg-danger-light px-2 py-1 rounded-pill'>Delete</button></div>
            </div>
        </div >
    )
}
function fnGetZoneIndicator(percentageValue, zones, label) {
    if ((percentageValue == 0 && (label == "Meetings" || label == "Messages")))
        return "disabled";

    if (0 < percentageValue < zones[0]) {
        return 'Negative';
    }
    else if (zones[0] < percentageValue < zones[1]) {
        return 'Neutral';
    }
    else if (zones[1] < percentageValue < zones[2]) {
        return 'Positive';
    }
    else if (zones[2] < percentageValue < zones[3]) {
        return 'Neutral';
    }
    else if (zones[3] < percentageValue < 100) {
        return 'Negative';
    }
    else {
        return ''
    }
}
const ListViewIndicator = ({ percentageValue, zones, label = 'Activity' }) => {
    const type = fnGetZoneIndicator(percentageValue, zones, label);

    return (
        <div className='col-auto px-0 mr-2 d-flex align-items-center'>
            {type && <div className={`list-view-indicator ${type}`} title={`${type} zone`}></div>}
            <div className='list-view-label px-2' title={label === 'Messages' ? 'Chats & Messages' : label}>{label}</div>
        </div>
    )
}

const TeamVibe = (
    { managerVibe, loading = false, managerName = '!No data', managerStatus = 'unknown', managerDesignation = '!No data', managerData, userLoading }
) => {
    return (<>
        {(loading && !userLoading) ?
            <div className='row align-items-center ml-auto'>

                <div className="insights-card cursor-default mx-3 shadow-bg-filter-header">
                    <div className="row">
                        <div className="col align-items-center justify-content-center insights-card-header">
                            <Skeleton.Shape width='60px' height='60px' />
                        </div>
                    </div>
                </div>
            </div> : managerData && !userLoading &&
            <div className='row align-items-center ml-auto'>

                <div className="insights-card cursor-default mx-3 shadow-bg-filter-header">
                    <div className="row">
                        <div className="col align-items-center justify-content-center insights-card-header">
                            <div className='name-label'>Team Vibe</div>
                            <div className='vibe-indicator align-self-end mt-4'>
                                <div className='vibe-indicator-emoji' >
                                    {managerVibe === 'positive' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Halo.png" alt="Neutral Face" width="38" height="38" />
                                        : (managerVibe === 'negative' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Worried%20Face.png" alt="Worried Face" width="38" height="38" />
                                            : <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Neutral%20Face.png" alt="Neutral Face" width="38" height="38" />
                                        )}
                                </div>
                                <div className='vibe-indicator-text bg-primary text-right'>
                                    {managerVibe === 'positive' ? 'Positive' : (managerVibe === 'negative' ? 'Negative' : 'Neutral')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
    )
}

const InsightsHeader = (
    { managerVibe, toUserView, managerData, loading = false, userLoading, ringCounts = 7, fetchData, ...data }
) => {
    const [headDViewOpen, setHeadDViewOpen] = useState(false)
    const handleDialogOpen = () => {
        setHeadDViewOpen(!headDViewOpen)
    }
    return (
        <WindowContext>
            {
                (context) => (
                    <>
                        {loading || userLoading ?
                            (userLoading ?
                                <>
                                    <div class="row mx-3 align-items-center" style={{ width: "392.6px" }}>
                                        <div className='col-auto'>
                                            <Skeleton.Avatar size='large' className='rounded-circle' />
                                        </div>
                                        <div className='col labels'>
                                            <Skeleton.Line width="75%" />
                                            <Skeleton.Line width="40%" />
                                            <Skeleton.Line width="60%" />
                                        </div>
                                    </div>
                                    {data.reportee ?
                                        <div className='row ml-auto mx-3 align-items-center' style={{ width: "392.6px" }}>
                                            <div className='col px-2 labels text-right'>
                                                <Skeleton.Line width="35%" />
                                                <Skeleton.Line width="70%" />
                                                <Skeleton.Line width="40%" />
                                            </div>
                                            <div className='col-auto'>
                                                <Skeleton.Avatar size='large' className=' rounded-circle' />
                                            </div>
                                        </div>
                                        :
                                        <div class="row mx-3 ml-auto" style={{ width: "392.6px" }}>
                                            <div className='col px-2 labels text-right'>
                                                <Skeleton.Line width="35%" />
                                                <Skeleton.Line width="70%" />
                                            </div>
                                        </div>}
                                </>
                                :
                                <div className='row align-items-center'>
                                    <div className='position-relative cursor-default'>
                                        <div className='position-relative cursor-default'>
                                            <Skeleton animation='pulse'><Skeleton.Avatar size='largest' className='shadow rounded-circle' /></Skeleton>
                                        </div>
                                    </div >
                                    <div className="insights-card cursor-default mx-3 shadow-bg-filter-header cursor-pointer" style={{ width: "392.6px" }}>
                                        <div className="container">
                                            <Skeleton animation='pulse'>
                                                <div className="row align-items-center insights-card-header">
                                                    <div className='col px-0 labels border-r-uinsights mr-3'>
                                                        <Skeleton.Line width="75%" />
                                                        <Skeleton.Line width="40%" />
                                                        <div className="insights-card-body">
                                                            <div className="container">
                                                                <div className='row gap-2 justify-content-center'>
                                                                    <Skeleton.Line width="80%" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Skeleton.Shape width='60px' height='60px' />
                                                </div>
                                            </Skeleton>
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            ((managerData && !userLoading && !toUserView) ?
                                <div className="col">
                                    <div className='row align-items-center'>
                                        <div className='position-relative cursor-pointer'>

                                            {!context.mediaCategory.sm && <Avatar
                                                onClick={() => fetchData(null, managerData.userGUID)}
                                                className='shadow rounded-circle'
                                                size='largest'
                                                name={managerData.userName}
                                                status={managerData.status}
                                                image={managerData.userImage}
                                                styles={{ zIndex: "1" }}
                                            />}
                                            {
                                                Array.from({ length: ringCounts }).map((_, index) => (
                                                    <div key={index} className={`click-disabled user-rings ${ringCounts <= 4 ? 'rings-danger' : ringCounts >= 7 ? 'rings-success' : 'rings-warning'}`} style={{ height: `${(index * 16) * (index * 2) + 'px'}`, width: `${(index * 16) * (index * 2) + 'px'}` }}></div>
                                                ))
                                            }
                                        </div>
                                        <div className={` cursor-default shadow-bg-filter-header cursor-pointer ${context.mediaCategory.sm ? "w-100 px-3 pb-3 pt-2" : "insights-card mx-3"}`} onClick={() => fetchData(null, managerData.userGUID)}>
                                            <div className="container">
                                                <div className="row align-items-center insights-card-header">
                                                    <div className='col px-0 labels border-r-uinsights'>
                                                        <div class="row justify-content-between">
                                                            <div class="col">
                                                                <div className='name-label'>{managerData.userName}</div>
                                                                <div className='designation-label'>{managerData.userDesignation}</div>
                                                            </div>
                                                            {context.mediaCategory.sm && <div class="col-auto">
                                                                <div className=''>Team Vibe</div>
                                                                <div className='bg-light rounded px-1 border text-center shadow-sm'>Neutral</div>
                                                            </div>}
                                                        </div>
                                                        <div className="insights-card-body">
                                                            <div className="container">
                                                                <div className='row gap-2 justify-content-center'>
                                                                    <ListViewIndicator percentageValue={managerData.meetingsPercentage} zones={managerData.meetingsZone} label='Meetings' />
                                                                    <ListViewIndicator percentageValue={managerData.callsPercentage} zones={managerData.callsZone} label='Messages' />
                                                                    <ListViewIndicator percentageValue={100 - (managerData.meetingsPercentage + managerData.callsPercentage)} zones={managerData.focusedZone} label='Focused' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* {teamView && <div className='org-email'>{userEmail}</div>} */}
                                                    </div>
                                                    {/*<div className='vibe-indicator align-self-end mb-2'>*/}
                                                    {/*    <div className='vibe-indicator-emoji' >*/}
                                                    {/*        {managerVibe === 'positive' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Halo.png" alt="Neutral Face" width="38" height="38" />*/}
                                                    {/*            : (managerVibe === 'negative' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Worried%20Face.png" alt="Worried Face" width="38" height="38" />*/}
                                                    {/*                : <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Neutral%20Face.png" alt="Neutral Face" width="38" height="38" />*/}
                                                    {/*            )}*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className='vibe-indicator-text bg-primary text-right'>*/}
                                                    {/*        {managerVibe === 'positive' ? 'Positive' : (managerVibe === 'negative' ? 'Negative' : 'Neutral')}*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : !userLoading &&
                                <>
                                    <div class="col-auto pl-0">
                                        <div class={`row align-items-center insights-card-header ${context.mediaCategory.sm ? "ml-0 my-2" : "ml-3"}`}>
                                            {<Avatar
                                                name={data.userName}
                                                image={data.userImage}
                                                size="large"
                                                status={data.userStatus}
                                            />}
                                            <div className='col-auto px-2 labels'>
                                                <div className='name-label'>{data.userName}</div>
                                                <div className='designation-label'>{data.userDesignation}</div>
                                                <div className='org-email'>{data.userEmail}</div>
                                            </div>
                                            {data.reporteeCount === 1 && <Button
                                                className={context.mediaCategory.sm ? "mb-auto mr-0" : "mb-auto"}
                                                styles={{ marginRight: '4px' }}
                                                icon={<i class="bi bi-diagram-2" style={{ fontSize: '21px' }}></i>}
                                                iconOnly
                                                title="Manager Insight"
                                                onClick={() => fetchData(data.userGUID)}
                                            />}
                                        </div>
                                    </div>
                                    {data.reportee &&
                                        <div class="col-auto pl-1">
                                            <div className={`row align-items-center ${context.mediaCategory.sm ? "ml-0" : "mr-3"}`}>
                                                <div className='col-auto px-2 labels text-right'>
                                                    <div className='reports-to-label text-primary'>Reports to</div>
                                                    <div className='name-label'>{data.reportee}</div>
                                                    <div className='designation-label'>{data.reporteeDesignation}</div>
                                                </div>
                                                {!context.mediaCategory.sm &&
                                                    <Avatar
                                                        name={data.reportee}
                                                        size="large"
                                                        status={data.reporteeStatus}
                                                        image={data.reporteeImage}
                                                    />}
                                            </div>
                                        </div>
                                    }
                                </>

                            )
                        }
                        {/* <BreadCrumb stack={stack} setStack={setStack} fetchData={fetchData} setSelectedMyNetwork={setSelectedMyNetwork} /> */}
                        {/* {
                (headDViewOpen) && managerData && (<Dialog
                    width={464}
                    height={464}
                    title={<div className='name-label'>Detail View</div>}
                    onClose={handleDialogOpen}
                >
                    <InsightsCard key={1} teamView={true} forDialog {...managerData} isNotManager={managerData == null ? true : false} fetchData={fetchData} />
                </Dialog>)
            } */}
                    </>
                )
            }
        </WindowContext>

    )
}

const BreadCrumb = ({ stack, setStack, fetchData, setSelectedMyNetwork }) => {

    const handleBreadcrumbClick = (index, userguid, ismanager) => {
        //debugger
        setStack(stack.slice(0, index));
        setSelectedMyNetwork(null);
        if (ismanager) {
            fetchData(userguid);
        } else {
            fetchData(null, userguid);
        }

    };

    return (
        <div>
            <Breadcrumb aria-label="breadcrumb">
                {stack.map((user, index) => (
                    <React.Fragment key={user.userguid}>
                        <Breadcrumb.Item>
                            {index === stack.length - 1 ? (
                                <span>{user.username}</span>
                            ) : (
                                <Breadcrumb.Link
                                    href="javascript:void(0);"
                                    onClick={() => handleBreadcrumbClick(index, user.userguid, user.ismanager)}
                                >
                                    {user.username}
                                </Breadcrumb.Link>
                            )}
                        </Breadcrumb.Item>
                        {index < stack.length - 1 && (
                            <Breadcrumb.Divider>
                                <ChevronEndMediumIcon />
                            </Breadcrumb.Divider>
                        )}
                    </React.Fragment>
                ))}
            </Breadcrumb>
        </div>
    );
};

function Insights() {
    const [managerIDSelected, setManagerIDSelected] = useState(null);
    const [userIDSelected, setUserIDSelected] = useState(null);
    const [myNetworkData, setMyNetworkData] = useState(null);
    const [stack, setStack] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [wellbeingResponsesLists,setWellbeingResponsesLists] = useState([]);

    const addItemToStack = (username, userguid, ismanager) => {
        setStack(prevStack => {
            const lastEntry = prevStack[prevStack.length - 1];
            if (lastEntry && lastEntry.userguid === userguid) {
                return prevStack;
            }
            return [...prevStack, { username, userguid, ismanager }];
        });
    };

    const calculateWorkingDays = (startTime, endTime) => {
        const startDate = new Date(startTime);
        const endDate = new Date(endTime);
        if (endDate < startDate) {
            return 0;
        }

        let numberOfWorkingDays = 0;

        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            const dayOfWeek = date.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                numberOfWorkingDays++;
            }
        }
        return numberOfWorkingDays;
    }

    const fetchData = (managerId = null, userGUID = null) => {
        let APIdata = {
            StartTime: startDate,
            EndTime: endDate,
            TimeCategory: timeCategory
        };
        if (managerId !== null) {
            setLoading(true);
            APIdata.ManagerId = managerId;
            APIdata.UserGUID = null;
            setManagerIDSelected(managerId);
            setUserIDSelected(null);
        }
        else if (userGUID !== null) {
            setUserLoading(true);
            APIdata.UserGUID = userGUID;
            APIdata.ManagerId = null;
            setManagerIDSelected(null);
            setUserIDSelected(userGUID);
        } else if (managerIDSelected !== null) {
            APIdata.ManagerId = managerIDSelected;
            setLoading(true);
        } else if (userIDSelected !== null) {
            APIdata.UserGUID = userIDSelected;
            setUserLoading(true);
        }

        AuthHelper.getAccessToken((token) => {
            ConfigurationApiService.GetTeamInsights(token, APIdata).then((response) => {
                if (response) {

                    let transformedData = transformData(response, setManagerData, setIsUser, categoryData);
                    setData(transformedData);
                    if (response.managerProfileDetails && transformedData.length > 1) {
                        let manager = response.managerProfileDetails;
                        addItemToStack(manager.displayName, manager.userGUID, true)
                    } else {
                        addItemToStack(transformedData[0].userName, transformedData[0].userGUID, false)
                    }

                } else {
                    alert('Error in fetching data');
                }
                setLoading(false);
                setUserLoading(false);
            });
        });
    }

    const loadUserProfileData = () => {
        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.GetTeamInsightsMasterData(token).then(
                (response) => {
                    if (response != undefined && response != "") {
                        if (response.userProfiles !== "") {
                            const myPeopleDropdownValues = response.userProfiles.map(user => ({
                                id: user.isManager,
                                key: user.userGUID,
                                header: user.displayName,
                                image: user.isManager ? '../../images/isManager.png' : '../../images/isUser.png',
                                content: 'Role'
                            }));
                            setMyNetworkData(myPeopleDropdownValues);
                        } else {
                            const defaultEntry = [{
                                id: 1,
                                key: 'no-network',
                                header: 'No Network Below You',
                                disabled: true
                            }];

                            setMyNetworkData(defaultEntry);
                        }
                        if (response.categoryData !== "") {
                            setCategoryData(response.categoryData);
                        }
                        if(response.wellbeingResponsesLists!==""){
                            setWellbeingResponsesLists(response.wellbeingResponsesLists);
                        }
                    }
                    window.dispatchEvent(new Event("resize"));
                }
            );
        });
    };

    useEffect(() => {
        if (categoryData.length > 0) {//665633a2-6100-4244-aea6-6c50d09de480
            fetchData();
        } else {
            loadUserProfileData();
        }
    }, [categoryData]);

    const [data, setData] = useState([]);
    const [managerData, setManagerData] = useState();
    const [isUser, setIsUser] = useState();
    const [toUserView, setToUserView] = useState();
    const [loading, setLoading] = useState(true);
    const [userLoading, setUserLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [timeCategory, setTimeCategory] = useState(1);
    const [period, setPeriod] = useState(1);
    const [selectedMyNetwork, setSelectedMyNetwork] = useState(null);


    return (
        <WindowContext>{
            (context) => (
                <div className='mx-2 overflow-hidden h-100'>
                    <Flex space='between' vAlign='center' gap="gap.small" className='insights-crum pl-2'>
                        <BreadCrumb stack={stack} setStack={setStack} fetchData={fetchData} setSelectedMyNetwork={setSelectedMyNetwork} />
                        <FlexItem push>
                            <FilterPopup
                                startDate={startDate}
                                period={period}
                                setPeriod={setPeriod}
                                endDate={endDate}
                                timeCategory={timeCategory}
                                setEndDate={setEndDate}
                                setStartDate={setStartDate}
                                setTimeCategory={setTimeCategory}
                                fetchData={fetchData}
                                myNetworkData={myNetworkData}
                                setSelectedMyNetwork={setSelectedMyNetwork}
                                selectedMyNetwork={selectedMyNetwork} />
                        </FlexItem>
                    </Flex>
                    <>
                        <div className="container container-cst align-items-center pr-md-0 my-md-4">
                            <div className='row justify-content-between my-md-3'>
                                <InsightsHeader {...data[0]} loading={loading} userLoading={userLoading} managerData={managerData} toUserView={toUserView} fetchData={fetchData} stack={stack} setStack={setStack} setSelectedMyNetwork={setSelectedMyNetwork}
                                />


                                {!context.mediaCategory.sm && !toUserView && <TeamVibe userLoading={userLoading} loading={loading} managerData={managerData} toUserView />}

                            </div>

                            {/* <div className='container'>
                                    <div className='insights-card cursor-default m-3 detail-view-width'>
                                        <div className="container h-100">
                                            <ProfileSettingHeader
                                                sysDefine
                                                assignedTo={34}
                                            />
                                            <div className="insights-card-body">
                
                                                <div className='row border-top pt-2'>
                                                    <div className="col px-2">
                                                        <HSCProgress progressLabel={false} type={'productive-hours'} label={'Meetings'} setMark={14} />
                                                        <HSCProgress progressLabel={false} type={'productive-hours'} label={'Messages'} setMark={32} />
                                                        <HSCProgress progressLabel={false} type={'productive-hours'} label={'Focused'} setMark={54} />
                                                    </div>
                                                </div>
                
                                            </div>
                
                                        </div>
                                    </div>
                                </div> */}
                        </div>
                        {!managerData && !loading && < div className='container container-cst border-b-uinsights'></div>}
                        {/* <div className="container my-3">
                                    <div className='row'>
                                        <div className="col text-right">
                                            <button className='bg-custome-light border rounded-pill px-3 py-2' onClick={() => setDView(!dView)}>detail View</button>
                                        </div>
                                    </div>
                                </div> */}

                        <div className={`px-3 mx-auto container-cst px-0 insights-body ${!managerData ? "h-user-insights" : "h-insights"}`}>
                            {loading || userLoading ?
                                <div className="row gap-24 mx-auto my-3">
                                    {(
                                        loading
                                            ? Array.from({ length: 6 }).map((_, index) => (
                                                <InsightsCardSkeleton key={index} />
                                            ))
                                            : Array.from({ length: 6 }).map((_, index) => (
                                                <InsightsCardSkeleton key={index} teamView={true} />
                                            ))
                                    )}
                                </div> : ((managerData && !toUserView) ?
                                    < div className={`row gap-24 mx-auto my-3 ${context.mediaCategory.sm ? "justify-content-center" : " "}`}>
                                        {data
                                            .filter((d) => d.userGUID !== managerData.userGUID)
                                            .map((d, index) =>
                                                <InsightsCard
                                                    key={index}
                                                    teamView={true}
                                                    {...d}
                                                    fetchData={fetchData}

                                                />
                                            )}
                                    </div> :
                                    // (!toUserView &&
                                    <UserInsights
                                        {...data[0]}
                                        fetchData={fetchData}
                                        responseScale ={wellbeingResponsesLists}
                                    />
                                    // <UserInsights
                                    //     {...managerData}
                                    //     fetchData={fetchData}
                                    // />)
                                )}

                        </div>

                    </>
                </div >
            )
        }
        </WindowContext>
    )
}

export default Insights