import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import "./GroupedGrid.scss";
import { CircleIcon } from "@fluentui/react-icons-northstar";
import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';

class cellWithIconAudio extends React.Component {
  render() {
    let avgCallQuality = this.props.dataItem.audio.avgCallQuality;
    let _className = "st_good";
    switch (avgCallQuality) {
      case "Poor":
        _className = "st_poor";
        break;
      case "Average":
        _className = "st_average";
        break;
      case "Good":
        _className = "st_good";
        break;
      default:
        _className = "st_none";
        avgCallQuality = "N/A";
    }
    return (
      <td className={this.props.className}>
        <CircleIcon className={_className} /> {avgCallQuality}
      </td>
    );
  }
}

class cellWithIconVideo extends React.Component {
  render() {
    let avgCallQuality = this.props.dataItem.video.avgCallQuality;
    let _className = "st_good";
    switch (avgCallQuality) {
      case "Poor":
        _className = "st_poor";
        break;
      case "Average":
        _className = "st_average";
        break;
      case "Good":
        _className = "st_good";
        break;
      default:
        _className = "st_none";
        avgCallQuality = "N/A";
    }
    return (
      <td className={this.props.className}>
        <CircleIcon className={_className} /> {avgCallQuality}
      </td>
    );
  }
}

class cellWithIconSentimentPeer extends React.Component {
  render() {
    let sentimentScore = this.props.dataItem.peer.sentimentScore;
    let _className = "st_good";
    switch (sentimentScore) {
      case "Negative":
        _className = "st_poor";
        break;
      case "Neutral":
        _className = "st_average";
        break;
      case "Positive":
        _className = "st_good";
        break;
      default:
        _className = "st_average";
        sentimentScore = "Neutral";
    }
    return (
      <td className={this.props.className}>
        <CircleIcon className={_className} /> {sentimentScore}
      </td>
    );
  }
}

class cellWithIconSentimentTeam extends React.Component {
  render() {
    let sentimentScore = this.props.dataItem.team.sentimentScore;
    let _className = "st_good";
    switch (sentimentScore) {
      case "Negative":
        _className = "st_poor";
        break;
      case "Neutral":
        _className = "st_average";
        break;
      case "Positive":
        _className = "st_good";
        break;
      default:
        _className = "st_average";
        sentimentScore = "Neutral";
    }
    return (
      <td className={this.props.className}>
        <CircleIcon className={_className} /> {sentimentScore}
      </td>
    );
  }
}

class cellWithIconSentimentTotal extends React.Component {
  render() {
    let sentimentScore = this.props.dataItem.total.sentimentScore;
    let _className = "st_good";
    switch (sentimentScore) {
      case "Negative":
        _className = "st_poor";
        break;
      case "Neutral":
        _className = "st_average";
        break;
      case "Positive":
        _className = "st_good";
        break;
      default:
        _className = "st_none";
        sentimentScore = "";
    }
    return (
      <td className={this.props.className}>
        <CircleIcon className={_className} /> {sentimentScore}
      </td>
    );
  }
}

class cellWithIconSentiment extends React.Component {
  render() {
    let sentimentScore = this.props.dataItem.sentimentScore;
    let _className = "st_good";
    switch (sentimentScore) {
      case "Negative":
        _className = "st_poor";
        break;
      case "Neutral":
        _className = "st_average";
        break;
      case "Positive":
        _className = "st_good";
        break;
      case "Unavailable":
        _className = "st_unavailable";
        break;
      default:
        _className = "st_none";
        sentimentScore = "";
    }
    return (
      <td className={this.props.className}>
        <CircleIcon className={_className} /> {sentimentScore}
      </td>
    );
  }
}

export default function GroupedGrid({ data, widgetId, downloadCard, valueAfterCardDownload }) {
  const [sort, setSort] = React.useState([]);
  const [cardDownload, setCardDownload] = React.useState(false);
  const _export = React.useRef(null);
  React.useEffect(() => {
    setCardDownload(downloadCard)
    if(cardDownload)
    {
      if (_export.current !== null) {
        _export.current.save();
      }
      setCardDownload(false);
      valueAfterCardDownload(false);
    }
  });
  
  return (
    <>
      {data && data.length > 0 && widgetId === 5 && (
        <ExcelExport data={data} ref={_export}>
          <Grid
          className="groupedGrid"
          style={{ height: "100%" }}
          // resizable={true}
          // reorderable={true}
          data={orderBy(data, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <Column
            field="callType"
            title="Call Type"
            width={100}
            resizable={false}
            locked
          />
          <Column title="Audio">
            <Column className="darken" width={105} field="audio.organised" title="Organised" />
            <Column
              className="darken" 
              width={111}
              field="audio.participated"
              title="Participated"
            />
            <Column
              className="darken" 
              width={112}
              field="audio.totalTalkTime"
              title="Total Talk Time"
            />
            <Column
              className="darken" 
              width={112}
              field="audio.avgTalkTime"
              title="Avg. Talk Time"
            />
            <Column
              className="darken" 
              width={112}
              field="audio.avgCallQuality"
              title="Avg. Call Quality"
              cell={cellWithIconAudio}
            />
          </Column>
          <Column title="Video">
            <Column width={105} field="video.organised" title="Organised" />
            <Column
              width={111}
              field="video.participated"
              title="Participated"
            />
            <Column
              width={112}
              field="video.totalTalkTime"
              title="Total Talk Time"
            />
            <Column
              width={112}
              field="video.avgTalkTime"
              title="Avg. Talk Time"
            />
            <Column
              width={112}
              field="video.avgCallQuality"
              title="Avg. Call Quality"
              cell={cellWithIconVideo}
            />
          </Column>
        </Grid>
        </ExcelExport>
      )}
      
      {data && data.length > 0 && widgetId === 15 && (
        <ExcelExport data={data} ref={_export}>
          <Grid
            className="groupedGrid"
            style={{ height: "100%" }}
            data={orderBy(data, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
          >
            <Column field="displayName" title="Name" width={80} locked />
            <Column title="Calls">
              <Column field="totalCalls" title="Total (External)" width={80} />
              <Column field="talkTime" title="Talk Time" width={80} />
              <Column field="teamCallsMeetings" title="Meetings/Calls" width={100} />
              <Column field="videoCalls" title="% Video" width={60} />
              <Column field="phoneCalls" title="Phone Calls" width={60} />
            </Column>
            <Column title="Meetings">            
              <Column className="darken" field="totalMeetings" title="Total (External)" width={85} />
              <Column className="darken" field="totalTimeInMeetings" title="Time in Meetings" width={120} />
              <Column className="darken" field="perOrganizedMeetings" title="Organised %" width={100} />
              <Column className="darken" field="perRecurringMeetings" title="Recurring %" width={85} />
            </Column>
            <Column title="Messages">
              <Column field="chats" title="Chats" width={60} />
              <Column field="posts" title="Posts" width={60} />
              <Column field="replies" title="Replies" width={60} />
              <Column field="sentimentScore" title="Sentiment" width={100} cell={cellWithIconSentiment}/>
            </Column>
          </Grid>
        </ExcelExport>
      )}
      {data && data.length > 0 && (widgetId === 34) && (
        <ExcelExport data={data} ref={_export}>
          <Grid
          className="groupedGrid"
          style={{ height: "100%" }}
          // resizable={true}
          // reorderable={true}
          data={orderBy(data, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <Column
            field="userName"
            title="Name"
            width={100}
            resizable={false}
            locked
          />
          <Column title="PeerToPeer">
            <Column className="darken" width={80} field="peer.sent" title="Sent" />
            <Column className="darken" width={80} field="peer.replied" title="Replied" />
            <Column
              className="darken" 
              width={126}
              field="peer.sentimentScore"
              title="Sentiment Score"
              cell={cellWithIconSentimentPeer}
            />
          </Column>
          <Column title="Team">
            <Column width={80} field="team.sent" title="Sent" />
            <Column width={80} field="team.replied" title="Replied" />
            <Column
              width={126}
              field="team.sentimentScore"
              title="Sentiment Score"
              cell={cellWithIconSentimentTeam}
            />
          </Column>
          <Column title="Total">
            <Column className="darken" width={80} field="total.sent" title="Sent" />
            <Column className="darken" width={80} field="total.replied" title="Replied" />
            <Column
              className="darken" 
              width={126}
              field="total.sentimentScore"
              title="Sentiment Score"
              cell={cellWithIconSentimentTotal}
            />
          </Column>
        </Grid>
        </ExcelExport>
      )}
      {data && data.length > 0 && widgetId === 40 && (
              <ExcelExport data={data} ref={_export}>

          <Grid>
                      </Grid>
        {/*  className="groupedGrid"*/}
        {/*  style={{ height: "100%" }}*/}
        {/*  // resizable={true}*/}
        {/*  // reorderable={true}*/}
        {/*  data={orderBy(data, sort)}*/}
        {/*  sortable={true}*/}
        {/*  sort={sort}*/}
        {/*  onSortChange={(e) => {*/}
        {/*    setSort(e.sort);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Column*/}
        {/*    field="userName"*/}
        {/*    title="Name"*/}
        {/*    width={100}*/}
        {/*    resizable={false}*/}
        {/*    locked*/}
        {/*              />*/}
        {/*              <Column title="Total">*/}
                         
        {/*                  <Column*/}
        {/*                      width={92}*/}
        {/*                      field="total.CallsandMeetings"*/}
        {/*                      title="Calls and Meetings(External)"*/}
        {/*                  />*/}
        {/*                  <Column*/}
        {/*                      width={106}*/}
        {/*                      field="total.totalTalkTime"*/}
        {/*                      title="Talk Time"*/}
        {/*                  />*/}
        {/*                  <Column*/}
        {/*                      width={106}*/}
        {/*                      field="total.Video"*/}
        {/*                      title="Video"*/}
        {/*                  />*/}
        {/*                  <Column*/}
        {/*                      width={106}*/}
        {/*                      field="total.Audio"*/}
        {/*                      title="Audio"*/}
        {/*                  />*/}
        {/*              </Column>*/}
        {/*  <Column title="OneToOne">*/}
        {/*                  <Column className="darken" width={92} field="peer.CallsandMeetings" title="Calls and Meetings(External)" />*/}
        {/*                  <Column*/}
        {/*                      className="darken"*/}
        {/*                      width={106}*/}
        {/*                      field="peer.totalTalkTime"*/}
        {/*                      title="Talk Time"*/}
        {/*                  /><Column*/}
        {/*                      width={106}*/}
        {/*                      field="peer.Video"*/}
        {/*                      title="Video"*/}
        {/*                  />*/}
        {/*                  <Column*/}
        {/*                      width={106}*/}
        {/*                      field="peer.Audio"*/}
        {/*                      title="Audio"*/}
        {/*                  />*/}
            
                         
        {/*  </Column>*/}
        {/*  <Column title="Group">*/}
        {/*                  <Column width={92} field="group.CallsandMeetings" title="Calls and Meetings(External)" />*/}
        {/*                  <Column*/}
        {/*                      width={106}*/}
        {/*                      field="group.totalTalkTime"*/}
        {/*                      title="Talk Time"*/}
        {/*                  />*/}
        {/*    <Column*/}
        {/*      width={92}*/}
        {/*      field="group.Video"*/}
        {/*                      title="Video"*/}
        {/*                  />*/}
        {/*                  <Column*/}
        {/*                      width={92}*/}
        {/*                      field="group.Audio"*/}
        {/*                      title="Audio"*/}
        {/*                  />*/}
            
        {/*  </Column>*/}
          
         
        
        </ExcelExport>
      )}
    </>
  );
}
