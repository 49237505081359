////export const AllWidgets = [
  
////  {
////    cardWidgetID: 7,
////    widgetName: "Network Quality %",//"Call Quality",
////    cardViewTypeID: 5,
////    Visualization: "piechart",
////    cardFilterID: 2,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 1,//2
////    isAvailable: 1,
////    cardSizeID: 0,
////  },
////  {
////    cardWidgetID: 8,
////    widgetName: "Message Sentiment %",//"Chat Sentiment",
////    cardViewTypeID: 5,
////    Visualization: "piechart",
////    cardFilterID: 3,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 3,
////    isAvailable: 1,
////    cardSizeID: 0,
////  },
////  {
////    cardWidgetID: 9,
////    widgetName: "Reactions",
////    cardViewTypeID: 6,
////    Visualization: "generalanalytics",
////    cardFilterID: 15,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 3,
////    isAvailable: 1,
////    cardSizeID: 0,
////  },
////  {
////    cardWidgetID: 10,
////    widgetName: "Collaboration Trends",
////    cardViewTypeID: 7,
////    Visualization: "barchart",
////    cardFilterID: 1,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 0,
////    categoryTypeID: 3,
////    collaborationTypeID: 4,
////    isAvailable: 1,
////    cardSizeID: 1,
////  },
////  {
////    cardWidgetID: 11,
////    widgetName: "Network Quality Trends",//"Call Quality Trends",
////    cardViewTypeID: 7,
////    Visualization: "barchart",
////    cardFilterID: 2,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 3,
////    collaborationTypeID: 1,//2
////    isAvailable: 1,
////    cardSizeID: 1,
////  },
////  {
////    cardWidgetID: 13,
////    widgetName: "Message Trends",
////    cardViewTypeID: 7,
////    Visualization: "barchart",
////    cardFilterID: 3,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 0,
////    categoryTypeID: 3,
////    collaborationTypeID: 3,
////    isAvailable: 1,
////    cardSizeID: 1,
////  },
////  {
////    cardWidgetID: 15,
////    widgetName: "Collaboration Summary",
////    cardViewTypeID: 2,
////    Visualization: "table",
////    cardFilterID: 1,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 2,
////    collaborationTypeID: 4,
////    isAvailable: 1,
////    cardSizeID: 2,
////  },
////  //{
////  //  cardWidgetID: 31,
////  //  widgetName: "Chat Comparison by People",
////  //  cardViewTypeID: 18,
////  //  Visualization: "barchart",
////  //  cardFilterID: 3,
////  //  isProductivity: 1,
////  //  isCustomerExperience: 0,
////  //  isWellness: 1,
////  //  isTeamsCalling: 0,
////  //  categoryTypeID: 4,
////  //  collaborationTypeID: 3,
////  //  isAvailable: 1,
////  //  cardSizeID: 2,
////  //},
////  {
////    cardWidgetID: 2,
////    widgetName: "Team Overview",
////    cardViewTypeID: 10,
////    Visualization: "summary",
////    cardFilterID: 12,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 4,
////    isAvailable: 1,
////    cardSizeID: 1,
////  },
////  {
////    cardWidgetID: 3,
////    widgetName: "Meeting Overview",
////    cardViewTypeID: 3,
////    Visualization: "summary",
////    cardFilterID: 2,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 2,//1
////    isAvailable: 1,
////    cardSizeID: 1,
////  },
////  {
////    cardWidgetID: 4,
////    widgetName: "Meeting Habits",
////    cardViewTypeID: 4,
////    Visualization: "list",
////    cardFilterID: 2,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 2,//1
////    isAvailable: 1,
////    cardSizeID: 0,
////  },
////  {
////    cardWidgetID: 19,
////    widgetName: "People Comparison",
////    cardViewTypeID: 15,
////    Visualization: "generalanalytics",
////    cardFilterID: 27,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 4,
////    collaborationTypeID: 4,
////    isAvailable: 0,
////    cardSizeID: 3,
////  },
////  {
////    cardWidgetID: 16,
////    widgetName: "Meeting Attendance %",//"Meeting Participation",
////    cardViewTypeID: 11,
////    Visualization: "piechart",
////    cardFilterID: 2,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 2,//1
////    isAvailable: 1,
////    cardSizeID: 0,
////  },
////  {
////    cardWidgetID: 34,
////    widgetName: "Message Comparison",
////    cardViewTypeID: 21,
////    Visualization: "table",
////    cardFilterID: 3,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 4,
////    collaborationTypeID: 3,
////    isAvailable: 1,
////    cardSizeID: 13,
////  },
////  {
////    cardWidgetID: 35,
////    widgetName: "Team Activity",
////    cardViewTypeID: 23,
////    Visualization: "table",
////    cardFilterID: 12,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 2,
////    collaborationTypeID: 4,
////    isAvailable: 1,
////    cardSizeID: 2,
////  },
////  {
////    cardWidgetID: 37,
////    widgetName: "Call Summary by Group",
////    cardViewTypeID: 21,
////    Visualization: "table",
////    cardFilterID: 19,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 1,
////    isAvailable: 1,
////    cardSizeID: 10,
////  },
////  {
////    cardWidgetID: 40,
////    widgetName: "Call Summary by People",
////    cardViewTypeID: 21,
////    Visualization: "table",
////    cardFilterID: 14,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 1,//2
////    isAvailable: 1,
////    cardSizeID: 10,
////  },
////  {
////    cardWidgetID: 55,
////    widgetName: "Team Engagement",
////    cardViewTypeID: 22,
////    Visualization: "table",
////    cardFilterID: 12,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 0,
////    categoryTypeID: 2,
////    collaborationTypeID: 4,//2
////    isAvailable: 1,
////    cardSizeID: 3,
////  },
////  {
////    cardWidgetID: 0,
////    widgetName: "People Summary",
////    cardViewTypeID: 13,
////    Visualization: "summary",
////    cardFilterID: 21,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 4,
////    isAvailable: 1,
////    cardSizeID: 3,
////  },
////  {
////    cardWidgetID: 26,
////    widgetName: "Team Comparison",
////    cardViewTypeID: 16,
////    Visualization: "generalanalytics",
////    cardFilterID: 5,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 4,
////    collaborationTypeID: 4,
////    isAvailable: 1,
////    cardSizeID: 3,
////  },
////  {
////    cardWidgetID: 29,
////    widgetName: "Collaboration Network",
////    cardViewTypeID: 28,
////    Visualization: "summary",
////    cardFilterID: 21,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 4,
////    isAvailable: 1,
////    cardSizeID: 3,
////  },
////  {
////    cardWidgetID: 41,
////    widgetName: "Network Quality by People",//"Call Quality by People",
////    cardViewTypeID: 18,
////    Visualization: "barchart",
////    cardFilterID: 14,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 4,
////    collaborationTypeID: 1,//2
////    isAvailable: 1,
////    cardSizeID: 2,
////  },
////  {
////    cardWidgetID: 42,
////      widgetName: "Message Sentiment by People",
////    cardViewTypeID: 18,
////    Visualization: "barchart",
////    cardFilterID: 3,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 4,
////    collaborationTypeID: 3,
////    isAvailable: 1,
////    cardSizeID: 2,
////  },
////  {
////    cardWidgetID: 43,
////    widgetName: "Platform by People",
////    cardViewTypeID: 18,
////    Visualization: "barchart",
////    cardFilterID: 22,
////    isProductivity: 1,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 2,
////    collaborationTypeID: 1,//2
////    isAvailable: 1,
////    cardSizeID: 2,
////  },
////  //{
////  //  cardWidgetID: 44,
////  //  widgetName: "List Calls by User",
////  //  cardViewTypeID: 0,
////  //  Visualization: "table",
////  //  cardFilterID: 22,
////  //  isProductivity: 1,
////  //  isCustomerExperience: 1,
////  //  isWellness: 1,
////  //  isTeamsCalling: 0,
////  //  categoryTypeID: 2,
////  //  collaborationTypeID: 1,//2
////  //  isAvailable: 1,
////  //  cardSizeID: 2,
////  //},
////  {
////    cardWidgetID: 45,
////    widgetName: "Call Queue Overview",
////    cardViewTypeID: 0,
////    Visualization: "table",
////    cardFilterID: 25,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 1,
////    categoryTypeID: 6,
////    collaborationTypeID: 6,
////    isAvailable: 1,
////    cardSizeID: 4,
////  },
////  {
////    cardWidgetID: 46,
////    widgetName: "Call Queue Summary",
////    cardViewTypeID: 20,
////    Visualization: "summary",
////    cardFilterID: 24,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 1,
////    categoryTypeID: 6,
////    collaborationTypeID: 6,
////    isAvailable: 1,
////    cardSizeID: 3,
////  },
////  {
////    cardWidgetID: 47,
////    widgetName: "List Calls by Call Queue",
////    cardViewTypeID: 0,
////    Visualization: "table",
////    cardFilterID: 23,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 1,
////    categoryTypeID: 6,
////    collaborationTypeID: 6,
////    isAvailable: 1,
////    cardSizeID: 4,
////  },
////  {
////    cardWidgetID: 48,
////    widgetName: "List Calls by Agent",
////    cardViewTypeID: 0,
////    Visualization: "table",
////    cardFilterID: 23,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 1,
////    categoryTypeID: 6,
////    collaborationTypeID: 6,
////    isAvailable: 1,
////    cardSizeID: 4,
////  },
////  {
////    cardWidgetID: 49,
////    widgetName: "List Missed Calls",
////    cardViewTypeID: 0,
////    Visualization: "table",
////    cardFilterID: 23,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 1,
////    categoryTypeID: 6,
////    collaborationTypeID: 6,
////    isAvailable: 1,
////    cardSizeID: 1,
////  },
////  {
////    cardWidgetID: 50,
////    widgetName: "List Unreturned Missed Calls",
////    cardViewTypeID: 0,
////    Visualization: "table",
////    cardFilterID: 23,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 1,
////    categoryTypeID: 6,
////    collaborationTypeID: 6,
////    isAvailable: 1,
////    cardSizeID: 1,
////  },
////  {
////    cardWidgetID: 51,
////    widgetName: "Call Queue Summary by Agent",
////    cardViewTypeID: 0,
////    Visualization: "table",
////    cardFilterID: 23,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 1,
////    categoryTypeID: 6,
////    collaborationTypeID: 6,
////    isAvailable: 1,
////    cardSizeID: 1,
////  },
////  {
////    cardWidgetID: 52,
////    widgetName: "Call Volume Trends",
////    cardViewTypeID: 18,
////    Visualization: "barchart",
////    cardFilterID: 23,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 1,
////    categoryTypeID: 6,
////    collaborationTypeID: 6,
////    isAvailable: 1,
////    cardSizeID: 1,
////  },
////  {
////    cardWidgetID: 53,
////    widgetName: "Ring Time Trends",
////    cardViewTypeID: 18,
////    Visualization: "barchart",
////    cardFilterID: 25,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 1,
////    categoryTypeID: 6,
////    collaborationTypeID: 6,
////    isAvailable: 1,
////    cardSizeID: 1,
////  },
////  {
////    cardWidgetID: 54,
////    widgetName: "Agent Activity By Queue",
////    cardViewTypeID: 0,
////    Visualization: "table",
////    cardFilterID: 26,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 0,
////    isTeamsCalling: 1,
////    categoryTypeID: 6,
////    collaborationTypeID: 6,
////    isAvailable: 1,
////    cardSizeID: 1,
////  },
////  {
////    cardWidgetID: 56,
////    widgetName: "Team Governance",
////    cardViewTypeID: 29,
////    Visualization: "table",
////    cardFilterID: 10,
////    isProductivity: 0,
////    isCustomerExperience: 0,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 5,
////    isAvailable: 1,
////    cardSizeID: 2,
////  },
////  {
////    cardWidgetID: 58,
////    widgetName: "Management Style",
////    cardViewTypeID: 25,
////    Visualization: "barchart",
////    cardFilterID: 21,
////    isProductivity: 1,
////    isCustomerExperience: 1,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 4,
////    isAvailable: 1,
////    cardSizeID: 1,
////  },
////  {
////    cardWidgetID: 59,
////    widgetName: "One-to-One Trends",
////    cardViewTypeID: 26,
////    Visualization: "barchart",
////    cardFilterID: 14,
////    isProductivity: 1,
////    isCustomerExperience: 1,
////    isWellness: 0,
////    isTeamsCalling: 0,
////    categoryTypeID: 3,
////    collaborationTypeID: 4,
////    isAvailable: 1,
////    cardSizeID: 1,

////  },
////  {
////    cardWidgetID: 57,
////    widgetName: "One-to-One Overview",
////    cardViewTypeID: 24,
////    Visualization: "generalanalytics",
////    cardFilterID: 21,
////    isProductivity: 1,
////    isCustomerExperience: 1,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 4,
////    collaborationTypeID: 4,
////    isAvailable: 0,
////    cardSizeID: 11,
////  },
////  {
////    cardWidgetID: 60,
////    widgetName: "Direct Report Overview",
////    cardViewTypeID: 27,
////    Visualization: "summary",
////    cardFilterID: 21,
////    isProductivity: 1,
////    isCustomerExperience: 1,
////    isWellness: 1,
////    isTeamsCalling: 0,
////    categoryTypeID: 1,
////    collaborationTypeID: 4,
////    isAvailable: 1,
////    cardSizeID: 3,
////  }
////];
//export const _cardCategoryType = {
//  1: "Summary",
//  2: "Activity",
//  3: "Trends",
//  4: "Comparisons",
//  5: "Insights",
//  6: "Teams Calling"
//};
export const _cardCollaborationType = {
  //1: "Meetings",
  //2: "Calls",
  1: "Calls",
  2: "Meetings",
  3: "Messages",
  4: "Collaboration",
  5: "Teams and Channels",
  //6: "Teams Calling"
};
export const cardSize = [
  {
    cardSizeID : 0, 
    w : 1, 
    h : 3
  },
  {
    cardSizeID : 1, 
    w : 1, 
    h : 6
  },
  {
    cardSizeID : 2, 
    w : 1, 
    h : 9
  },
  { 
    cardSizeID : 3, 
    w : 2, 
    h : 6
  },
  {
    cardSizeID : 4, 
    w : 1, 
    h : 12
  },
  {
    cardSizeID : 5, 
    w : 4, 
    h : 2
  },
  {
    cardSizeID : 6, 
    w : 6, 
    h : 2
  },
  {
    cardSizeID : 7, 
    w : 8, 
    h : 2
  },
  {
    cardSizeID : 8, 
    w : 2, 
    h : 8
  },
  {
    cardSizeID : 10, 
    w : 2, 
    h : 12
  },
  {
    cardSizeID : 11, 
    w : 2, 
    h : 9
  },
  {
    cardSizeID : 12, 
    w : 3, 
    h : 6
  },
  {
    cardSizeID : 13, 
    w : 2, 
    h : 8
  },
  {
    cardSizeID : 14, 
    w : 2, 
    h : 3
  },
]

