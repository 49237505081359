import { useState, useEffect } from "react";
import "./dashboard.scss";
import AuthenticatedRoute from "../../RouteHelper";

import { Route, Switch } from "react-router-dom";

import DashGrid from "./dashboard";
import AddCard from "./addcard";
import DashHome from "./dashboards";
import TeamInsights from "../TeamInsight";

const Dashboards = (props) => {
  useEffect(() => {}, []);

  return (
    <Switch>
      <AuthenticatedRoute exact path="/dashboards" component={DashHome} />
      <AuthenticatedRoute
        path="/dashboards/teaminsights"
              component={TeamInsights} viewStatus={props.viewStatus}
      />
      <AuthenticatedRoute
        path="/dashboards/:operation/:dashboardId"
        component={DashGrid}
      />
    </Switch>
  );
};

export default Dashboards;
