import React from 'react';
import './insights.scss';
import { Skeleton } from '@fluentui/react-northstar';
function InsightsCardSkeleton({ key, teamView, numOfCards, forDialog = false, }) {
    return (

        <>
            <div className="insights-card-container">
                <div className={`cursor-default ${forDialog ? 'm-3' : 'detail-view-width insights-card'}`} key={key}>
                    <div className="container">
                    <Skeleton animation='pulse' key={key}>
                        <div className='row insights-card-header align-items-center'>
                            <div className=''>
                                <Skeleton.Avatar size='large' className='shadow rounded-circle' />
                            </div>
                            <div className='col px-2 labels'>
                                <Skeleton.Line width="40%" />
                                <Skeleton.Line width="20%" />
                                <Skeleton.Line width="60%" />
                            </div>
                            <Skeleton.Shape width="30px" height='30px' />
                        </div>
                    </Skeleton>
                    <div className="insights-card-body">
                        <div className='row border-b-uinsights pb-3'>
                            <div className="col justify-content-center px-2">
                                <Skeleton.Line width="80%" />
                                <Skeleton.Line width="80%" />
                                <Skeleton.Line width="80%" />
                            </div>
                        </div>
                        <div className='row align-items-center justify-content-center my-2 pb-2 border-b-uinsights'>
                            <Skeleton.Line width="90%" />
                        </div>
                        <div className='row align-items-center justify-content-center my-2'>
                            <Skeleton.Line width="90%" />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default InsightsCardSkeleton