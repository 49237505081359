import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartLegend
} from '@progress/kendo-react-charts';
import './FreemiumCardView.scss';



export default function FreemiumCardView() {

    // (A) FETCH "HTML"
    fetch(`${process.env.REACT_APP_INSIGHTS_LINK}`)

        // (B) RETURN THE RESULT AS TEXT
        .then((result) => {
            if (result.status !== 200) { throw new Error("Bad Server Response"); }
            return result.text();
        })

        // (C) PUT LOADED CONTENT INTO <DIV>
        .then((content) => {
            //debugger
            content = content.replaceAll(`src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"`, '');
            content = content.replaceAll('data-lazy-src', 'src');
            document.getElementById("demoShow").innerHTML = content;
            document.getElementById("footer").remove()
            document.getElementById("main").style.height = "100vh";
            document.getElementById("main").style.padding = 0;
        })

        // (D) HANDLE ERRORS - OPTIONAL
        .catch((error) => { console.log(error); });


    return (

        <div className="dyk-wrap">
            {/* <iframe src="https://www.analytics-365.com/insight-dashboard/">

            </iframe> */}
            <div id="demoShow"></div>
        </div>
    );
}
