import { useState, useEffect } from "react";
import { Alert, CheckmarkCircleIcon, ExclamationTriangleIcon, Flex } from "@fluentui/react-northstar";
export const INCIDENTLEVELS = {
    "healthy": 0,
    "warning": 1,
    "critical": 2
}
export const GETINCIDENTLEVEL = (incidentLevelId) => {
    return INCIDENTLEVELS[incidentLevelId];
}

export const FORMATBYTES = (a, b = 2) => { if (!+a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]}` }

export const OPERATIONSTATUS = Object.freeze({
    Success: 'success',
    Failed: 'failed',
    Unauthorized: 'unauthorized',
    None: 'none',
});
export const OPERATIONSTATUSMESSAGE = {
    "success": "Operation executed successfully!",
    "failed": "Operation failed, Please try again!",
    "unauthorized": "Unauthorized, Operation failed. Please contact your admin!",
    "none": ""
}
export const OperationStatusIndicator = ({ status, revertStatusToNull }) => {
    const [alertVisible, setAlertVisible] = useState(false);

    useEffect(() => {
        if (status && !(status == "" || status == OPERATIONSTATUS.None)) {
            setAlertVisible(true);
            setTimeout(() => {
                setAlertVisible(false);
                revertStatusToNull();
            }, 3000);
        }
    }, [status])
    return (
        <Alert
            styles={{
                zIndex: '100000',
                position: "absolute",
                left: "50%",
                transform: "translate(-50%,-50%)",
                top: "10%",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            }}
            fitted
            icon={status == OPERATIONSTATUS.Success ? <CheckmarkCircleIcon /> : <ExclamationTriangleIcon />}
            variables={{
                urgent: status == OPERATIONSTATUS.Success ? false : true,
            }}
            success={status == OPERATIONSTATUS.Success}
            content={OPERATIONSTATUSMESSAGE[status]}
            visible={alertVisible}
        />
    )
}