import AuthHelper from '../../services/auth-helper';
import { Card,CardHeader, CardBody, Flex, Segment, Text } from '@fluentui/react-northstar'
import './auth.scss';

const SignInEnd = () => {
    AuthHelper.EndSignIn();
    return (
        <Flex fill className="auth-form-container default-segment-container" hAlign="center" vAlign="center">
        <Segment className="auth-form-segment default-segment">
            <Flex gap="gap.small" column hAlign="center" vAlign="center">
                <Text content="You are being redirected to the home page"/>
            </Flex>
        </Segment>
        </Flex>
    )
}

export default SignInEnd;