import React, { useEffect, useState } from 'react'
import InsightsCard from '../insights/insightsCard'
import '../insights/insights.scss';
import { Avatar, Text, Loader, Flex, FlexItem, Box, Skeleton, Button, Tooltip } from '@fluentui/react-northstar';
import { Dialog } from '@progress/kendo-react-dialogs';
import InsightsCardSkeleton from '../insights/insightsCardSkeleton';
import WindowContext from '../../Shared/Context/Context';



function fnGetZoneIndicator(percentageValue, zones, label) {
    if ((percentageValue == 0 && (label == "Meetings" || label == "Messages")))
        return "disabled";

    if (0 < percentageValue < zones[0]) {
        return 'Negative';
    }
    else if (zones[0] < percentageValue < zones[1]) {
        return 'Neutral';
    }
    else if (zones[1] < percentageValue < zones[2]) {
        return 'Positive';
    }
    else if (zones[2] < percentageValue < zones[3]) {
        return 'Neutral';
    }
    else if (zones[3] < percentageValue < 100) {
        return 'Negative';
    }
    else {
        return ''
    }
}
const ListViewIndicator = ({ percentageValue, zones, label = 'Activity', desc }) => {
    const type = fnGetZoneIndicator(percentageValue, zones, label);

    return (
        <div className='col-auto px-0 mr-2 d-flex align-items-center'>
            {type && <div className={`list-view-indicator ${type}`} title={`${type} zone`}></div>}
            <Tooltip content={desc}
                pointing
                trigger={
                    <div className='list-view-label px-2 cursor-default' title={label === 'Messages' ? 'Chats & Messages' : label}>{label}</div>
                }
            />
        </div>
    )
}

const TeamInsightVibeComponent = ({ managerVibe, loading = false, managerData }) => {
    return (<>
        {(loading) ?
            <div className='row align-items-center ml-auto'>

                <div className="insights-card cursor-default mx-3 shadow-bg-filter-header">
                    <div className="row">
                        <div className="col align-items-center justify-content-center insights-card-header">
                            <Skeleton.Shape width='60px' height='60px' />
                        </div>
                    </div>
                </div>
            </div> : managerData ?
                <div className='row align-items-center ml-auto'>
                    <div className="insights-card p-tv-header cursor-default mx-3 shadow-bg-filter-header">
                        <div className="row">
                            <div className="col align-items-center justify-content-center insights-card-header">
                                <div className='name-label'>Team Vibe</div>
                                <div className='vibe-indicator align-self-end mt-4'>
                                    <div className='vibe-indicator-emoji' >
                                        {managerVibe === 'positive' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Halo.png" alt="Neutral Face" width="38" height="38" />
                                            : (managerVibe === 'negative' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Worried%20Face.png" alt="Worried Face" width="38" height="38" />
                                                : <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Neutral%20Face.png" alt="Neutral Face" width="38" height="38" />
                                            )}
                                    </div>
                                    <div className='vibe-indicator-text bg-primary text-right'>
                                        {managerVibe === 'positive' ? 'Positive' : (managerVibe === 'negative' ? 'Negative' : 'Neutral')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : <></>
        }
    </>
    )
}

const TeamViewHeader = ({ loading = true, managerData, ringCounts = 7, fnUpdateUserToView }) => {
    return (
        <WindowContext>
            {
                (context) => (
                    <>
                        {loading ?
                            <div className='row align-items-center'>
                                <div className='position-relative cursor-default'>
                                    <div className='position-relative cursor-default'>
                                        <Skeleton animation='pulse'><Skeleton.Avatar size='largest' className='shadow rounded-circle' /></Skeleton>
                                    </div>
                                </div >
                                <div className="insights-card cursor-default mx-3 shadow-bg-filter-header cursor-pointer" style={{ width: "392.6px" }}>
                                    <div className="container">
                                        <Skeleton animation='pulse'>
                                            <div className="row align-items-center insights-card-header">
                                                <div className='col px-0 labels border-r-uinsights mr-3'>
                                                    <Skeleton.Line width="75%" />
                                                    <Skeleton.Line width="40%" />
                                                    <div className="insights-card-body">
                                                        <div className="container">
                                                            <div className='row gap-2 justify-content-center'>
                                                                <Skeleton.Line width="80%" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Skeleton.Shape width='60px' height='60px' />
                                            </div>
                                        </Skeleton>
                                    </div>
                                </div>
                            </div>
                            : managerData != null ?
                                <div className="col">
                                    <div className='row align-items-center'>
                                        <div className='position-relative cursor-pointer'>

                                            {!context.mediaCategory.sm && <Avatar
                                                onClick={() => fnUpdateUserToView(managerData.userGUID, false)}
                                                className='shadow rounded-circle'
                                                size='largest'
                                                name={managerData.userName}
                                                status={managerData.status}
                                                image={managerData.userImage}
                                                styles={{ zIndex: "1" }}
                                            />}
                                            {
                                                Array.from({ length: ringCounts }).map((_, index) => (
                                                    <div key={index} className={`click-disabled user-rings ${ringCounts <= 4 ? 'rings-danger' : ringCounts >= 7 ? 'rings-success' : 'rings-warning'}`} style={{ height: `${(index * 16) * (index * 2) + 'px'}`, width: `${(index * 16) * (index * 2) + 'px'}` }}></div>
                                                ))
                                            }
                                        </div>
                                        <div className={`shadow-bg-filter-header cursor-pointer ${context.mediaCategory.sm ? "w-100 px-3 pb-3 pt-2" : "insights-card p-tv-header mx-3"}`} onClick={() => fnUpdateUserToView(managerData.userGUID, false)}>
                                            <div className="container">
                                                <div className="row align-items-center insights-card-header">
                                                    <div className='col px-0 labels'>
                                                        <div class="row justify-content-between">
                                                            <div class="col">
                                                                <div className='name-label'>{managerData.userName}</div>
                                                                <div className='designation-label'>{managerData.userDesignation}</div>
                                                            </div>
                                                            {context.mediaCategory.sm && <div class="col-auto">
                                                                <div className=''>Team Vibe</div>
                                                                <div className='bg-light rounded px-1 border text-center shadow-sm'>Neutral</div>
                                                            </div>}
                                                        </div>
                                                        <div className="insights-card-body">
                                                            <div className="container">
                                                                <div className='row gap-2 justify-content-center'>
                                                                    <ListViewIndicator percentageValue={managerData.meetingsPercentage} zones={managerData.meetingsZone} label='Meetings' desc='Represents the percentage of time spent in meetings. This section helps gauge whether your time spent in meetings is balanced or excessive, ensuring that meetings are productive without compromising focus or other tasks.' />
                                                                    <ListViewIndicator percentageValue={managerData.callsPercentage} zones={managerData.callsZone} label='Messages' desc='This section consolidates the time spent on calls and messages, emphasizing your communication load. It ensures that communication is maintained without overwhelming your ability to focus on essential tasks.' />
                                                                    <ListViewIndicator percentageValue={100 - (managerData.meetingsPercentage + managerData.callsPercentage)} zones={managerData.focusedZone} label='Focused' desc='Represents the remaining time within your workday that is dedicated to focused, undisturbed work. Ensuring sufficient focus time is critical to productivity, allowing you to concentrate on core tasks.' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : <></>
                        }
                    </>
                )
            }
        </WindowContext>

    )
}


const TeamViewInsights = (props) => {
    const [loading, setLoading] = useState(props.loading);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading])

    return (
        <WindowContext>{
            (context) => (
                <div className='mx-2 overflow-hidden' style={{ height: "calc(100% - 36px)" }}>
                    <>
                        <div className="container container-cst align-items-center pr-md-0 my-md-3">
                            <div className='row justify-content-between'>
                                <TeamViewHeader loading={loading} managerData={props.insightData?.managerData} fnUpdateUserToView={props.fnUpdateUserToView} />
                                {!context.mediaCategory.sm ?
                                    <TeamInsightVibeComponent loading={loading} managerData={props.insightData?.managerData} managerVibe={""} />
                                    : <></>}
                            </div>
                        </div>


                        <div className={`px-3 mx-auto container-cst px-0 insights-body h-insights`}>
                            {loading ?
                                <div className="row gap-24 mx-auto my-3">
                                    {(
                                        Array.from({ length: 6 }).map((_, index) => (
                                            <InsightsCardSkeleton key={index} teamView={true} />
                                        ))
                                    )}
                                </div> :
                                < div className={`row gap-24 mx-auto my-3 ${context.mediaCategory.sm ? "justify-content-center" : " "}`}>
                                    {
                                        props.insightData?.userData != null ? props.insightData?.userData.filter((d) => d.userGUID !== props.insightData?.managerData.userGUID)
                                            .map((d, index) =>
                                                <InsightsCard
                                                    key={index}
                                                    teamView={true}
                                                    {...d}
                                                    fnUpdateUserToView={props.fnUpdateUserToView}
                                                    wellBeingScoreDescriptionList={props.wellBeingScoreDescriptionList}
                                                />
                                            )
                                            : <></>
                                    }
                                </div>
                            }

                        </div>

                    </>
                </div >
            )
        }
        </WindowContext>
    )
}

export default TeamViewInsights