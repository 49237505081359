import { Tooltip } from '@fluentui/react-northstar';
import React from 'react';
import './circleChart.scss';

const CircleChart = (props) => {
    return (
        <Tooltip content={props.id === "xx" ? props.value + "% Overall meeting presence" : (props.id === "xx1"? "Total: " + props.tooltipData :(props.tooltipData !== undefined ? props.tooltipData + " Meeting/Calls" : props.value + "%"))} pointing trigger={<div className="donut-chart">
            <div id={props.id} className={`c100 ${props.size} p${props.value >= 100 ? '100' : props.value}`}>
                <span>
                    <span>{props.value}</span>%
                </span>
                <div className="slice">
                    <div className="bar"></div>
                    <div className="fill"></div>
                </div>
            </div>
        </div>} />

    );
}

export default CircleChart;