import React, { useState, useEffect, Fragment } from "react";
import {
    Grid,
    GridColumn as Column,
    GridNoRecords,
} from "@progress/kendo-react-grid";
import {
    AcceptIcon,
    ExclamationTriangleIcon,
} from "@fluentui/react-icons-northstar";
import {
    Form,
    Segment,
    Loader,
    Flex,
    Dialog as FluentDialog,
    Button,
    Text,
    Input,
    Divider,
    FormButton,
    FormDropdown,
    FormInput
} from "@fluentui/react-northstar";
import WindowContext from "../../Shared/Context/Context";
import TeamsTimePicker from "../../Shared/uicomponents/TeamsTimePicker";
import NativeTimePicker from "../../Shared/uicomponents/NativeTimePicker";
import TeamsDatePicker from "../../Shared/uicomponents/TeamsDatePicker";
import NativeDatePicker from "../../Shared/uicomponents/NativeDatePicker";
import AuthHelper from "../../../services//auth-helper";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import DashboardApiService from "../../../services/DashboardApiService";


const ScheduleTypes = [
    { id: 1, header: 'Daily', key: 0 },
    { id: 2, header: 'Weekly', key: 1 },
    { id: 3, header: 'Monthly', key: 2 },
    { id: 4, header: 'Yearly', key: 3 }
]

const DashboardScheduler = (props) => {
    const [loading, setLoading] = useState(false);
    const [scheduleName, setScheduleName] = useState("");
    const [subject, setSubject] = useState("");
    const [emailTo, setEmailTo] = useState("");
    const [emailCc, setEmailCc] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [time, setTime] = useState("");
    const [scheduleType, setScheduleType] = useState(ScheduleTypes[1]);
    const [selectedCards, setSelectedCards] = useState([]);
    const [selecteddashboards, setSelectedDashboards] = useState([]);
    const [cards, setCards] = useState([]);
    const [dashboards, setDashboards] = useState([]);
    const [submitButtonState, setSubmitButtonState] = useState(0);
    const [validEmail, setValidEmail] = useState(true);
    const [validEmailCC, setValidEmailCC] = useState(true);
    const [formFilled, setFormFilled] = useState(false);
    const [isDemo, setIsDemo] = useState(false);
    const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));
    const [dashboardCardsData, setDashboardCardsData] = useState([]);

    useEffect(() => {
        if (isDemoMode === "true") {
            setIsDemo(true);
        } else {
            setIsDemo(false);
        }
    }, []);

    useEffect(() => {
        var cardData = props.cardList?.map(ele => {
            var card = {
                id: ele.cardID,
                header: ele.name,
                key: ele.cardID
            }
            return card
        })
        setSelectedCards(cardData);
        setCards(cardData);
        setSubmitButtonState(0);
    }, [props.cardList]);

    useEffect(() => {
        AuthHelper.getAccessToken(function (token) {
            ConfigurationApiService.GetDashboardList(token).then((response) => {
                if (response) {
                    setDashboardCardsData(response);

                    var dashboardData = response?.filter(ele => ele.cards && ele.cards.length > 0).map(ele => {
                        var dashboard = {
                            id: ele.id,
                            header: ele.dashboardName,
                            key: ele.id
                        }
                        return dashboard;
                    });
                    setDashboards(dashboardData);
                    console.log(dashboardData);
                    setSubmitButtonState(0);
                } else {

                }
            });
        });
    }, []);

    const checkValid = () => {
        if (scheduleName.trim() !== "" && subject.trim() !== "" && emailTo.trim() !== "" &&
            startDate !== null && time.trim() !== "" &&
            scheduleType !== null && scheduleType.id !== null &&
            (props.isQuickAdd ? (selecteddashboards !== null && selecteddashboards.length !== 0)
                : (selectedCards !== null && selectedCards.length !== 0))
        ) {
            return true;
        } else {
            return false;
        }
    }


    const handleSaveSchedule = () => {
        setSubmitButtonState(1);
        setFormFilled(true);


        if (!checkValid() || !validEmail) {
            {
                setTimeout(function () {
                    setSubmitButtonState(0);
                }, 1000);
                setSubmitButtonState(3);
                return;
            }
        }
        //setLoading(true);

        var data = {
            name: scheduleName,
            emailTo: emailTo.split(";").map(ele => ele.trim()).filter(ele => ele != ""),
            emailCC: emailCc.split(";").map(ele => ele.trim()).filter(ele => ele != ""),
            subject: subject,
            startDate: startDate,
            time: time,
            scheduleTypeID: scheduleType.id,
            dashboardID: props.isQuickAdd ? selecteddashboards.map(ele => ele.id) : [props.dashboardId],
            cardIDs: props.isQuickAdd ? [] : selectedCards.map(ele => ele.id),
        }

        AuthHelper.getAccessToken(function (token) {
            ConfigurationApiService.CreateSchedule(data, token).then((response) => {
                if (response) {
                    setSubmitButtonState(2);
                    //props.cancelHandler()
                }
                else {
                    setSubmitButtonState(3);
                    setTimeout(function () {
                        setSubmitButtonState(0);
                    }, 1000);
                }
                setLoading(false);
            })
            // .finally(() => {
            //     if (submitButtonState !== 2) {
            //    }
            // });
        });
    };
    function emailValidation(value) {
        var flag = true;
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        //if (!value || regex.test(value) === false) {
        //    return false;
        //}
        value.split(",").map(ele => {
            if (!ele.trim() || regex.test(ele.trim()) === false) {
                flag = false;
            }
        }
        )
        return flag;
    }
    function emailValidationCC(value) {
        var flag = true;
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        //if (!value || regex.test(value) === false) {
        //    return false;
        //}
        value.split(",").map(ele => {
            if (regex.test(ele.trim()) === false) {
                flag = false;
            }
        }
        )
        if (value.trim().length === 0)
            flag = true;
        return flag;
    }
    function onChangeEmail(value) {
        if (emailValidation(value))
            setValidEmail(true);
        else
            setValidEmail(false);
        setEmailTo(value);
    }
    function onChangeEmailCC(value) {
        if (emailValidationCC(value))
            setValidEmailCC(true);
        else
            setValidEmailCC(false);
        setEmailCc(value);
    }

    return (
        <WindowContext.Consumer>
            {(context) => (
                loading ? <Loader /> :
                    <Fragment>
                        <Form
                            className="formCardSettings"
                            onSubmit={handleSaveSchedule}

                        >
                            <FormInput
                                label="Schedule Name"
                                name="scheduleName"
                                required
                                inline
                                fluid
                                defaultValue={scheduleName}
                                value={scheduleName}
                                maxLength="50"
                                className="mb-3"
                                disabled={submitButtonState === 2}
                                onChange={(e, { value }) => { setScheduleName(value); }}
                                onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                            />
                            <FormInput
                                label="Email Subject"
                                name="emailSubject"
                                required
                                inline
                                fluid
                                defaultValue={subject}
                                value={subject}
                                maxLength="100"
                                className="mb-3"
                                disabled={submitButtonState === 2}
                                onChange={(e, { value }) => { setSubject(value); }}
                                onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                            />
                            <div className="dropdownSection mb-3">
                                <FormInput
                                    label="Email To"
                                    name="emailTo"
                                    required
                                    inline
                                    fluid
                                    defaultValue={emailTo}
                                    value={emailTo}
                                    disabled={submitButtonState === 2}
                                    errorMessage={!validEmail ? 'Please enter a valid value' : undefined}
                                    onChange={(e, { value }) => { onChangeEmail(value); }}
                                    onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                />
                                <FormInput
                                    label="Email cc"
                                    name="emailCc"
                                    inline
                                    fluid
                                    defaultValue={emailCc}
                                    value={emailCc}
                                    disabled={submitButtonState === 2}
                                    onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                    errorMessage={!validEmailCC ? 'Please enter a valid value' : undefined}
                                    onChange={(e, { value }) => { onChangeEmailCC(value); }}
                                />
                            </div>
                            <div className="dropdownSection mb-3">
                                <div>
                                    <label className="mb-0">Start Date</label>
                                    {context.teams.hostClientType.web ||
                                        context.teams.hostClientType.desktop ? (
                                        <TeamsDatePicker
                                            required={true}
                                            minDate={new Date()}
                                            value={startDate}
                                            disabled={submitButtonState === 2}
                                            onChange={(value) => { setStartDate(value); }}
                                        />
                                    ) : (
                                        <NativeDatePicker
                                            id="startDate"
                                            required={true}
                                            minDate={new Date()}
                                            value={startDate}
                                            disabled={submitButtonState === 2}
                                            onChange={(value) => { setStartDate(value); }}
                                        />
                                    )}
                                </div>
                                <div>
                                    <label>Start Time</label>
                                    {
                                        context.teams.hostClientType.web ||
                                            context.teams.hostClientType.desktop ? (
                                            <TeamsTimePicker
                                                required={true}
                                                value={time}
                                                disabled={submitButtonState === 2 ? true : false}
                                                onChange={(value) => { setTime(value); }}
                                            />
                                        ) : (
                                            <NativeTimePicker
                                                id="startTime"
                                                value={time}
                                                disabled={submitButtonState === 2 ? true : false}
                                                onChange={(value) => { setTime(value); }}
                                                required={true}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <div className="dropdownSection mb-5">
                                <FormDropdown
                                    label="Run Interval"
                                    fluid
                                    
                                    placeholder="Select run interval"
                                    items={ScheduleTypes}
                                    value={scheduleType}
                                    disabled={submitButtonState === 2}
                                    itemToValue={(obj) => { return obj.key; }}
                                    onChange={(e, { value }) => { setScheduleType(value); }}
                                    onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                    noResultsMessage="We couldn't find any matches."
                                />
                                {props.isQuickAdd ?
                                    <FormDropdown
                                        fluid
                                        
                                        label="Dashboards*"
                                        search
                                        multiple
                                        required
                                        renderSelectedItem={(Component, props) => {
                                            if (submitButtonState === 2) {
                                                props.className = props.className + " _disabledItem";
                                                props.icon = null;
                                            }
                                            return <Component {...props}></Component>;
                                        }}
                                        placeholder="Select dashboards"
                                        disabled={submitButtonState === 2 ? true : false}
                                        items={dashboards}
                                        value={selecteddashboards}
                                        itemToValue={(obj) => { return obj.key; }}
                                        errorMessage={selecteddashboards.length === 0 && formFilled ? 'Please select a value' : undefined}
                                        onChange={(e, { value }) => { setSelectedDashboards(value); }}
                                        onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                        noResultsMessage="We couldn't find any matches."
                                    />
                                    :
                                    <FormDropdown
                                        fluid
                                        
                                        label="Cards*"
                                        search
                                        multiple
                                        required
                                        placeholder="Select cards"
                                        renderSelectedItem={(Component, props) => {
                                            if (submitButtonState === 2) {
                                                props.className = props.className + " _disabledItem";
                                                props.icon = null;
                                            }
                                            return <Component {...props}></Component>;
                                        }}
                                        disabled={submitButtonState === 2 ? true : false}
                                        items={cards}
                                        value={selectedCards}
                                        itemToValue={(obj) => { return obj.key; }}
                                        errorMessage={selectedCards.length === 0 && formFilled ? 'Please select a value' : undefined}
                                        onChange={(e, { value }) => { setSelectedCards(value); }}
                                        onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                        noResultsMessage="We couldn't find any matches."
                                    />
                                }

                            </div>
                            <Flex
                                className="align-items-center"
                                gap="gap.small"
                                fill
                                hAlign="end"
                                styles={{ marginTop: "40 !important" }}
                            >
                                {!isDemo && submitButtonState === 2 && <div className="col text-right p-0">Scheduled dashboard has been created.</div>}
                                {isDemo && <div className="col text-right p-0">Exploring app with sample data.</div>}
                                <FormButton content={submitButtonState === 2 ? "Close" : "Cancel"} onClick={() => { setSubmitButtonState(0); props.cancelHandler(); }} />
                                <FormButton primary type="submit"
                                    icon={submitButtonState === 2 && <AcceptIcon />}
                                    loading={submitButtonState === 1}
                                    disabled={isDemo || submitButtonState === 2}

                                    content={
                                        submitButtonState === 0
                                            ? "Save"
                                            : submitButtonState === 1
                                                ? "Saving"
                                                : submitButtonState === 2
                                                    ? "Saved"
                                                    : "Failed"
                                    } />
                            </Flex>
                        </Form>


                    </Fragment>


            )}
        </WindowContext.Consumer>
    );
};

export default DashboardScheduler;
