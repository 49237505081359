import React, { useState, useEffect } from "react";
import {
    Flex,
    Input,
    Checkbox,
    Button,
    Text,
    Tooltip,
    Avatar,
    Loader,
    Label,
    Box,
} from "@fluentui/react-northstar";
import {
    SearchIcon,
    EditIcon,
    ApprovalsAppbarIcon,
    ExclamationTriangleIcon,
    ShiftActivityIcon,
    PresenceAvailableIcon,
    CloseIcon,
} from "@fluentui/react-icons-northstar";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import AuthHelper from "../../../services/auth-helper";
import "./config.scss";


const GroupInheritanceIcon = (props) => {
    const [groupsLoading, setGroupsLoading] = useState(false);
    const [inheritedGroups, setInheritedGroups] = useState(null);

    const getInheritedGroupList = () => {
        setInheritedGroups(null);
        setGroupsLoading(true);
        AuthHelper.getAccessToken(function (token) {
            ConfigurationApiService.GetInheritedGroups(props.userGUID, token).then(
                (response) => {
                    if (response !== undefined && response !== "") {
                        setInheritedGroups(response);
                    }
                    setGroupsLoading(false);
                }
            );
        });
    }

    return (
        <Tooltip
            className="tooltipTxt"
            trigger={
                <PresenceAvailableIcon
                    size="large"
                    styles={{ marginRight: "10px", color: props.colorValue !== undefined ? props.colorValue : "green" }}
                />
            }
            onOpenChange={getInheritedGroupList}
            align="center"
            position="before"
            content={groupsLoading ?
                <Loader size="small" /> :
                <div>
                    <div>Via Group Policy</div>
                    <ul style={{ paddingLeft: "15px", maxHeight: "120px", overflow: "auto" }}>
                        {props.inheritanceType === "administrative" && inheritedGroups && inheritedGroups.administrativeGroups &&
                            inheritedGroups?.administrativeGroups?.map((group) => {
                                return <li style={{ width: "100px" }}>{group}</li>
                            })
                        }
                        {props.inheritanceType === "reporting" && inheritedGroups && inheritedGroups.reportingGroups &&
                            inheritedGroups?.reportingGroups?.map((group) => {
                                return <li style={{ width: "100px" }}>{group}</li>
                            })
                        }
                        {props.inheritanceType === "subscription" && inheritedGroups && inheritedGroups.subscriptionGroups &&
                            inheritedGroups?.subscriptionGroups?.map((group) => {
                                return <li style={{ width: "100px" }}>{group}</li>
                            })
                        }
                    </ul>
                </div>
            }
        />
    )
}


const TrialExpiredIcon = (props) => {
    return (
        <Tooltip
            trigger={
                <ShiftActivityIcon
                    size="large"
                    styles={{ marginRight: "10px", color: "grey" }}
                />
            }
            align="center"
            position="below"
            content={"Trial completed"}
        />
    )
}


const LicenseWarningIcon = (props) => {
    return (
        <Tooltip
            trigger={
                <ExclamationTriangleIcon
                    size="large"
                    styles={{ marginRight: "10px", color: props.colorValue !== undefined ? props.colorValue : "orange" }}
                />
            }
            align="center"
            position="below"
            content= {props.type==="group" ? "Licences not available for Group Policy Inheritance" : "Licences not available for users"}
        />
    )
}





export { GroupInheritanceIcon, TrialExpiredIcon, LicenseWarningIcon }

