import React, { useEffect, useState } from 'react'
import InsightsCard from '../insights/insightsCard'
import '../insights/insights.scss';
import { Avatar, Text, Loader, Flex, FlexItem, Box, Skeleton, Button } from '@fluentui/react-northstar';
import { Dialog } from '@progress/kendo-react-dialogs';
import ConfigurationApiService from '../../../services/ConfigurationApiService';
import DashboardApiService from '../../../services/DashboardApiService';
import AuthHelper from '../../../services/auth-helper';
import FilterPopup from '../insights/FilterPopup';
import { Breadcrumb } from '@fluentui/react-northstar'
import { AddIcon, ChevronEndMediumIcon, PersonIcon } from '@fluentui/react-icons-northstar'
import InsightsCardSkeleton from '../insights/insightsCardSkeleton';
import UserInsights from '../insights/userInsights';
import WindowContext from '../../Shared/Context/Context';


function fnGetZoneIndicator(percentageValue, zones, label) {
    if ((percentageValue == 0 && (label == "Meetings" || label == "Messages")))
        return "disabled";

    if (0 < percentageValue < zones[0]) {
        return 'Negative';
    }
    else if (zones[0] < percentageValue < zones[1]) {
        return 'Neutral';
    }
    else if (zones[1] < percentageValue < zones[2]) {
        return 'Positive';
    }
    else if (zones[2] < percentageValue < zones[3]) {
        return 'Neutral';
    }
    else if (zones[3] < percentageValue < 100) {
        return 'Negative';
    }
    else {
        return ''
    }
}
const ListViewIndicator = ({ percentageValue, zones, label = 'Activity' }) => {
    const type = fnGetZoneIndicator(percentageValue, zones, label);

    return (
        <div className='col-auto px-0 mr-2 d-flex align-items-center'>
            {type && <div className={`list-view-indicator ${type}`} title={`${type} zone`}></div>}
            <div className='list-view-label px-2' title={label === 'Messages' ? 'Chats & Messages' : label}>{label}</div>
        </div>
    )
}

const TeamVibe = (
    { managerVibe, loading = false, managerName = '!No data', managerStatus = 'unknown', managerDesignation = '!No data', managerData, userLoading }
) => {
    return (<>
        {(loading && !userLoading) ?
            <div className='row align-items-center ml-auto'>

                <div className="insights-card cursor-default mx-3 shadow-bg-filter-header">
                    <div className="row">
                        <div className="col align-items-center justify-content-center insights-card-header">
                            <Skeleton.Shape width='60px' height='60px' />
                        </div>
                    </div>
                </div>
            </div> : managerData && !userLoading &&
            <div className='row align-items-center ml-auto'>

                <div className="insights-card cursor-default mx-3 shadow-bg-filter-header">
                    <div className="row">
                        <div className="col align-items-center justify-content-center insights-card-header">
                            <div className='name-label'>Team Vibe</div>
                            <div className='vibe-indicator align-self-end mt-4'>
                                <div className='vibe-indicator-emoji' >
                                    {managerVibe === 'positive' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Halo.png" alt="Neutral Face" width="38" height="38" />
                                        : (managerVibe === 'negative' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Worried%20Face.png" alt="Worried Face" width="38" height="38" />
                                            : <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Neutral%20Face.png" alt="Neutral Face" width="38" height="38" />
                                        )}
                                </div>
                                <div className='vibe-indicator-text bg-primary text-right'>
                                    {managerVibe === 'positive' ? 'Positive' : (managerVibe === 'negative' ? 'Negative' : 'Neutral')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
    )
}

const UserViewHeader = ({ userData, loading, ringCounts = 7, fnUpdateUserToView, networkUsers }) => {
    const [isManagerAccessible] = useState(networkUsers != null && networkUsers.findIndex(f => f.key === userData.managerGUID) >= 0)
    return (
        <WindowContext>
            {
                (context) => (
                    <>
                        {loading ?
                            <>
                                <div class="row mx-3 align-items-center" style={{ width: "392.6px" }}>
                                    <div className='col-auto'>
                                        <Skeleton.Avatar size='large' className='rounded-circle' />
                                    </div>
                                    <div className='col labels'>
                                        <Skeleton.Line width="75%" />
                                        <Skeleton.Line width="40%" />
                                        <Skeleton.Line width="60%" />
                                    </div>
                                </div>
                                <div className='row ml-auto mx-3 align-items-center' style={{ width: "392.6px" }}>
                                    <div className='col px-2 labels text-right'>
                                        <Skeleton.Line width="35%" />
                                        <Skeleton.Line width="70%" />
                                        <Skeleton.Line width="40%" />
                                    </div>
                                    <div className='col-auto'>
                                        <Skeleton.Avatar size='large' className=' rounded-circle' />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div class="col-auto pl-0">
                                    <div class={`row align-items-center insights-card-header ${context.mediaCategory.sm ? "ml-0 my-2" : "ml-3"}`}>
                                        {<Avatar
                                            name={userData.userName}
                                            image={userData.userImage}
                                            size="large"
                                            status={userData.userStatus}
                                        />}
                                        <div className='col-auto px-2 labels'>
                                            <div className='name-label'>{userData.userName}</div>
                                            <div className='designation-label'>{userData.userDesignation}</div>
                                            <div className='org-email'>{userData.userEmail}</div>
                                        </div>
                                        {userData.reporteeCount === 1 ? <Button
                                            className={context.mediaCategory.sm ? "mb-auto mr-0" : "mb-auto"}
                                            styles={{ marginRight: '4px' }}
                                            icon={<i class="bi bi-diagram-2" style={{ fontSize: '21px' }}></i>}
                                            iconOnly
                                            title="Team View"
                                            onClick={() => fnUpdateUserToView(userData.userGUID, true)}
                                        /> : <></>}
                                    </div>
                                </div>
                                {userData.manager ?
                                    <div class="col-auto pl-1">
                                        <div className={`row align-items-center ${isManagerAccessible ? 'cursor-pointer' : ''} ${context.mediaCategory.sm ? "ml-0" : "mr-3"}`} onClick={() => {
                                            if (isManagerAccessible) {
                                                fnUpdateUserToView(userData.managerGUID, true);
                                            }
                                            else {
                                                return;
                                            }
                                        }}>
                                            <div className='col-auto px-2 labels text-right'>
                                                <div className='reports-to-label'>Reports to</div>
                                                <div className={`name-label ${isManagerAccessible ? 'text-primary cursor-pointer' : ''}`}>{userData.manager}</div>
                                                <div className={`designation-label text-secondary`}>{userData.managerDesignation}</div>
                                            </div>
                                            {!context.mediaCategory.sm &&
                                                <Avatar
                                                    name={userData.manager}
                                                    size="large"
                                                    status={userData.managerStatus}
                                                    image={userData.managerImage}
                                                />}
                                        </div>
                                    </div>
                                    : <></>
                                }
                            </>
                        }
                    </>
                )
            }
        </WindowContext>

    )
}

function UserViewInsights(props) {
    const [loading, setLoading] = useState(props.loading);
    useEffect(() => {
        debugger
        setLoading(props.loading);
    }, [props.loading])


    return (
        <WindowContext>{
            (context) => (
                <div className='mx-2 overflow-hidden' style={{ height: "calc(100% - 36px)" }}>
                    <>
                        <div className="container container-cst align-items-center pr-md-0 my-md-3">
                            <div className='row justify-content-between'>
                                <UserViewHeader loading={loading} userData={props.insightData?.userData[0]} fnUpdateUserToView={props.fnUpdateUserToView}
                                    networkUsers={props.networkUsers} />
                            </div>
                        </div>
                        {!loading && < div className='container container-cst border-b-uinsights'></div>}

                        <div className={`px-3 mx-auto container-cst px-0 insights-body h-user-insights`}>
                            {loading ?
                                <div className="row gap-24 mx-auto my-3">
                                    {(
                                        Array.from({ length: 6 }).map((_, index) => (
                                            <InsightsCardSkeleton key={index} />
                                        ))
                                    )}
                                </div> :
                                <UserInsights
                                    userData={props.insightData?.userData[0]}
                                    responseScale={props.wellBeingScoreDescriptionList}
                                />
                            }
                        </div>

                    </>
                </div >
            )
        }
        </WindowContext>
    )
}

export default UserViewInsights