import React, { useState } from 'react';
import './insights.scss';
import { Avatar, Button, Layout, Skeleton, Tooltip } from '@fluentui/react-northstar';
import HSCProgress from './hscProgress';
import {
    Chart,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
} from "@progress/kendo-react-charts";
import { red } from '@material-ui/core/colors';

const series = [
    {
        name: "Total",
        data: [7, 11, 12, 4, 6, 13],
    },
    {
        name: "Recurring",
        data: [4, 5, 5, 1, 2, 7],
    },
    {
        name: "External",
        data: [2, 5, 6, 1, 1, 5],
    }
];

const series2 = [
    {
        name: "Positive",
        data: [0, 0, 20, 0, 0, 30]
    },
    {
        name: "Negative",
        data: [0, 45, 0, 0, 35, 0]
    },
    {
        name: "Neutral",
        data: [75, 0, 0, 78, 0, 0]
    }
];

const categories = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"];



function UserInsights({ key, responseScale, userData }) {
debugger
    const TeamViewIndicator = ({ type, value = '!' }) => {
        return (
            <div className='col-auto pl-2 pr-1 d-flex align-items-center'>
                {type && <div className={`detail-view-indicator ${type}`} title={`${type} zone`}></div>}
                <div className='detail-view-label px-2' title={type + ' People'}>{type} - {value}</div>
            </div>
        )
    }
    const WellbeingCategoryScoreListComponent = ({category}) => {
        debugger
        return (
            <div className="wellbeing-category mt-2 mb-2">
                <Tooltip content={category.desc}
                    trigger={
                        <div className='col-auto pl-0'>
                            {category.label}
                        </div>
                    }
                />
                <div className="wellbeing-bar">
                <div className={`wellbeing-fill wi-${category.val}`}>{responseScale[category.val]}</div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='cards-section py-4'>
                <div class="insights-card-container h-100">
                    <div className='insights-card cursor-default h-100'>
                        <div class="container">
                            <HSCProgress
                                className="border-b-uinsights pb-2"
                                label={'Meetings'}
                                progress={userData.meetingsPercentage}
                                zones={userData.meetingsZone}
                            />
                            <div class="insights-card-body">
                                <div className='row'>
                                    <div className='col'>
                                        <div class="row">
                                            <div class="col-auto">Total</div>
                                            <div class="col ml-auto text-right">000</div>
                                        </div>
                                        <div class="text-right">0h 0m 0s</div>
                                    </div>
                                    <div className='col'>
                                        <div class="row">
                                            <div class="col-auto">Recurring</div>
                                            <div class="col ml-auto text-right">000</div>
                                        </div>
                                        <div class="text-right">0h 0m 0s</div>
                                    </div>
                                    <div className='col'>
                                        <div class="row">
                                            <div class="col-auto">External</div>
                                            <div class="col ml-auto text-right">000</div>
                                        </div>
                                        <div class="text-right">0h 0m 0s</div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col'>
                                        <Chart
                                            style={{
                                                height: 200,
                                            }}
                                        >
                                            <ChartLegend position="top" orientation="horizontal" />
                                            <ChartCategoryAxis>
                                                <ChartCategoryAxisItem categories={categories} startAngle={45} />
                                            </ChartCategoryAxis>
                                            <ChartSeries>
                                                {series.map((item, idx) => (
                                                    <ChartSeriesItem
                                                        key={idx}
                                                        type="line"
                                                        tooltip={{
                                                            visible: true,
                                                        }}
                                                        data={item.data}
                                                        name={item.name}
                                                    />
                                                ))}
                                            </ChartSeries>
                                        </Chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="insights-card-container h-100">
                    <div className='insights-card cursor-default h-100'>
                        <div class="container">
                            <HSCProgress
                                className="border-b-uinsights pb-2"
                                label={'Messages'}
                                progress={userData.callsPercentage}
                                zones={userData.callsZone}
                            />
                            <div class="insights-card-body">
                                <div className='row'>
                                    <div className='col'>
                                        <div class="">Collaborators</div>
                                        <div class="text-right">000</div>
                                    </div>
                                    <div className='col'>
                                        <div class="">Messages count</div>
                                        <div class="text-right">000</div>
                                    </div>
                                    <div className='col'>
                                        <div class="">Sentiments</div>
                                        <div class="text-right">Neutral</div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col'>
                                        <Chart
                                            seriesColors={["#28a745", "#dc3545", "#ffc107"]}
                                            style={{
                                                height: 200,
                                            }}
                                        >
                                            <ChartLegend position="top" orientation="horizontal" />
                                            <ChartCategoryAxis>
                                                <ChartCategoryAxisItem categories={categories} startAngle={45} />
                                            </ChartCategoryAxis>
                                            <ChartSeries>
                                                {series2.map((item, idx) => (
                                                    <ChartSeriesItem
                                                        key={idx}
                                                        type="column"
                                                        tooltip={{
                                                            visible: true,
                                                        }}
                                                        data={item.data}
                                                        name={item.name}
                                                    />
                                                ))}
                                            </ChartSeries>
                                        </Chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-between gap-24 insights-card-container h-100">
                    <div className='insights-card cursor-default h-100'>
                        <div class="container">
                            <HSCProgress
                                className="border-b-uinsights pb-2"
                                label={'Focused'}
                                progress={userData.focusedPercentage}
                                zones={userData.focusedZone}
                            />
                            <div class="insights-card-body">
                                <div className='row'>
                                    <div className='col'>
                                        Available focuse time : {userData.focusedPercentage}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='insights-card cursor-default h-100'>
                        <div class="container">
                            <h6>Wellbeing Status Overview</h6>
                            <div class="insights-card-body">
                                {userData.categoryTag && userData.categoryTag.map((item) => <WellbeingCategoryScoreListComponent category={item} />)}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="insights-card-container">
                    <div className='insights-card cursor-default h-100'>
                        <div class="container h-100">
                            <h3>
                                Sentiments
                            </h3>
                            <div class="insights-card-body bg-light-insight rounded overflow-hidden">
                                <div class="col py-2">
                                    <h>Negative - Top 5</h>
                                    <div className='row justify-content-center mt-2 pb-2 border-bottom'>
                                        {userData.topSentimentUsers.map((user) => (

                                            < Tooltip
                                                content={
                                                    < Layout vertical start={user.value.displayName} main={user.value.sentiment > 0 ? `${user.value.sentiment * 100 + "% Positive Sentiments"}` : `${(user.value.sentiment * -(100)) + "% Negative Sentiments"}`} end={user.value.negativeMsgCount + " Negative Message Counts"} />
                                                }
                                                pointing
                                                trigger={
                                                    <Avatar
                                                        className={`sentiments-value-border nv-${(user.value.sentiment * 10)}`}
                                                        name={user.value.displayName}
                                                        image={user.value.photo}
                                                        size="large"
                                                    />
                                                } />
                                        ))}
                                    </div>
                                </div>
                                <div class="col" style={{ height: "136px" }}>
                                    <h>Sentiments with others</h>
                                    <div className='row mx-0 py-2 gap-12' style={{
                                        height: "calc(100% - 30px)",
                                        overflow: "auto"
                                    }}>
                                        {userData.otherSentimentUsers.map((user) => (
                                            < Tooltip
                                                content={
                                                    < Layout vertical start={user.value.displayName} main={user.value.sentiment > 0 ? `${user.value.sentiment * 100 + "% Positive Sentiments"}` : `${(user.value.sentiment * -(100)) + "% Negative Sentiments"}`} end={user.value.negativeMsgCount + " Negative Message Counts"} />
                                                }
                                                pointing
                                                trigger={
                                                    <Avatar
                                                        className={`sentiments-value-border pv-${(user.value.sentiment * 10)}`}
                                                        name={user.value.displayName}
                                                        image={user.value.photo}
                                                        size="large"
                                                    />
                                                } />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="insights-card-container user-insight-meeting-behaviour">
                    <div className='insights-card cursor-default h-100'>
                        <div class="container">
                            <h4>Meeting Behaviour</h4>
                            <div class="insights-card-body">
                                <div className='row gap-24'>
                                    <div className='col'>
                                        <div class="row py-2 bg-light-insight rounded mb-4">
                                            <div class="col">
                                                <div class="row">
                                                    <div class="col"><h5>Recurring meetings</h5></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col font-weight-bold">Meeting Name and Time</div>
                                                    <div class="col-auto font-weight-bold">Joined as</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">Meeting 1 | 4th August 2024 | 32m 41s</div>
                                                    <div class="col-auto">Participants</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">Meeting 1 | 1th August 2024 | 12m 35s</div>
                                                    <div class="col-auto">Oraganiser</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-4 py-2 bg-light-insight rounded">
                                            <div class="col"><h5>Overran meetings</h5></div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div class="row mb-4 py-2 bg-light-insight rounded">
                                            <div class="col"><h5>Late join meeting list</h5></div>
                                        </div>
                                        <div class="row mb-4 py-2 bg-light-insight rounded">
                                            <div class="col"><h5>Missed meetings</h5></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default UserInsights