import { useState, useEffect } from "react";
import "./dashboard.scss";
//import { AllWidgets } from "./allwidgets";
import {
  Flex,
  Segment,
  List,
  Loader,
  Divider,
  Header,
} from "@fluentui/react-northstar";
import DashboardApiService from "../../../services/DashboardApiService";
import AuthHelper from "../../../services/auth-helper";
import SingleCardSettings from "../../filters/card/SingleCardSettings/SingleCardSettings";
import WindowContext from "../../Shared/Context/Context";

const MyCards = (props) => {
  const [myCards, setMyCards] = useState([]);  
  useEffect(() => {    
    setMyCards(
      props.cards.map((card) => {
          return { key: card.cardID, header: card.name };

      })
    );   

  }, [props.cards]);

  useEffect(() => {
    const elementss = document.getElementById("mycardsList").querySelectorAll('li');
    elementss.forEach(item => {
      if (item.getAttribute('aria-selected') === "true") {
        item.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }, [myCards.length])

  return (
    <WindowContext.Consumer>
      {(context) => (
        <>
          <Divider content="My Cards" />
          <List
            styles={{ maxHeight: 'calc(100% - 23px)', overflowY: 'auto' }}
            className={context.mediaCategory.sm ? "categorylist editCard_list myCardsSm" : "categorylist editCard_list"} id="mycardsList"
            selectable
            selectedIndex={props.selectedCardIndex}
            onSelectedIndexChange={(e, newProps) => {
              props.handleCardChange(
                newProps.selectedIndex,
                newProps.items[newProps.selectedIndex].key
              );
            }}
            items={myCards}
          />

        </>
      )}
    </WindowContext.Consumer>
  );
};

const EditCard = (props) => {

  const [loaded, setLoaded] = useState(false);
  const [dashboardId, setDashboardId] = useState(props.dashboardId);
  const [cards, setCards] = useState([]);
  const [cardsList, setcardsList] = useState(props.AddcardList);
  const [selectedCard, setSelectedCard] = useState(-1);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  useEffect(() => {
    setCards(cards);
    //setcardsList(cards);
  }, [cards]);
  useEffect(() => {
    //console.log(props.dashboardId)
    setDashboardId(props.dashboardId);
    setLoaded(false);

    setSelectedCardIndex(0);
    setSelectedCard(-1);

    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetDashboardCardsWithFilters(props.dashboardId, token).then((response) => {
        if (response && response.length > 0) {
          setCards(response);
          if (props.editCardID >= 0) {
            setSelectedCardIndex(response.findIndex((res) => res.cardID === props.editCardID));
            setSelectedCard(props.editCardID);
            global.localStorage.setItem(
              "sCIndex",
              response.findIndex((res) => res.cardID === props.editCardID)
            );
            global.localStorage.setItem(
              "sCard",
              props.editCardID);
          } else if (props.editCardID === -2) {//rendering from settings tab to dashboard tab
            setSelectedCardIndex(parseInt(global.localStorage.getItem(
              "sCIndex")));
            setSelectedCard(parseInt(global.localStorage.getItem(
              "sCard")));
          }
          else if (props.editCardID === -1) {//switching between dashboards

            setSelectedCardIndex(0);
            setSelectedCard(response[0].cardID);
          }
        } else {
          setSelectedCardIndex(-1);
          setSelectedCard(-1);
        }
        setLoaded(true);
      });
    });
  }, [props.dashboardId]);
  useEffect(() => {
    AuthHelper.getAccessToken(function (token) {    
    DashboardApiService.GetDashboardCards(dashboardId, token).then((response) => {       
        if (response !== undefined && response !==" ") {                             
            global.localStorage.setItem("TestItems",response.length); 
        }           
     });  
  });
},[]);
  const handleCardChange = (_cardIndex, _cardId) => {
    setSelectedCardIndex(_cardIndex);
    setSelectedCard(_cardId);
    global.localStorage.setItem(
      "sCIndex",
      _cardIndex
    );
    global.localStorage.setItem(
      "sCard",
      _cardId);
  };

  const onCancel = () => {
    //console.log("cancelled");
  };

  const onConfirm = (updatedCardData) => {
    //console.log(updatedCardData);
    let _tempCard = [...cards];
    let _checkcardIndex = cards.findIndex((card) => card.cardID === updatedCardData.cardID);
    if (_checkcardIndex === -1) {
      _tempCard.push(updatedCardData);
    }
    let _cardIndex = _checkcardIndex === -1 ? _tempCard.findIndex((card) => card.cardID === updatedCardData.cardID) : _checkcardIndex;

    let _cards = _tempCard;
    let _card = _cards[_cardIndex];
    _card.expiredFilterType = -1;
    _card.expiredFilterValues = null;
    _card.callQuality = updatedCardData.callQualityFilter === "" ? [] : updatedCardData.callQualityFilter.split(",").map((x) => Number(x));
    _card.groups = updatedCardData.GroupFilter !== ""
      ? updatedCardData.GroupFilter.split(",").map((x) => Number(x)) : [];
    _card.messages = updatedCardData.messagesFilter;
    _card.modalities = updatedCardData.modalitiesFilter === "" ? [] : updatedCardData.modalitiesFilter.split(",").map((x) => Number(x));
    _card.msgParticipants = updatedCardData.msgParticipantsFilter === "" ? [] : updatedCardData.msgParticipantsFilter.split(",").map((x) => Number(x));
    _card.callParticipants = updatedCardData.callParticipantsFilter === "" ? [] : updatedCardData.callParticipantsFilter.split(",").map((x) => Number(x));
    _card.joinedAs = updatedCardData.joinedAsFilter === "" ? [] : updatedCardData.joinedAsFilter.split(",").map((x) => Number(x));
    _card.collaborationType = updatedCardData.collaborationTypeFilter === "" ? [] : updatedCardData.collaborationTypeFilter.split(",").map((x) => Number(x));
    _card.name = updatedCardData.name;
    _card.reactions = updatedCardData.reactionFilter === "" ? [] : updatedCardData.reactionFilter.split(",").map((x) => Number(x));
    _card.runPeriod = updatedCardData.runPeriod;
    _card.selectedEndTime = updatedCardData.selectedEndTime;
    _card.selectedStartTime = updatedCardData.selectedStartTime;
    _card.selectedStartDate = updatedCardData.selectedStartDate;
    _card.selectedEndDate = updatedCardData.selectedEndDate;
    _card.sentiment = updatedCardData.sentimentFilter
      .split(",")
      .map((x) => Number(x));
    _card.teams =
      updatedCardData.TeamFilter !== ""
        ? updatedCardData.TeamFilter.split(",").map((x) => Number(x))
        : [];
    _card.timePeriod = updatedCardData.timePeriod;
    _card.cardFilterID = updatedCardData.cardFilterID;
    _card.users = updatedCardData.userFilter !== ""
      ? updatedCardData.userFilter.split(",").map((x) => Number(x)) : [];
    _card.callQueues = updatedCardData.callQueueFilter !== ""
      ? updatedCardData.callQueueFilter.split(",").map((x) => Number(x)) : [];
    _card.agents = updatedCardData.agentFilter !== ""
      ? updatedCardData.agentFilter.split(",").map((x) => Number(x)) : [];
    _cards[_cardIndex] = _card;
    setCards(_cards);
    setSelectedCardIndex(_cardIndex);
    setSelectedCard(updatedCardData.cardID);
  };

  return loaded && props.userFiltersData ? (
    <Flex
      className="editcardcontainer"
      gap="gap.small"
      padding="padding.medium"
      style={{
        height: '100%',
        //maxHeight: '100%'
      }}
    >
      {cards && cards.length > 0 && selectedCard > 0 ? (
        <>
          <Flex.Item size="size.quarter">
            <Segment>
              <MyCards
                cards={cards}
                selectedCardIndex={selectedCardIndex}
                handleCardChange={handleCardChange}
              />
            </Segment>
          </Flex.Item>

          <Flex.Item size="size.half" grow>
            <Segment>
              {selectedCard !== -1 ? (
                <>
                  <Header
                    as="h5"
                    styles={{ fontSize: '1.15rem' }}
                    content={
                      cardsList.find(
                        (widget) =>
                          widget.cardWidgetID ==
                          cards.find((card) => card.cardID == selectedCard)
                            .cardWidgetID
                      ).widgetName
                    }
                  />
                  <SingleCardSettings
                    cardData={cards.find(
                      (card) => card.cardID === selectedCard
                    )}
                    dashboardId={dashboardId}
                    submitHandler={onConfirm}
                    cancelHandler={onCancel}
                    userFiltersData={props.userFiltersData}
                    demo={props.demo}
                    cardList={cardsList}
                  />
                </>
              ) : (
                <></>
              )}
            </Segment>
          </Flex.Item>
        </>
      ) : (
        <p>Please add cards to edit!</p>
      )}
    </Flex>
  ) : (
    <Loader />
  );
};

export default EditCard;
