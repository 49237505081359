import { useState, useEffect } from "react";
import _ from "lodash";
import "../pages/Dashboard/dashboard.scss";
import "./sharedDashboard.scss";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import DashboardApiService from "../../services/DashboardApiService";
import FiltersApiService from "../../services/FiltersApiService";
import { Flex, Text, Loader, Segment } from "@fluentui/react-northstar";
import { SharedCardWidget } from "./sharedcardwidget";


const SharedCardComponent = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [card, setCard] = useState([]);
    const [cID, setCID] = useState(null);
    const [userFiltersData, setUserFiltersData] = useState(null);
    const [isDemo, setIsDemo] = useState(false);

    let cardTimer;

    useEffect(() => {
        var urlCID = props.location.search.split('&').find((el) => el.includes("cID=")).split('=')[1];

        try {
            var isDemoMode = props.location.search.split('&').find((el) => el.includes("d=")).split('=')[1];

            if (isDemoMode === '1')
                setIsDemo(true);
        }
        catch (e) {
            console.log(e);
        }

        setCID(urlCID);
        setLoaded(false);
        setCard(null);
        clearInterval(cardTimer);
        getCardData(urlCID);
        cardTimer = setInterval(function(){
            getCardData(urlCID);
        }, 120000);
        return () => clearInterval(cardTimer);
    }, [props.location.search]);

    const getCardData = (urlCID) => {
        FiltersApiService.GetSharedCardDetails(urlCID).then(
            (response) => {
                if (response !== undefined && response !== "") {
                    setCard(response);
                    getSharedUserData(urlCID)
                }
                else{
                    setCard(null);
                    setLoaded(true);
                }
            }
        );
    }

    const getSharedUserData = (urlCID) => {
        FiltersApiService.GetSharedUserData(urlCID).then((response) => {
            if (response && response != "") {
                let data = response;
                data.teamFilter.items = data.teamFilter.items.map((obj) => ({
                ...obj,
                key: obj.value,
                }));
                data.groupFilter.items = data.groupFilter.items.map((obj) => ({
                ...obj,
                key: obj.value,
                }));
                data.callQueueFilter.items = data.callQueueFilter.items.map((obj) => ({
                ...obj,
                key: obj.value,
                }));
                data.agentFilter.items = data.agentFilter.items.map((obj) => ({
                ...obj,
                key: obj.value,
                }));
                data.userFilter.items = data.userFilter.items.map((obj) => ({
                ...obj,
                key: obj.value,
                }));
                setUserFiltersData(data);
            }
            setLoaded(true);
        });
    };

    return loaded ? (card !== null ? (
            <div className="cardHeight">
                <SharedCardWidget
                card={card}
                userFiltersData={userFiltersData}
                isDemo={isDemo}
                />
            </div>
        ) :
        (<Flex
            fill
            className="default-segment-container"
            hAlign="center"
            vAlign="center"
        >
            <Segment className="default-segment">
                <Flex gap="gap.small" column hAlign="center" vAlign="center">
                    <Text content="Card does not exist." />
                </Flex>
            </Segment>
        </Flex>)
    )
     : (
        <Loader />
    );
};

export default SharedCardComponent;
