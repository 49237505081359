import { Button, Text } from "@fluentui/react-northstar";

import { AddIcon, CallDialpadIcon } from "@fluentui/react-icons-northstar";

import "../Dashboard/dashboard.scss";

const _cardCollaborationType = {
    1: "Calls",
    2: "Meetings",
  3: "Messages",
  4: "Collaboration",
  5: "Teams and Channels",
  //6: "Teams Calling",
};

export const CellWithVisualizationIcon = (props) => {
  const data = props.dataItem;
  return (
    <td>
      <div className="widget_visualization_container">
        {data.Visualization === "summary" && (
          <i title="Summary" className={`analytics-${data.Visualization}`} />
        )}
        {data.Visualization === "barchart" && (
          <i title="Bar Chart" className={`analytics-${data.Visualization}`} />
        )}
        {data.Visualization === "piechart" && (
          <i title="Pie Chart" className={`analytics-${data.Visualization}`} />
        )}
        {data.Visualization === "list" && (
          <i title="List" className={`analytics-${data.Visualization}`} />
        )}
        {data.Visualization === "table" && (
          <i title="Table" className={`analytics-${data.Visualization}`} />
        )}
        {data.Visualization === "generalanalytics" && (
          <i
            title="General Analytics"
            className={`analytics-${data.Visualization}`}
          />
        )}
      </div>
    </td>
  );
};

export const CellWithCategoryIcon = (props) => {
  const data = props.dataItem;
  return (
    <td>
    <div className="widget_target_container">
      {data.isProductivity === 1 && (
        <i title="People Engagement" className={"analytics-productivity"} />
      )}
      {data.isCustomerExperience === 1 && (
        <i
          title="Customer Engagement"
          className={"analytics-customerExperience"}
        />
      )}
      {data.isWellness === 1 && (
        <i title="Wellbeing" className={"analytics-wellbeing"} />
      )}
      {data.isTeamsCalling === 1 && (
        <i title="Teams Calling" className={"analytics-headphones"} />
      )}
    </div>
    </td>
  );
};
export const CellWithViewIcon = (props) => {
  const data = props.dataItem;
  return (
    <td>
    <div className="widget_category_container">
      {data.categoryTypeID === 1 && (
        <i title="Summary" className={"analytics-summary"} />
      )}
      {data.categoryTypeID === 2 && (
        <i title="Activity" className={"analytics-activity2"} />
      )}
      {data.categoryTypeID === 3 && (
        <i title="Trends" className={"analytics-trends"} />
      )}
      {data.categoryTypeID === 4 && (
        <i title="Comparison" className={"analytics-comparison2"} />
      )}
      {data.categoryTypeID === 5 && (
        <i title="Insight" className={"analytics-insights"} />
      )}
      {data.categoryTypeID === 6 && (
            <i title="Insight" className={"analytics-list"} />
      )}
    </div>
    </td>
  );
};

export const CellWithTypeIcon = (props) => {
  const data = props.dataItem;
  return <td><Text content={_cardCollaborationType[data.collaborationTypeID]} /></td>;
};

export const CellWithAddIcon = (props) => {
  const data = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      <Button
        onClick={() => props.addCard({ ...data, cardID: -1 })}
        icon={<AddIcon />}
        content="Add"
        size="small"
        primary
      />
    </td>
  );
};
