import { useState, useEffect } from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { CalendarIcon } from "@fluentui/react-icons-northstar";

import "./teamsdatepicker.scss";

const formatDate = (value) => {
  // API: yyyy-MM-dd
  // Display: dd/MMM/yyyy
  let _date = new Date(value);
  let _dateString = _date.toDateString();
  let _dateStringLength = _dateString.length;
  let _yearString = _dateString.substr(_dateStringLength - 4, 4);
  let _dayString =
    _date.getDate() < 10 ? "0" + _date.getDate() : String(_date.getDate());
  let _monthString =
    _date.getMonth() + 1 < 10
      ? "0" + (_date.getMonth() + 1)
      : String(_date.getMonth() + 1);
  let _monthDisplayString = _dateString.substr(4, 3);

  let returnDateValue = _yearString + "-" + _monthString + "-" + _dayString;
  let returnDateDisplayValue =
    _dayString + "/" + _monthDisplayString + "/" + _yearString;

  return {
    value: returnDateValue,
    displayValue: returnDateDisplayValue,
  };
};

const TeamsDatePicker = (props) => {
  const [value, setValue] = useState("");
  const [minDate, setMinDate] = useState(formatDate("2000-01-01T00:00:00").value);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let result = formatDate(props.value);
    if (props.minDate) {
      let minDate = formatDate(props.minDate);
      setMinDate(minDate.value);
      if (props.minDate > props.value) {
        setValue(minDate.value);
      }
      else {
        setValue(result.value);
      }
    }
    else {
      setValue(result.value);
    }
  }, [props.value, props.minDate]);


  return (
    <div className="teamsDatePicker" id="teamsDatePicker">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          disableToolbar
          variant="inline"
          format="DD/MMM/YYYY"
          value={value}
          minDate={minDate}
          onChange={(e) => { props.onChange(formatDate(e._d).value); }}
          onKeyPress={e => { e.which === 13 && e.preventDefault() }}
          disabled={props.disabled}
          autoOk
        />
      </MuiPickersUtilsProvider>
      <CalendarIcon className={`calender-icon ${props.disabled ? " disabled" : ""}`} onClick={() => props.disabled ? "" : setOpen(true)} />
    </div>
  );
};

export default TeamsDatePicker;
