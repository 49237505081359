import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartTooltip,
    ChartLegend
} from '@progress/kendo-react-charts';
import './DonutChart.scss';

// const donutData = [
//     {
//         name: "Positive",
//         share: 0.6
//     },
//     {
//         name: "Neutral",
//         share: 0.3
//     },
//     {
//         name: "Negative",
//         share: 0.1
//     } 
// ];


export default function DonutChart({data}){
    const renderTooltip = (point) => {
        const { category } = point.point || point;
        let total=0;
        for(let i=0; i<data.length; i++)
            if(category === data[i].name)
                total = data[i].total;
        return (
        <div>
            {category}: {total} 
        </div>
        );
    };

    return (
        <Chart style={{height: '100%'}}>
            <ChartTooltip render={renderTooltip} />
            <ChartSeries>
                <ChartSeriesItem type="donut" data={data} categoryField="name" field="share">
                    <ChartSeriesLabels color="#fff" background="none" />
                </ChartSeriesItem>
            </ChartSeries>
            <ChartLegend position="top" orientation="horizontal" />
        </Chart>
      );
}
