import React, { useEffect, useState } from "react";
import { useTeams } from "../../Shared/Themes";
import CircleChart from "../../Shared/uicomponents/circleChart";
import "./MeetingSummaryView.scss";
import WindowContext from "../../Shared/Context/Context";

const MeetingSummaryView = ({ data }) => {
  return (
    <WindowContext>{
      (context) => (
        <div className="meetingArea meettingStr">
          <div className="meetingData">
            <div className="meetingDataCol h-100 border ml-1">
              <div className="meetingDataTitle m-1 fs-13px h-25">Joined as Organiser</div>
              <div className={context.mediaCategory.sm ? "d-flex flex-column" : "meetingDataTrk my-1 h-75"}>
                <div className={context.mediaCategory.sm ? "" : "meetingDataTotal w-25"}>
                  <div className="meetingDataMain-val">{data.totalOrganized}</div>
                </div>
                <div className={context.mediaCategory.sm ? "px-1" : "meetingDataSub p-0 w-75"}>
                  <div className="meetingEnt">
                    <div className="meetingEnt-val text-nowrap">{data.internalOrganized}</div>
                    <div className="meetingEnt-p">Internal</div>
                  </div>
                  <div className="meetingEnt">
                    <div className="meetingEnt-val text-nowrap">{data.externalOrganized}</div>
                    <div className="meetingEnt-p">External</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="meetingDataCol h-100 border">
              <div className="meetingDataTitle m-1 fs-13px h-25">Joined as Participant</div>
              <div className={context.mediaCategory.sm ? "d-flex flex-column" : "meetingDataTrk my-1 h-75"}>
                <div className={context.mediaCategory.sm ? "" : "meetingDataTotal w-25"}>
                  <div className="meetingDataMain-val">
                    {data.totalParticipated}
                  </div>
                </div>
                <div className={context.mediaCategory.sm ? "px-1" : "meetingDataSub p-0 w-75"}>
                  <div className="meetingEnt">
                    <div className="meetingEnt-val text-nowrap text-nowrap">
                      {data.internalParticipated}
                    </div>
                    <div className="meetingEnt-p">Internal</div>
                  </div>
                  <div className="meetingEnt">
                    <div className="meetingEnt-val text-nowrap">
                      {data.externalParticipated}
                    </div>
                    <div className="meetingEnt-p">External</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="meetingDataCol h-100 border mr-1">
              <div className="meetingDataTitle m-1 fs-13px h-25">{context.mediaCategory.sm ? "Scheduled Meetings" : "Time in Scheduled Meetings"}</div>
              <div className={context.mediaCategory.sm ? "d-flex flex-column" : "meetingDataTrk my-1 h-75"}>
                <div className={context.mediaCategory.sm ? "" : "meetingDataTotal w-25"}>
                  <div className="meetingDataMain-val">
                    {data.totalTimeInMeetings}
                  </div>
                </div>
                <div className={context.mediaCategory.sm ? "px-1" : "meetingDataSub p-0 w-75"}>
                  <div className="meetingEnt">
                    <div className="meetingEnt-val text-nowrap">
                      {data.internalTimeInMeetings}
                    </div>
                    <div className="meetingEnt-p">Internal</div>
                  </div>
                  <div className="meetingEnt">
                    <div className="meetingEnt-val text-nowrap">
                      {data.externalTimeInMeetings}
                    </div>
                    <div className="meetingEnt-p">External</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="meetingGraph">
            <div className="meetingsGraphTrk pb-0">
              <div className="meetingGraph-area">
                <CircleChart
                  id="xx1"
                  value={data.externalParticipation}
                  size="small"
                  tooltipData={data.totalExternalParticipation}
                />
              </div>
              <div className="meetingGraph-p">External Scheduled Meetings</div>
            </div>
            <div className="meetingsGraphTrk pb-0">
              <div className="meetingGraph-area">
                <CircleChart id="xx1" value={data.recurringMeetings} size="small" tooltipData={data.totalRecurringMeeting} />
              </div>
              <div className="meetingGraph-p">% Recurring Scheduled Meetings</div>
            </div>
            <div className="meetingsGraphTrk pb-0">
              <div className="meetingGraph-area">
                <CircleChart id="xx1" value={data.perMeetings} size="small" tooltipData={data.totalPerMeetings} />
              </div>
              <div className="meetingGraph-p">% Total Meetings/Calls Scheduled</div>
            </div>
            <div className="meetingsGraphTrk pb-0">
              <div className="meetingGraph-area">
                <CircleChart id="xx1" value={data.overrun} size="small" tooltipData={data.totalOverrun} />
              </div>
              <div className="meetingGraph-p">Scheduled Meetings Overran</div>
            </div>
          </div>
        </div>
      )}</WindowContext>
  );
};

export default MeetingSummaryView;
