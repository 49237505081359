import React, {useState} from 'react';
import { Redirect, useLocation } from "react-router-dom";
import { Card,CardHeader, CardBody, Flex, Button, Text, Segment, TeamCreateIcon } from '@fluentui/react-northstar'

const NotTeams = () => {
    const { state } = useLocation();
    const { from } = state || { from: { pathname: "/" } };
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);

    const login = () => {
        setRedirectToReferrer(true);
    };

    if (redirectToReferrer) {
        return <Redirect to={from} />;
    }

    return (
        <Flex fill className="auth-form-container default-segment-container" hAlign="center" vAlign="center">
        <Segment className="auth-form-segment default-segment">
            <Flex gap="gap.small" column hAlign="center" vAlign="center">
                {/* <Text weight="semibold" size="large" content={from.pathname}/> */}
                <Text weight="semibold" size="large" content="This app is supported in Microsoft Teams."/>
                    <Text content={`Please visit the app in Microsoft Teams to start using ${process.env.REACT_APP_APP_BRAND_NAME}.`} />
            </Flex>
        </Segment>
        </Flex>
    );
}

export default NotTeams;