import React from "react";

const ExploreAppMode = React.createContext();
const savePDF = React.createContext();

const ExploreAppProvider = ExploreAppMode.Provider
const ExploreAppConsumer = ExploreAppMode.Consumer

const SavePDFProvider = savePDF.Provider
const SavePDFConsumer = savePDF.Consumer

export { ExploreAppProvider, ExploreAppConsumer, SavePDFProvider, SavePDFConsumer }