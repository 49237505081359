import { rgbToHex } from "@material-ui/core";
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartTooltip
} from "@progress/kendo-react-charts";
import "hammerjs";
import { random } from "lodash";
import './LineChart.scss';


export default function LineChart({ data }) {
            

    const renderTooltip = ({
        point
    }) => <span>{point.category.toString()}: {point.value}</span>;

    return (
        <>
        {
            data && data.categories && data.categories.length > 0 &&
                <Chart style={{ height: '100%' }} pannable={{
                    lock: "y",
                }}
                    zoomable={{
                        mousewheel: {
                            lock: "y",
                        },
                        selection: {
                            lock: "y",
                        },
                    }}>
                <ChartLegend position="top" orientation="horizontal" />
                <ChartCategoryAxis>
                        <ChartCategoryAxisItem categories={data.categories} labels={{
                            rotation: 'auto',
                            step:"10"
                        }} maxDivisions={10} />
                </ChartCategoryAxis>
                <ChartSeries>
                    {data.series.map((item, idx) => (
                        <ChartSeriesItem
                            key={idx}
                            type="line" 
                            markers={{
                                visible: false
                              }}
                              style={'smooth'}
                            color={"rgb"+"("+ (Math.random() * 200 + 75).toString()+","+ (Math.random() * 200 + 75).toString()+","+ (Math.random() * 200 + 75).toString()+")"}
                            tooltip={{ visible: true }}
                            data={item.data}
                            name={item.name}
                        />
                    ))}
                    </ChartSeries>
                    <ChartTooltip render={renderTooltip} />
            </Chart>
        }
        </>
      );
}
