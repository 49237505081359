import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem
} from '@progress/kendo-react-charts';
import { Path, Group, Text } from "@progress/kendo-drawing";
import "hammerjs";
import './ColumnChart.scss';
import { SavePDFConsumer } from '../../Shared/Context/ExploreAppMode';
import { useInView } from 'react-intersection-observer';



export default function ColumnChart({ data, widgetId }) {
    //const renderTooltip = ({
    //    point
    //}) => <span>{point.category.toString()}: {point.value} secs</span>;


    const { ref, inView } = useInView()

    const renderTooltip = (graph) => {
        var date = new Date(null);
        date.setSeconds(graph.point.value * 60 * 60); // specify value for SECONDS here
        var result = date.toISOString().substr(11, 8);

        return (
            <div>
                <div>{graph.point.category.toString()} : {result}</div>
            </div>
        );
    };

    //const onRender = (args) => {
    //    const chart = args.target.chartInstance;
    //    if (!chart) { return; }

    //    const valueAxis = chart.findAxisByName("valueAxis");
    //    const categoryAxis = chart.findAxisByName("categoryAxis");
    //    const range = categoryAxis.range();
    //    const categorySlot = categoryAxis.slot(range.min, range.max); 
    //    const collaborationSlot = valueAxis.slot(1);

    //    const collaborationLineLegend = new Path({ stroke: { color: "#36a2eb", width: 1.5 } })
    //        .moveTo(categorySlot.topRight().x - 100, 44)
    //        .lineTo(categorySlot.topRight().x, 44);

    //    const collaborationLine = new Path({ stroke: { color: "#36a2eb", width: 2 }, tooltip: "Tooltip" })
    //        .moveTo(collaborationSlot.origin)
    //        .lineTo(categorySlot.topRight().x, collaborationSlot.origin.y);

    //    const collaborationLabelLegend = new Text("Avg Talk Time", [0, 0], { font: "10px sans-serif", fill:{color:"#868686"}
    //    });

    //    const collaborationBboxLegend = collaborationLabelLegend.bbox();
    //    collaborationLabelLegend.position([
    //        (categorySlot.topRight().x - 120) - collaborationBboxLegend.size.width,
    //        42 - collaborationBboxLegend.size.height,
    //    ]);

    //    const collaborationGroup = new Group();
    //    collaborationGroup.append(collaborationLine, collaborationLabelLegend,  collaborationLineLegend);
    //    chart.surface.draw(collaborationGroup);
    //};

    return (
        <SavePDFConsumer>
            {
                (yesPDF) => {
                    return <>
                        {data.categories.length >= 40 && <div ref={ref}> {inView && <div className="hideMeAfter5Seconds">
                            <p className='click-drag-flash p-2 rounded'><i class="bi bi-arrow-left-short"></i> <i class="bi bi-mouse-fill"></i> <i class="bi bi-arrow-right-short"></i></p>
                        </div>}</div>}
                        {
                            data && data.categories && data.categories.length > 0 && widgetId === 59 &&
                            <Chart style={{ height: '100%' }} pannable={{ lock: "y", }}
                                zoomable={{
                                    mousewheel: {
                                        lock: yesPDF ? ["y", "x"] : "y"
                                    },
                                    selection: {
                                        lock: yesPDF ? ["y", "x"] : "y"
                                    },
                                }}
                            >
                                <ChartValueAxis>
                                    <ChartValueAxisItem min={0}
                                        title={{
                                            text: "Time (Hours)",
                                        }}
                                        name="valueAxis" />
                                </ChartValueAxis>
                                <ChartLegend position="top" orientation="horizontal" />
                                <ChartCategoryAxis>
                                    <ChartCategoryAxisItem categories={data.categories}
                                        labels={{ rotation: 'auto' }} maxDivisions={10} max={yesPDF ? null : 40} name="categoryAxis"
                                    />
                                </ChartCategoryAxis>
                                <ChartSeries>
                                    {data.series.map((item, idx) => (
                                        <ChartSeriesItem
                                            border={
                                                {
                                                    color: "white",
                                                    width: "1px"
                                                }
                                            }
                                            key={idx}
                                            color={item.color}
                                            type={idx === 0 ? "column" : "column"}
                                            spacing={0}
                                            tooltip={{ visible: true }}
                                            data={item.preciseData}
                                            name={item.name}
                                        />
                                    ))}
                                </ChartSeries>
                                <ChartTooltip render={renderTooltip} />
                            </Chart>
                        }
                    </>
                }
            }
        </SavePDFConsumer>
    );
}




