import { useState, useEffect } from "react";
import _ from "lodash";
import "../pages/Dashboard/dashboard.scss";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import DashboardApiService from "../../services/DashboardApiService";
import FiltersApiService from "../../services/FiltersApiService";
import { Flex, Text, Loader, Segment } from "@fluentui/react-northstar";
import { SharedCardWidget } from "./sharedcardwidget";

const saveToLS = (key, value) => {
    if (global.localStorage) {
        global.localStorage.setItem("layout" + key, JSON.stringify(value));
    }
};

const ResponsiveGridLayout = WidthProvider(Responsive);

const SharedDashboardComponent = (props) => {
    const defaultProps = {
        className: "layout",
        rowHeight: 315,
        breakPoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
        cols: { lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 },
        margin: [8, 8, 8, 8],
        containerPadding: [20, 20],
    };
    const [loaded, setLoaded] = useState(false);
    const [currentBreakpoint, setCurrentBreakPoint] = useState(null);
    const [mounted, setMounted] = useState(false);
    const [layouts, setLayouts] = useState({ lg: [], md: [], sm: [], xxs: [], });
    const [cards, setCards] = useState(null);
    const [dID, setDID] = useState("");
    const [userFiltersData, setUserFiltersData] = useState(null);
    const [cardsData, setCardsData] = useState([]);
    const [isDemo, setIsDemo] = useState(false);
    const [dName, setdName] = useState(null);
    
    let dashboardTimer;

    useEffect(() => {
        var urlDID = props.location.search.split('&').find((el) => el.includes("dID=")).split('=')[1];


        try {
            var isDemoMode = props.location.search.split('&').find((el) => el.includes("d=")).split('=')[1];

            if (isDemoMode === '1')
                setIsDemo(true);
        }
        catch (e) {
            console.log(e);
        }

        setDID(urlDID);
        setLoaded(false);
        setMounted(false);
        setCards(null);
        onWidthChange(window.innerWidth);
        loadCards(urlDID);
        dashboardTimer = setInterval(function () {
            loadCards(urlDID);
        }, 120000);
        return () => {
            setMounted(false);
            clearInterval(dashboardTimer);
        };
    }, [props.location.search]);
    const fetchCards = (urlDID) => {
        FiltersApiService.GetSharedAllCardsList(urlDID).then((response) => {
            if (response !== undefined && response !== "") {
                setCardsData(response);
                //loadCards(urlDID);
            }
            setLoaded(true);
        });
    }
    const loadCards = (urlDID) => {
        DashboardApiService.GetSharedDashboardCards(urlDID).then(            
            (response) => {                
                if (response !== undefined && response !== "") {
                    let _cards = response;
                    for (let i = 0 ; i< response.length;i++)
                    {
                        setdName(response[i].dashboardName);
                    }
                    if (_cards && _cards.length > 0) {
                        generateLayout(urlDID, _cards);
                    } else {
                        setLoaded(true);
                    }
                } else {
                    setCards(null);
                    setLoaded(true);
                }
            }
        );
        setMounted(true);
    }

    const generateLayout = (urlDID, cardList) => {   
        let layout = { lg: [], md: [], sm: [], xxs: [], };
        DashboardApiService.GetSharedDashboardLayout(urlDID).then((response) => { 
            try {
                if (response !== undefined && response !== "") {
                    layout = response;
                }
                saveToLS(urlDID, layout, cardList);
                setLayouts(JSON.parse(JSON.stringify(layout)));
                setCards(cardList);

                var isDemoMode = "0";
                try {
                    isDemoMode = props.location.search.split('&').find((el) => el.includes("d=")).split('=')[1];
                }
                catch (e) {
                    console.log(e);
                }
                if (isDemoMode != "1")
                    getSharedUserData(urlDID);
            }
            catch (e) { console.log(e) };
            fetchCards(urlDID);
        });
    };

    const getSharedUserData = (urlDID) => {
        FiltersApiService.GetSharedUserData(urlDID).then((response) => {
            try {

                if (response !== undefined && response !== "") {
                    let data = response;
                    data.teamFilter.items = data.teamFilter.items.map((obj) => ({
                        ...obj,
                        key: obj.value,
                    }));
                    data.groupFilter.items = data.groupFilter.items.map((obj) => ({
                        ...obj,
                        key: obj.value,
                    }));
                    data.callQueueFilter.items = data.callQueueFilter.items.map((obj) => ({
                        ...obj,
                        key: obj.value,
                    }));
                    data.agentFilter.items = data.agentFilter.items.map((obj) => ({
                        ...obj,
                        key: obj.value,
                    }));
                    data.userFilter.items = data.userFilter.items.map((obj) => ({
                        ...obj,
                        key: obj.value,
                    }));
                    setUserFiltersData(data);
                }
                setLoaded(true);
            }
            catch (e) { console.log(e) };
        });
    };

    const onWidthChange = (width) => {
        if (currentBreakpoint === null) {
            const breakpoints = defaultProps.breakPoints;
            const sortedBreakpoints = Object.keys(breakpoints).sort(
                (breakpoint1, breakpoint2) => breakpoints[breakpoint1] - breakpoints[breakpoint2]
            );
            let breakpoint = sortedBreakpoints[0];
            for (let i = 0; i < sortedBreakpoints.length; i++) {
                const currentBreakpoint = sortedBreakpoints[i];
                const nextBreakpoint = sortedBreakpoints[i + 1];
                if (
                    typeof nextBreakpoint === "undefined" ||
                    (breakpoints[currentBreakpoint] <= width && width <= breakpoints[nextBreakpoint])
                ) {
                    breakpoint = currentBreakpoint;
                    break;
                }
            }
            setCurrentBreakPoint(breakpoint);
        }
    };

    const onBreakpointChange = (breakpoint) => {
        setCurrentBreakPoint(breakpoint);
    };

    return loaded ? (cards !== null ?
        (cards.length > 0 ?
            (<div>
                <div class ="m-2 p-2 bg-light border-bottom font-weight-bold " style ={{textAlign :"center",color : "#6264a7", fontSize : "1.2rem"}}>{dName}</div>
            <div>
                <ResponsiveGridLayout
                    {...defaultProps}
                    layouts={layouts}
                    onBreakpointChange={onBreakpointChange}
                    measureBeforeMount={true}
                    useCSSTransforms={mounted}
                    compactType="vertical"
                    isBounded={true}
                    onWidthChange={onWidthChange}
                >
                    {_.map(cards, function (card, i) {
                        return (
                            <div key={card.cardID}>
                                {
                                    <SharedCardWidget
                                        card={card}
                                        currentBreakpoint={currentBreakpoint}
                                        dashboardParam={dID}
                                        userFiltersData={userFiltersData}
                                        allCardsData={cardsData}
                                        isDemo={isDemo}
                                    />
                                }
                            </div>
                        );
                    })}
                </ResponsiveGridLayout>
            </div>
        </div>
            ) : (
                <Flex
                    fill
                    className="default-segment-container"
                    hAlign="center"
                    vAlign="center"
                >
                    <Segment className="default-segment">
                        <Flex gap="gap.small" column hAlign="center" vAlign="center">
                            <Text content="No cards to display" />
                        </Flex>
                    </Segment>
                </Flex>
            )
        ) : (
            <Flex
                fill
                className="default-segment-container"
                hAlign="center"
                vAlign="center"
            >
                <Segment className="default-segment">
                    <Flex gap="gap.small" column hAlign="center" vAlign="center">
                        <Text content="No data to display." />
                    </Flex>
                </Segment>
            </Flex>
        )
    ) : (<Loader />);
};

export default SharedDashboardComponent;
