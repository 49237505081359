const CenteredHeader = (props) => {
    return (
      <a className="k-link text-center" onClick={props.onClick}>
        <span>
          {props.title}
        </span>
        {props.children != null && props.children[0]}
      </a>
    );
  };

export default CenteredHeader;
