import "./TopUsersGrid.scss";

//const usersData = [
//    {
//        category: "Mentions",
//        image: "images/user-img.jpg",
//        initials: "AB",
//        name: "Alina Brandon",
//        mentions: 423,
//        posts: 675,
//        metingsParticipated: 321,
//        replies: 321
//    },
//    {
//        category: "Posts",
//        image: "",
//        initials: "KA",
//        name: "Kimmiey Aroster",
//        mentions: 423,
//        posts: 675,
//        metingsParticipated: 321,
//        replies: 321
//    },
//    {
//        category: "Meeting Participation",
//        image: "",
//        initials: "MP",
//        name: "Lesley Davies",
//        mentions: 423,
//        posts: 675,
//        metingsParticipated: 321,
//        replies: 321
//    },
//    {
//        category: "Replies",
//        image: "",
//        initials: "WR",
//        name: "William Reader",
//        mentions: 423,
//        posts: 675,
//        metingsParticipated: 321,
//        replies: 321
//    }
//];

export default function TopUsersGrid({ data, widgetId }) {
  return (
    <>
      {data && data.length > 0 && (
        <div className="activity-view">
          <div className="topUsrList">
            {data.map((item, idx) => (
              <div className="topUsrArea" key={idx}>
                <div className="topUsrAreaPic">
                  <div className="topUsrAreaPic-i">
                    <div className="contact-list-icon">
                      <div className="contact-list-icon-txt">
                        {item.image && item.image.length > 0 ? (
                          <div className="contact-list-icon-img">
                            <img src={item.image} alt="User" />
                          </div>
                        ) : (
                          item.initials
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="topUsrAreaPic-tx">
                    {widgetId === 14 ? "Most Active User" : "Top Team"} <br />
                    by{" "}
                    {item.category === "Meetings"
                      ? "Calls"
                      : item.category}
                  </div>
                </div>
                <div className="topUsrAreaDtl">
                  <div className="topUsrAreaDtl-t">{item.name}</div>
                  <div className="topUsrAreaDtlLbl">
                    <div className="topUsrAreaDtlLblRow">
                      <div className="topUsrAreaDtlLblRow-h">
                        {widgetId === 14 ? "Calls" : "Calls"}
                      </div>
                      <div className="topUsrAreaDtlLblRow-dot">:</div>
                      <div className="topUsrAreaDtlLblRow-val">
                        {item.meetings}
                      </div>
                    </div>
                    {widgetId === 14 && (
                      <div className="topUsrAreaDtlLblRow">
                        <div className="topUsrAreaDtlLblRow-h">Replies</div>
                        <div className="topUsrAreaDtlLblRow-dot">:</div>
                        <div className="topUsrAreaDtlLblRow-val">
                          {item.replies}
                        </div>
                      </div>
                    )}
                    <div className="topUsrAreaDtlLblRow">
                      <div className="topUsrAreaDtlLblRow-h">Messages</div>
                      <div className="topUsrAreaDtlLblRow-dot">:</div>
                      <div className="topUsrAreaDtlLblRow-val">
                        {item.messages}
                      </div>
                    </div>
                    <div className="topUsrAreaDtlLblRow">
                      <div className="topUsrAreaDtlLblRow-h">Mentions</div>
                      <div className="topUsrAreaDtlLblRow-dot">:</div>
                      <div className="topUsrAreaDtlLblRow-val">
                        {item.mentions}
                      </div>
                    </div>
                    {widgetId === 25 && (
                      <div className="topUsrAreaDtlLblRow">
                        <div className="topUsrAreaDtlLblRow-h">Reactions</div>
                        <div className="topUsrAreaDtlLblRow-dot">:</div>
                        <div className="topUsrAreaDtlLblRow-val">
                          {item.reactions}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
