import axios, { AxiosInstance } from 'axios';
import AuthHelper from './auth-helper';
import { AuthenticationResult } from '@azure/msal-browser';
import * as constants from '../constants';
import * as msTeams from '@microsoft/teams-js';

class TenantApiService {

    static AuthenticatedApi = axios.create({
        baseURL: constants.Api.baseUri,
        // will inject auth header on-demand later as needed.
        headers: { 
            'Content-Type': 'application/json',
        }
    });

    static GetAccountPermission(token) {
        return this.AuthenticatedApi.get('/Tenant/GetAccountPermission/', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (response.data)
                return response.data;
            else
                return null;
        })
        .catch((error) => {
            console.log('TenantApiService.GetAccountPermission() - An error has occurred calling the web api: ' + error);
            return "Error";
        });
    }

}

export default TenantApiService;